import React from 'react';
import { LucideIcon } from 'lucide-react';

const ProfileStat = ({ label, value, Icon }: { label: string; value: string | number; Icon: LucideIcon }) => (
    <div className="flex flex-row items-center justify-between m-2 p-2 w-full max-w-[271px] h-[40px] bg-[rgba(0,0,0,0.2)] rounded-lg">
        <div className="flex flex-row items-center gap-2">
            <Icon className="text-white w-5 h-5" />
            <p className="text-white font-medium text-sm lg:text-lg">{label}</p>
        </div>
        <p className="text-white font-semibold text-lg lg:text-2xl">{value}</p>
    </div>
);

export default ProfileStat;

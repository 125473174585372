// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import posthog from 'posthog-js';

if (process.env.NODE_ENV === 'production') {
  // Initialize PostHog in production only
  posthog.init('phc_2B0EInFZdoHLRg4PjHnSN1xKrlasU7v8J1qFKkbAqPc', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only'
  });
}

const container = document.getElementById('root');

if (!container) {
  throw new Error("Failed to find the root element");
}

const root = ReactDOM.createRoot(container);
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

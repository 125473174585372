import React from 'react';
import { CheckCircle, User, Eye, Radio, DollarSign } from 'lucide-react';
import ButtonComp from '../ButtonComp';
import IconButton from '../IconButton';
import LabelTagComp from '../LabelTagComp';

interface ProfileHeroProps {
    username: string;
    name: string;
    memberSince: string;
    avatarUrl: string;
    bio: string;
    followers: number;
    viewers: number;
    streams: number;
    revenue: number;
    isLive?: boolean;
    tags: string[];
    rightBtnTxt?: string;
    rightBtnIcon?: React.ReactNode;
    bgColor?: string;
}

const ProfileHeroComponent: React.FC<ProfileHeroProps> = ({
    username,
    name,
    memberSince,
    avatarUrl,
    bio,
    followers,
    viewers,
    streams,
    revenue,
    isLive = false,
    rightBtnTxt = 'UserAdd',
    rightBtnIcon = null,
    bgColor = 'bg-purple-30',
    tags = []
}) => {
    return (
        <div className={`relative w-full ${bgColor} rounded-lg p-6 flex flex-col gap-5 overflow-hidden`}>
            {/* Background circle */}
            <div className="absolute w-[400px] h-[400px] -left-[140px] -top-[140px] bg-grey-100 opacity-10 rounded-full" />

            {/* Top section */}
            <div className="flex justify-between items-start z-10">
                <div className="flex items-center gap-5">
                    {/* Avatar */}
                    <div className="relative">
                        <img src={avatarUrl} alt={name} className="w-20 h-20 rounded-lg border-4 border-red-100" />
                        {isLive && (
                            <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 bg-red-100 text-white text-[10px] font-bold px-1 py-0.5 rounded">
                                LIVE
                            </div>
                        )}
                    </div>
                    {/* Profile details */}
                    <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                            <span className="text-grey-40 text-sm">@{username}</span>
                            <CheckCircle className="w-4 h-4 text-green-100" />
                        </div>
                        <h1 className="text-white text-2xl font-semibold">{name}</h1>
                        <p className="text-grey-40 text-sm">member since {memberSince}</p>
                    </div>
                </div>
                <div>
                    {/* <IconButton
                        label={""}
                        icon={rightBtnIcon}
                        onClick={() => console.log('Right button clicked')}
                        className="w-full text-brand-100 !border-brand-100"
                    /> */}
                </div>
            </div>

            {/* Bio */}
            <p className="text-white text-sm leading-5 z-10">{bio}</p>

            {/* Tags */}
            <div className="flex gap-2 z-10">
                {tags.map((tag, index) => (
                    <LabelTagComp key={index} tags={[tag]} />
                ))}
            </div>

            {/* Spacer */}
            <div className="flex-grow" />

            {/* Stats */}
            {/* <div className="grid grid-cols-2 md:grid-cols-4 gap-2 z-10">
                <StatItem icon={<User />} label="Followers" value={followers} />
                <StatItem icon={<Eye />} label="Viewers" value={viewers} />
                <StatItem icon={<Radio />} label="Streams" value={streams} />
                <StatItem icon={<DollarSign />} label="Revenue" value={`$${revenue}`} />
            </div> */}
        </div>
    );
};

interface StatItemProps {
    icon: React.ReactNode;
    label: string;
    value: number | string;
}

const StatItem: React.FC<StatItemProps> = ({ icon, label, value }) => (
    <div className="flex items-center gap-2 bg-black bg-opacity-20 rounded-lg px-1 py-2 md:px-2 md:py-3 flex-1">
        {icon}
        <div className="flex flex-row items-center gap-2">
            <span className="text-white text-sm">{label}:</span>
            <span className="text-white text-sm font-semibold">{value}</span>
        </div>
    </div>
);

export default ProfileHeroComponent;
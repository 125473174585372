import { useState } from 'react';
import { useSetupAccountStore } from '../../store/setupAccountStore';
import { api } from '../../services/api';
import { useToast } from '../../components/ToastComp';
import { SetupAccountType } from '../../types/api';
import { useNavigate } from 'react-router-dom';

interface Tool {
  id: string;
  name: string;
  borderColor: string;
}
interface Option {
  id: string;
  name: 'yes' | 'no' | 'maybe';
  borderColor: string;
}

export const useSetupAccount = () => {
  const {
    step,
    tools,
    departments,
    beAsStreamer,
    nextStep,
    prevStep,
    reset,
    addTool,
    toggleTool,
    addDepartment,
    toggleDepartment,
    setBeAsStreamer,
  } = useSetupAccountStore();

  const navigate = useNavigate();
  const toast = useToast();
  const [toolInput, setToolInput] = useState('');

  // Tools.
  const availableTools: Tool[] = [
    { id: '1', name: 'Hubspot', borderColor: 'border-[#DF974D]' },
    { id: '2', name: 'Salesforce', borderColor: 'border-blue-100' },
    { id: '3', name: 'AWS', borderColor: 'border-[#A854FD]' },
    { id: '4', name: 'Google Cloud', borderColor: 'border-grey-60' },
    { id: '5', name: 'Microsoft Azure', borderColor: 'border-grey-60' },
    { id: '6', name: 'OpenAI', borderColor: 'border-green-80' },
    { id: '7', name: 'Figma', borderColor: 'border-grey-20' },
    { id: '8', name: 'Adobe Suite (PSD, AI)', borderColor: 'border-grey-60' },
    { id: '9', name: 'Slack', borderColor: 'border-grey-60' },
    { id: '10', name: 'Jira', borderColor: 'border-red-80' },
    { id: '11', name: 'Trello', borderColor: 'border-blue-70' },
  ];

  // Find added tools.
  const addedTools = tools.filter(tool =>
    !availableTools.some(avTool => avTool.name.toLowerCase() === tool.toLowerCase())
  );

  // Create a combined list of tools to display
  const allToolsToDisplay = [
    ...availableTools,
    ...addedTools.map((tool, index) => ({
      id: (availableTools.length + index + 1).toString(),
      name: tool,
      // Set a default border color for added tools
      borderColor: 'border-grey-60',
    }))
  ];

  const handleToolOnAdd = () => {
    const trimmedTool = toolInput.trim();
    if (trimmedTool === '') {
      toast({
        type: 'error',
        message: 'Tool name cannot be empty',
        duration: 3000,
      });
      return;
    }
    if (tools.includes(trimmedTool)) {
      toast({
        type: 'error',
        message: 'Tool already added',
        duration: 3000,
      });
      return;
    }
    addTool(trimmedTool);
    setToolInput('');
  };

  // Departments.
  const availableDepartments: Tool[] = [
    { id: '1', name: 'Market', borderColor: 'border-[#DF974D]' },
    { id: '2', name: 'Sales', borderColor: 'border-blue-100' },
    { id: '3', name: 'Finance', borderColor: 'border-[#A854FD]' },
    { id: '4', name: 'Human Resources', borderColor: 'border-grey-60' },
    { id: '5', name: 'Customer Success', borderColor: 'border-grey-60' },
    { id: '6', name: 'Operations', borderColor: 'border-grey-60' },
    { id: '7', name: 'Research & Development', borderColor: 'border-red-80' },
    { id: '8', name: 'Design', borderColor: 'border-grey-60' },
    { id: '9', name: 'Legal', borderColor: 'border-grey-20' },
    { id: '10', name: 'Product Development', borderColor: 'border-grey-60' },
    { id: '11', name: 'Communications', borderColor: 'border-grey-60' },
    { id: '12', name: 'Corporate Strategy', borderColor: 'border-blue-70' },
    { id: '13', name: 'Healthcare', borderColor: 'border-grey-60' },
    { id: '14', name: 'Manufacturing', borderColor: 'border-grey-60' },
    { id: '15', name: 'IT & Engineering', borderColor: 'border-green-80' },
    { id: '16', name: 'Education & Training', borderColor: 'border-grey-60' },
  ];

  // Options.
  const options: Option[] = [
    { id: '1', name: 'yes', borderColor: 'border-green-80' },
    { id: '2', name: 'no', borderColor: 'border-grey-60' },
    { id: '3', name: 'maybe', borderColor: 'border-grey-60' },
  ];

  const handleComplete = async () => {
    try {
      const payload: SetupAccountType = {
        tools,
        departments,
        beAsStreamer,
      };
      await api.profile.completeSetup(payload);
      reset();
      navigate('/register/success');
    } catch (error: any) {
      toast({
        type: 'error',
        message: error.response?.data?.message || 'An error occurred',
        duration: 5000,
      });
    }
  };

  return {
    step,
    tools,
    departments,
    beAsStreamer,
    nextStep,
    prevStep,
    reset,
    addTool,
    toggleTool,
    addDepartment,
    toggleDepartment,
    setBeAsStreamer,
    handleComplete,
    availableTools,
    toolInput,
    setToolInput,
    handleToolOnAdd,
    toast,
    availableDepartments,
    options,
    allToolsToDisplay,
  };
};

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import { useUserStore } from '../store/userStore';
import { MeType, SetupAccountType, ChatStoreType, BroadcasterRequestType, ProfileType, BroadcastSetupType, UserProfileType } from '../types/api';
import { Broadcast, Channel, Pagination, Transcript } from '../types/search';

// Set Axios defaults
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER || "https://backend.filter.fm/api/";
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

// Create an Axios instance
const axiosInstance: AxiosInstance = axios.create();

// Interface to extend AxiosRequestConfig with a _retry property
interface AxiosRequestConfigWithRetry extends AxiosRequestConfig {
  _retry?: boolean;
}

// Interface for items in the failed request queue
interface FailedQueueItem {
  resolve: (value: string | null | PromiseLike<string | null>) => void;
  reject: (error: any) => void;
}

// Variables to manage token refreshing
let isRefreshing = false;
let failedQueue: FailedQueueItem[] = [];

// Function to process the failed request queue
const processQueue = (error: any, token: string | null = null): void => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Response interceptor to handle 401 errors and token refreshing
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfigWithRetry;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise<string | null>((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => axiosInstance(originalRequest))
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await axiosInstance.post<{ access_token: string }>('/auth/refresh-token/');
        const { access_token } = response.data;

        processQueue(null, access_token);
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        useUserStore.getState().setUser(null);
        //window.location.href = '/login';
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

// Generic Axios wrapper to extract data from responses
const axiosWrapper = <T>(apiCall: Promise<AxiosResponse<T>>): Promise<T> => {
  return apiCall.then((response) => response.data).catch((err) => Promise.reject(err));
};


// API object with typed methods
const api = {
  stripe: {
    createCheckoutSession: (priceId: string): Promise<{ id: string; url: string }> =>
      axiosWrapper(axiosInstance.post('/stripe/create-checkout-session', { priceId })),
    createDashboardSession: (customerId: string): Promise<{ url: string }> =>
      axiosWrapper(axiosInstance.post('/stripe/create-dashboard-session', { customerId })),
  },

  auth: {
    login: (credentials: any): Promise<MeType> =>
      axiosWrapper(axiosInstance.post('/auth/login/', credentials)),

    logout: (): Promise<{ message: string }> =>
      axiosWrapper(axiosInstance.post('/auth/logout/')),

    update: (data: Partial<any>): Promise<any> =>
      axiosWrapper(axiosInstance.put('/user/me/', data)),

    refreshToken: (): Promise<{ access_token: string }> =>
      axiosWrapper(axiosInstance.post('/user/refresh-token/')),

    // Password reset routes for the auth API
    reset: {
      sendOTP: (email: string): Promise<{ message: string }> =>
        axiosWrapper(axiosInstance.post('/auth/reset/send-otp', { email })),
      validateOTP: (data: { email: string; otp: string }): Promise<{ valid: boolean }> =>
        axiosWrapper(axiosInstance.post('/auth/reset/validate-otp', data)),
      updatePassword: (data: { email: string; otp: string; password: string, repeat: string }): Promise<{ message: string }> =>
        axiosWrapper(axiosInstance.post('/auth/reset/update-password', data)),
    },
    register: {
      signup: (data: any): Promise<any> => axiosWrapper(axiosInstance.post('/auth/signup/', data)),
      validateOTP: (data: any): Promise<any> => axiosWrapper(axiosInstance.post('/auth/verify-email/', data)),
      resendOTP: (data: any): Promise<any> => axiosWrapper(axiosInstance.post('/auth/resend-otp/', data))
    }
  },
  profile: {
    me: (): Promise<MeType> =>
      axiosWrapper(axiosInstance.get('/user/me')),
    completeSetup: (payload: SetupAccountType): Promise<MeType> =>
      axiosWrapper(axiosInstance.post('/user/setup-account', payload)),
    updateProfilePicture: (formData: FormData): Promise<any> =>
      axiosWrapper(
        axiosInstance.put('/user/me/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      ),

    deleteProfilePicture: (): Promise<{ message: string }> =>
      axiosWrapper(axiosInstance.delete('/user/me/')),

    updatePassword: (data: { current_password: string; new_password: string }): Promise<{ message: string }> =>
      axiosWrapper(axiosInstance.patch('/user/me/password/', data)),

    updateProfile: (data: ProfileType): Promise<{ message: string }> =>
      axiosWrapper(axiosInstance.put('/user/me/', data)),  
    getFullProfile: (): Promise<UserProfileType> =>
      axiosWrapper(axiosInstance.get('/user/profile')),
  },

  broadcast: {
    start: (data: BroadcastSetupType): Promise<any> =>
      axiosWrapper(axiosInstance.post('/broadcast/start/', data)),
    join: (id: string): Promise<any> =>
      axiosWrapper(axiosInstance.get(`/broadcast/${id}/join/`)),
    getRTMToken: (): Promise<any> =>
      axiosWrapper(axiosInstance.post('/broadcast/generate-rtm-token')),
    stop: (broadcastId: string): Promise<{ message: string }> =>
      axiosWrapper(axiosInstance.post(`/broadcast/${broadcastId}/stop/`)),

    chat: {
      save: (data: ChatStoreType): Promise<any> => axiosWrapper(axiosInstance.post('/chat/save', data)),
    },
    broadcaster: {
      save: (data: BroadcasterRequestType): Promise<{ message: string }> =>
        axiosWrapper(axiosInstance.post('/become-broadcaster/save', data)),
    },
    saveVideoFile: (file: File): Promise<{ url: string }> => {
      const formData = new FormData();
      formData.append('video', file);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'file-name': file.name
        }
      };

      return axiosWrapper(
        axiosInstance.post('/become-broadcaster/upload-video', formData, config)
      ).then(response => {
        return { url: response.videoUrl };
      });
    },
    getListOfChannels: (limit?: number): Promise<any> => axiosWrapper(axiosInstance.get(`/become-broadcaster/${limit ? `?limit=${limit}` : ''}`)),
    getChannelById: (id: string): Promise<any> => axiosWrapper(axiosInstance.get(`/become-broadcaster/${id}`)),
    getBroadcastsByChannelId: (channel_id: string): Promise<any> => axiosWrapper(axiosInstance.get(`/broadcasts/${channel_id}`)),
    getBroadcastsByUserId: (user_id: string): Promise<any> => axiosWrapper(axiosInstance.get(`/broadcasts/user/${user_id}`)),

    recent: (isLive: boolean) => axiosWrapper(axiosInstance.get(`/broadcasts/recent?isLive=${isLive}`)),

    watch: (): Promise<any> => axiosWrapper(axiosInstance.get(`/watch`)),
  },
  search: {
    all: (query: string) => axiosWrapper(axiosInstance.get(`/search${query}`)),
    category: (query: string) => axiosWrapper(axiosInstance.get(`/search/category${query}`)),
  }
};
export { api, axiosInstance };
export default api;

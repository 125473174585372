import React, { useState } from 'react';
import Modal from '../../components/ModalComp';
import InputComp from '../../components/InputComp';
import { Share2, Twitter, Facebook, MessageCircle, Mail } from 'lucide-react';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
interface ShareModalProps {
    isModalOpen: boolean;
    onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ isModalOpen, onClose }) => {
    const [shareLink, setShareLink] = useState<string>('https://your-share-link.com/abc123');

    const socialIcons = [
        { icon: Twitter, label: 'Twitter', color: '#020318', bgColor: '#FFFFFF' },
        { icon: Facebook, label: 'Facebook', color: '#FFFFFF', bgColor: '#679EE3' },
        { icon: MessageCircle, label: 'WhatsApp', color: '#FFFFFF', bgColor: '#8DAD58' },
        { icon: Share2, label: 'Reddit', color: '#FFFFFF', bgColor: '#EC5353' },
        { icon: Mail, label: 'Email', color: '#FFFFFF', bgColor: '#DEC65E' },
    ];

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink);
        // You could add a toast notification here
    };

    return (
        <div className="bg-grey-100 z-[1000]">
            <Modal
                isOpen={isModalOpen}
                onClose={onClose}
                title="Share"
                subtitle="Choose one of the main channels"
                showNavigation={false}
                footer={
                    <div className="flex flex-col w-full">
                        <label className="text-sm text-grey-40 mb-2">Share Link</label>
                        <div className="flex items-center gap-2  bg-grey-90">
                            <InputComp
                                value={shareLink}
                                onChange={() => { }}
                                placeholder="Share link"
                                type="text"
                                bgColor="bg-grey-90"
                                readOnly
                            />
                            <button
                                onClick={handleCopyLink}
                                className="p-2 bg-grey-90 rounded-lg hover:bg-grey-80"
                            >
                                <Copy className="w-5 h-5 text-grey-40" />
                            </button>
                        </div>
                    </div>
                }
            >
                {/* Social Icons Grid */}
                <div className="flex justify-between">
                    {socialIcons.map((item, index) => (
                        <div key={index} className="flex flex-col items-center gap-2">
                            <button
                                className="w-16 h-16 rounded-lg flex items-center justify-center transition-transform hover:scale-105"
                                style={{ backgroundColor: item.bgColor }}
                            >
                                <item.icon
                                    className="w-6 h-6"
                                    style={{ color: item.color }}
                                />
                            </button>
                            <span className="text-sm text-grey-40">{item.label}</span>
                        </div>
                    ))}
                </div>
            </Modal>
        </div>
    );
};

export default ShareModal;
import React, { ReactNode } from 'react';
import { Bell, MessageCircle } from 'lucide-react';
import { ReactComponent as Logo } from '../../assets/logos/logo_brand.svg';
import { ReactComponent as NotificationOutline } from '../../assets/icons/notification_outline.svg';
import { ReactComponent as MessageOutline } from '../../assets/icons/message_round.svg';
import Illustration from '../../assets/images/Group-1.png';
import User1 from "../../assets/user4.png";
import IconButton from '../IconButton';
import useMediaQuery from '../../hooks/useMediaQuery';

interface BecomeBroadcasterLayoutProps {
    children: ReactNode;
    illustration?: ReactNode;
}

const BecomeBroadcasterLayout: React.FC<BecomeBroadcasterLayoutProps> = ({
    children,
    illustration = <img src={Illustration} alt="Illustration" />
}) => {
    const isMobile = useMediaQuery('(max-width: 640px)');
    if (isMobile) {
        illustration = null;
    }
    return (
        <div className="min-h-screen bg-grey-100 flex flex-col">
            {/* Top Bar */}
            <div className="h-[88px] w-full">
                <div className="h-[84px] w-full flex justify-between items-center px-4 lg:px-8">
                    {/* Logo */}
                    <div className="w-[137.68px] h-8 flex items-center">
                        <div className="w-8 h-8">
                            {/* Logo mark */}
                        </div>
                        <div className="ml-2 flex-1">
                            <Logo width="100%" height="100%" className="text-white" />
                        </div>
                    </div>

                    {/* Controls */}
                    <div className="flex gap-4">
                        <IconButton
                            label=''
                            icon={<NotificationOutline className='text-white' />}
                            onClick={() => console.log('Right button clicked')}
                            className="bg-grey-90 !w-12"
                        />
                        <IconButton
                            label=''
                            onClick={() => console.log('Right button clicked')}
                            className="bg-grey-90 !w-12"
                            icon={<MessageOutline className='text-white' />}
                        />
                        <div className="ml-2 w-12 h-12 bg-text-medium rounded-lg flex items-center justify-center flex-shrink-0">
                            <img src={User1} alt="User" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1 flex justify-center px-4 lg:px-0">
                {/* Mobile Layout (default) */}
                {/* Empty space on left */}
                <div className="w-[2%] lg:block lg:w-[10%]"></div>

                <div className="w-full max-w-[480px] lg:max-w-[1208px] flex flex-col lg:flex-row lg:items-center lg:justify-between py-8 lg:py-0">
                    {/* Illustration - Top for mobile, Left for desktop */}
                    {!isMobile ? (<div className="w-[210px] h-[500px] mb-32 lg:w-auto lg:h-auto lg:flex-1">
                        {illustration}
                    </div>) : null}

                    {/* Content - Bottom for mobile, Right for desktop */}
                    <div className="w-full lg:w-[480px] flex flex-col gap-[60px] lg:-mt-[135px]">
                        {children}
                    </div>
                </div>
                {/* Empty space on right */}
                <div className="w-[2%] lg:block lg:w-[10%]"></div>s
            </div>
        </div>
    );
};

export default BecomeBroadcasterLayout;
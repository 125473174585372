import React from 'react';
import { Plus, Search } from 'lucide-react';
import InputComp from './InputComp';

interface CategoriesProps {
    categories: string[];
    onAddCategory: () => void;
    onChangeCategory: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    label?: string;
}

const CategoriesComp = ({
    categories,
    onAddCategory,
    onChangeCategory,
    value,
    label = 'Categories'
}: CategoriesProps) => {


    return (
        <div className="w-full">
            <h3 className="font-outfit text-[14px] leading-[20px] text-[#FFFFFF] mb-2">
                {label}
            </h3>
            {/* <div className="relative flex items-center w-full h-[40px] bg-grey-80 rounded-lg"> */}
                {/* Search Icon */}
            {/* <Search className="text-brand-100 w-[20px] h-[20px] absolute left-3" /> */}

                {/* Input Field */}
            {/* <InputComp
                    value={value ?? ''}
                    onChange={onChangeCategory}
                    placeholder={`Add ${label}...`}
                    type="text"
                    className="flex-grow w-full bg-transparent text-white border-none outline-none pl-10 pr-10"
                /> */}

                {/* Plus Icon */}
            {/* <button
                    onClick={() => onAddCategory()}
                    className="absolute right-3 text-brand-100 w-[20px] h-[20px] flex items-center justify-center"
                >
                    <Plus />
                </button> */}
            {/* </div> */}

            {/* Tags */}
            <div className="flex flex-wrap items-start gap-4 mt-4 w-[560px]">
                {categories?.map((category) => (
                    <Tag key={category} label={category} />
                ))}
            </div>
        </div>
    );
};

// Tag Component
const Tag = ({ label }: { label: string }) => (
    <div className="flex flex-row items-center justify-center p-2 border border-[#C7F572] rounded-lg">
        <span className="text-[#C7F572]">{label}</span>
    </div>
);

export default CategoriesComp;

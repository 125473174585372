import React, { useState } from 'react';
import ButtonCompSpecific from '../../../components/ButtonCompSpecific';
import { ReactComponent as ShortRight } from '../../../assets/icons/short_right.svg';
import { ReactComponent as ShortLeft } from '../../../assets/icons/short_left.svg';
import TagComponent from '../../../components/TagComp';
import StepProgressBar from '../../../components/StepProgressBar';
import { useSetupAccount } from '../../../hooks/user/useSetupAccount';

const SetupAccount2: React.FC = () => {
    const { toast, nextStep, prevStep, availableDepartments, departments, toggleDepartment } = useSetupAccount();

    const handleNext = () => {
        if (departments.length === 0) {
            toast({
                type: 'error',
                message: 'Please select at least one department',
                duration: 5000,
            });
            return;
        }
        nextStep();
    };

    const handleBack = () => {
        prevStep();
    };

    return (
        <div className="max-w-[480px] flex flex-col items-start justify-start gap-5">
            <div>
                {/* Title Section */}
                <p className="text-base opacity-70 text-grey-40">Setup Account</p>
            <h1 className="text-2xl font-medium mb-8 text-white">
                Which departments are most relevant to your career or interests?
            </h1>
            </div>

            {/* Departments Tags */}
            <div className="flex flex-wrap gap-4">
                {availableDepartments.map((dept) => (
                    <TagComponent
                        key={dept.id}
                        label={dept.name}
                        selected={departments.includes(dept.name)}
                        borderColor={dept.borderColor}
                        onClick={() => toggleDepartment(dept.name)}
                    />
                ))}
            </div>

            {/* Button Controls */}
            <div className="flex gap-4 w-full mt-6">
                <ButtonCompSpecific
                    type="grey"
                    leftIcon={<ShortLeft className="fill-grey-90" />}
                    onClick={handleBack}
                >
                    Back
                </ButtonCompSpecific>
                <ButtonCompSpecific
                    type="brand"
                    rightIcon={<ShortRight className="fill-grey-90" />}
                    onClick={handleNext}
                >
                    Next
                </ButtonCompSpecific>
            </div>

            {/* Progress Bars */}
            <div className='w-full mt-24'>

            <StepProgressBar totalSteps={3} currentStep={2} />
            </div>
        </div>
    );
};

export default SetupAccount2;

import React, { useState, ChangeEvent } from 'react';
import { Eye, EyeOff, Send, Search } from 'lucide-react';
import { ReactComponent as SendIcon } from '../assets/icons/campaigns.svg';

interface InputCompProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  type?: 'text' | 'password' | 'email' | 'number';
  send?: boolean;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
  labelColor?: string;
  iconColor?: string;
  hoverColor?: string;
  pressedColor?: string;
  focusColor?: string;
  label?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  disabled?: boolean;
  width?: string;
}

const InputComp: React.FC<InputCompProps> = ({
  value,
  onChange,
  placeholder,
  type = 'text',
  send = false,
  bgColor = 'bg-grey-90',
  textColor = 'text-white',
  borderColor = 'border-transparent',
  labelColor = 'text-grey-40',
  iconColor = 'text-grey-40',
  hoverColor = 'hover:bg-grey-80',
  pressedColor = 'active:bg-grey-70',
  focusColor = 'focus:ring-4 focus:ring-brand-100 focus:ring-opacity-10',
  label,
  leftIcon = null,
  rightIcon = null,
  disabled = false,
  width = 'w-full',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isPassword = type === 'password';

  const togglePasswordVisibility = (): void => {
    if (!disabled) setShowPassword(!showPassword);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!disabled) onChange(e);
  };

  const disabledClass = disabled ? 'opacity-50 cursor-not-allowed' : '';
  const inputPadding = leftIcon ? 'pl-10 pr-10' : 'px-3 pr-10';

  return (
    <div className={`flex flex-col gap-2 ${width}`} >
      {label && (
        <label className={`text-sm font-normal ${labelColor} ${disabledClass}`}>
          {label}
        </label>
      )}
      <div className='relative'>
        {leftIcon && (
          <div className={`absolute left-2.5 top-1/2 transform -translate-y-1/2 ${iconColor} ${disabledClass}`}>
            {leftIcon}
          </div>
        )}
        <input
          className={`
            w-full ${textColor} ${borderColor} ${bgColor}
            ${hoverColor} ${pressedColor} ${focusColor}
            outline-none rounded-md
            flex items-center h-10 ${inputPadding}
            text-sm leading-5
            transition-all duration-200
            ${disabledClass}
          `}
          placeholder={placeholder}
          type={isPassword && showPassword ? 'text' : type}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        <div className={`absolute right-2.5 top-1/2 transform -translate-y-1/2 ${iconColor} ${disabledClass}`}>
          {isPassword ? (
            <button className='mt-2' type='button' onClick={togglePasswordVisibility} disabled={disabled}>
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          ) : send ? (
            <button type='button' disabled={disabled}>
                <SendIcon />
            </button>
          ) : (
            rightIcon
          )}
        </div>
      </div>
    </div>
  );
};

export default InputComp;
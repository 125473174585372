import React, { useState } from 'react';
import InputComp from '../InputComp';
import { Info } from 'lucide-react';
import SectionHeader from '../../components/Section/SectionHeader';
import LeftRightButton from '../../components/Section/LeftRightButton';
import DividerComp from '../../components/DividerComp';
import { ReactComponent as Move } from '../../assets/icons/move.svg';
import { ReactComponent as MoreVertical } from '../../assets/icons/more_vertical.svg';
import IconButton from '../IconButton';
import User1 from '../../assets/user1.png';

interface User {
    id: string;
    name: string;
    avatar?: string;
}

export interface Message {
    id: number;
    message: string;
    user: User;
}

interface ChatProps {
    messages: Message[];
    onSendMessage: (message: string) => void;
    title?: string;
    placeholder?: string;
    infoMessage?: string;
    onLeftClick?: () => void;
    onRightClick?: () => void;
    isMobile?: boolean;
    isBroadcaster?: boolean;
}

const Chat: React.FC<ChatProps> = ({
    messages,
    onSendMessage,
    title = 'Chat',
    placeholder = 'Chat here...',
    infoMessage = 'Once you start your broadcast you’ll be able to chat with your audience.',
    onLeftClick,
    onRightClick,
    isMobile = false,
    isBroadcaster = false,
}) => {
    const [message, setMessage] = useState<string>('');
    const hasMessages = messages.length > 0;

    return (
        <div className="flex flex-col h-[670px] w-full rounded-lg">
            {!isMobile ? (
                <div className="px-5">
                <SectionHeader title={title}>
                        {isBroadcaster ? (
                            <IconButton
                                onClick={onLeftClick}
                                icon={<Move className='text-grey-40' />}
                                className="!w-8 !border-transparent !bg-transparent"
                            />) : (
                            <IconButton
                                onClick={onRightClick}
                                icon={<MoreVertical className='text-grey-40' />}
                                className="!w-8 !border-transparent !bg-transparent"
                            />
                        )}

                    </SectionHeader>
                    <DividerComp />
          </div>
            ) : null}

            <div className="p-5 w-full space-y-4 overflow-y-auto overflow-x-hidden [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-grey-100 [&::-webkit-scrollbar-thumb]:bg-grey-40 [&::-webkit-scrollbar-thumb]:rounded-full h-full">
              {hasMessages ? (
                    messages.map((msg, index) => (
                      <>
                            {index == 0 ? null : <DividerComp />}
                            <div key={msg.id} className="flex items-start w-full">
                                <img src={User1} alt={`${msg.user.name}'s avatar`} className="w-6 h-6 rounded flex-shrink-0" />
                                <div className="flex flex-col ml-2 min-w-0 flex-1">
                                    <span className="font-semibold text-sm text-orange-100 truncate">{msg.user.name}</span>
                                    <span className="text-white text-sm break-words whitespace-pre-wrap">{msg.message}</span>
                                </div>
                            </div>
                      </>
          ))
              ) : (
                  <div className="flex flex-col justify-center items-center h-full">
                      <Info className="text-grey-40 mb-4" size={24} />
                      <p className="text-grey-40 text-center max-w-xs">{infoMessage}</p>
                  </div>
              )}
          </div>
            <div className="px-4">
              <InputComp
                  send={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
                  value={message}
                  placeholder={placeholder}
                  bgColor="bg-grey-90"
                  borderColor="border-transparent"
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                          onSendMessage(message);
                          setMessage('');
                      }
                  }}

                    width='!w-full'
              />
          </div>
      </div>
  );
};

export default Chat;

import React, { useState } from 'react';
import ButtonComp from '../ButtonComp';
import DividerComp from '../../components/DividerComp';

interface TabCompProps {
    tabs: { label: string; value: string }[];
    onTabChange?: (tab: string) => void;
    width?: string;
    borderBg?: boolean;
}

const TabComp: React.FC<TabCompProps> = ({ tabs, onTabChange, width = 'w-full', borderBg = false }) => {
    const [activeTab, setActiveTab] = useState<string>(tabs[0].value);

    return (
        <div className={`flex max-w-full justify-start ${width}`}>
            {tabs.map((tab, index) => (
                <div key={index} className="relative inline-block !w-full">  
                <ButtonComp
                        backgroundColor='bg-transparent'
                        textColor={activeTab === tab.value ? 'text-white' : 'text-grey-40'}
                    onClick={() => {
                        setActiveTab(tab.value);
                        if (onTabChange) {
                            onTabChange(tab.value);
                        }
                    }}

                >
                    {tab.label}
                </ButtonComp>
                    <div className="relative">
                        {activeTab === tab.value && (
                            <div className="absolute top-0 left-0 right-0 h-0.5 bg-brand-100 z-10" />
                        )}
                        <DividerComp className="mt-2 mb-4" />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TabComp;
import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { ProfileType } from '../../types/api';
import { useUserStore } from '../../store/userStore';
import { useToast } from '../../components/ToastComp';

// This is being use to read the data for top hero section of the profile page

export const useProfile = () => {
    const [streamCount, setStreamCount] = useState<number>(0);
    const [viewers, setViewers] = useState<number>(0);
    const [channelName, setChannelName] = useState<string>('');

    const toast = useToast();

    useEffect(() => {
        getStats();
    }, []);

    const getStats = async () => {
        try {
            const response = await api.profile.getFullProfile();
            setStreamCount(response.stream_count || 0);
            setViewers(response.total_views || 0);
            setChannelName(response.channel_name || '');
        }
        catch (error) {
            console.error(error);
            toast({
                type: 'error',
                message: 'Error fetching profile data'
            });
        }
    }


    return {
        streamCount,
        viewers,
        channelName
    };
}

export default useProfile;
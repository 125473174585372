import React from 'react';
import AuthLayout from '../../../components/Layouts/AuthLayout';
import Illustration1 from '../../../assets/images/CreateAccount4.png';
import Illustration2 from '../../../assets/images/CreateAccount5.png';
import Illustration3 from '../../../assets/images/CreateAccount6.png';
import SetupAccount1 from './SetupAccount1';
import SetupAccount2 from './SetupAccount2';
import SetupAccount3 from './SetupAccount3';
import { useSetupAccountStore } from '../../../store/setupAccountStore';
import { useSetupAccount } from '../../../hooks/user/useSetupAccount';

const SetupAccount = () => {
    const { step } = useSetupAccountStore();
    const { handleComplete } = useSetupAccount();

    const getIllustration = () => {
        switch (step) {
            case 1:
                return <img src={Illustration1} alt="Step 1 Illustration" className="w-[600px]" />;
            case 2:
                return <img src={Illustration2} alt="Step 2 Illustration" className="w-[600px]" />;
            case 3:
                return <img src={Illustration3} alt="Step 3 Illustration" className="w-[600px]" />;
            default:
                return null;
        }
    };

    const getRightContent = () => {
        switch (step) {
            case 1:
                return <SetupAccount1 />;
            case 2:
                return <SetupAccount2 />;
            case 3:
                return <SetupAccount3 onComplete={handleComplete} />;
            default:
                return null;
        }
    };

    return (
        <AuthLayout
            illustration={getIllustration()}
        >
            <>
                {getRightContent()}
            </>
        </AuthLayout>
    );
};

export default SetupAccount;

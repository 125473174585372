import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import { ReactComponent as ShortLeft } from '../../assets/icons/short_left.svg';

const Calendar: React.FC = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

    const prevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const renderCalendarDays = () => {
        const days = [];
        const today = new Date();

        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(<div key={`empty-${i}`} className="w-10 h-10" />);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            const isToday = today.getDate() === i && today.getMonth() === currentDate.getMonth() && today.getFullYear() === currentDate.getFullYear();
            const isSelected = i >= 8 && i <= 11; // Simulating selected days

            days.push(
                <div
                    key={i}
                    className={`w-6 h-6 sm:w-6 sm:h-6 md:w-6 md:h-6 ml:w-8 ml:h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full text-xs font-normal
        ${isToday ? 'border-2 border-brand-100 text-brand-100 font-semibold' : 'border border-white'}
        ${isSelected ? 'border-2 border-brand-100 text-brand-100 font-semibold' : ''}
        ${i > daysInMonth - 4 ? 'text-grey-60 border-grey-60' : 'text-white'}
    `}
                >
                    {i}
                </div>
            );
        }

        return days;
    };

    return (
        <div className="flex flex-col items-start p-0 gap-5 w-full h-[400px]">
            <div className="flex justify-between items-center w-full h-10">
                <button onClick={prevMonth} className="w-10 h-10 bg-[#000] rounded-full flex items-center justify-center">
                    <ShortLeft className="w-6 h-6 text-white" />
                </button>
                <span className="text-white text-sm font-semibold">
                    {currentDate.toLocaleString('default', { month: 'long' }).toUpperCase()}
                </span>
                <button onClick={nextMonth} className="w-10 h-10 bg-[#000] rounded-full flex items-center justify-center">
                    <ShortRight className="w-6 h-6 text-white" />
                </button>
            </div>
            <div className="grid grid-cols-7 gap-5 w-full">
                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                    <div key={day} className="w-10 h-10 flex items-center justify-center text-xs font-normal text-white">
                        {day}
                    </div>
                ))}
                {renderCalendarDays()}
            </div>
        </div>
    );
};

export default Calendar;
import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import AuthLayout from '../../../components/Layouts/AuthLayout'
import useRegister from '../../../hooks/auth/useRegister'
import Illustration1 from '../../../assets/images/CreateAccount1.png';
import Illustration2 from '../../../assets/images/CreateAccount2.png';
import CreateAccount1 from './CreateAccount1';
import CreateAccount2 from './CreateAccount2';
import { ReactComponent as ShortRightIcon } from '../../../assets/icons/short_right.svg';
import ButtonCompSpecific from '../../../components/ButtonCompSpecific';

const Register = () => {
    const { step } = useRegister();

    const getRightContent = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <CreateAccount1 />
                    </>
                )
            case 2:
                return (
                    <>
                        <CreateAccount2 />
                    </>
                )

            default:
                return (
                    <>
                    </>
                )
        }
    }

    const getIllustration = () => {
        switch (step) {
            case 1:
                return <img src={Illustration1} alt="Step 1 Illustration" className="w-[400px]" />;
            case 2:
                return <img src={Illustration2} alt="Step 2 Illustration" className="w-[400px]" />;
            default:
                return null;
        }
    };

    return (
        <AuthLayout

            topRightContent={
                <div className='flex flex-row items-center justify-center gap-4'>
                    <label className='text-sm text-grey-40'>Have an account??</label>
                    <Link
                        to='/login'
                        className='text-sm text-brand-100'
                    >
                        <ButtonCompSpecific
                            type='borderBrand'
                            rightIcon={<ShortRightIcon className="fill-brand-100" />}
                        >
                            Login
                        </ButtonCompSpecific>
                    </Link>
                </div>
            }
            rightImage={false}
            illustration={getIllustration()}
        >
            <>
                {getRightContent()}
            </>
        </AuthLayout>
    )
}

export default Register

import React from 'react';
import VerificationInput from '../../../components/VerificationInputComp';
import useRegister from '../../../hooks/auth/useRegister'
import StepProgressBar from '../../../components/StepProgressBar';

const CreateAccount2 = () => {
    const { loading, code, setCode, next, prev, handleResendCode, resendCountdown } = useRegister();

    return (
        <div className='max-w-[480px] flex flex-col items-start justify-start gap-5'>
            <div>
                <p className="text-base opacity-70 text-grey-40">Create Account</p>
            <h1 className="text-2xl font-medium mb-8 text-white">
                Check Email for Verification Code
            </h1>
            </div>
            <div className='flex flex-row justify-between items-between w-full'>
                <label className="text-sm md:text-base text-grey-40">
                Verification Code
                </label>
                <button
                        onClick={handleResendCode}
                        disabled={resendCountdown > 0 && loading}
                    className={`text-sm ${resendCountdown > 0 ? 'text-grey-40' : 'text-brand-100 '}`}
                    >
                        {resendCountdown > 0
                            ? `Resend Code in ${resendCountdown}s`
                            : 'Resend Code'}
                    </button>
            </div>
            <div className="w-full">
                <VerificationInput
                    value={code}
                    onChange={setCode}
                    onComplete={() => { }}
                />
            </div>
            {/* <div className="w-full mt-12">
                <ButtonCompSpecific
                    onClick={onBack}
                    loading={loading}
                    type='grey'
                    leftIcon={<ShortLeftIcon className="fill-grey-90" />}
                >
                    Back
                </ButtonCompSpecific>
            </div> */}
            <div className='w-full mt-24'>
            <StepProgressBar totalSteps={2} currentStep={2} />
            </div>
        </div>
    );
};

export default CreateAccount2;
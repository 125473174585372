import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

interface HLSPlayerProps {
    src: string;
}

const HLSPlayer: React.FC<HLSPlayerProps> = ({ src }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isReady, setIsReady] = useState(false); // Track if the video is ready to play

    useEffect(() => {
        if (!videoRef.current) return;

        if (Hls.isSupported()) {
            const hls = new Hls({
                debug: true,
                xhrSetup: (xhr) => {
                    xhr.withCredentials = false; // Avoid cross-origin cookie issues
                },
            });

            hls.loadSource(src);
            hls.attachMedia(videoRef.current);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                setIsReady(true); // Set video as ready to play after manifest is parsed
            });

            return () => {
                hls.destroy();
            };
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = src;
            setIsReady(true); // Set video as ready for browsers with native HLS support
        }
    }, [src]);

    const handlePlay = () => {
        videoRef.current?.play().catch((err) => {
            console.error("Error attempting to play the video:", err);
        });
    };

    return (
        <div>
            <video ref={videoRef} controls style={{ width: '100%' }} />
            {!isReady && <p>Loading video...</p>}
            {!videoRef.current?.paused && (
                <button onClick={handlePlay}>Play Video</button>
            )}
        </div>
    );
};

export default HLSPlayer;

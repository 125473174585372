import react from 'react';

export interface Tag {
    id: string;
    text: string;
}

interface LabelTagProps {
    tags: Tag[] | string[];
}

const isTagObject = (tag: Tag | string): tag is Tag => {
    return typeof tag === 'object' && 'id' in tag && 'text' in tag;
}

const LabelTagComp = ({ tags }: LabelTagProps) => {
    return (
        <div className="flex gap-2 mb-4 mt-2">
            {tags.map((tag, index) => (
                <span
                    key={isTagObject(tag) ? tag.id : index}
                    className={`text-white text-[10px] font-bold px-1 py-0.5 rounded ${index % 2 === 0 ? 'bg-blue-100' : 'bg-orange-100'
                        }`}
                >
                    {isTagObject(tag) ? tag.text : tag}
                </span>
            ))}
        </div>
    )
}

export default LabelTagComp;
import { useState, useRef, useEffect } from 'react';
import { useToast } from '../../components/ToastComp';
import { useParams, useNavigate } from 'react-router-dom';
import api from "../../services/api";

const useChannel = () => {
    const isBroadcastOn = false;
    const { id } = useParams();
    const [channel, setChannel] = useState<any>(null);
    const [broadcasts, setBroadcasts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast();
    const navigate = useNavigate();


    useEffect(() => {
        fetchData();
    }, [id]);


    const fetchData = async () => {
        setIsLoading(true);
        if (!id) {
            console.error('Channel ID not found');
            return;
        }
        try {
            const [channelResponse, broadcastsResponse] = await Promise.all([
                api.broadcast.getChannelById(id),
                api.broadcast.getBroadcastsByChannelId(id)
            ]);
            console.log("Streams Response", broadcastsResponse);
            setChannel(channelResponse);
            setBroadcasts(broadcastsResponse.broadcasts);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast({
                type: 'error',
                message: 'Error fetching data'
            });
        } finally {
            setIsLoading(false);
        }
    };


    return {
        channel,
        broadcasts,
        isLoading,
    };
}

export default useChannel;
import React from 'react';
import { create } from 'zustand';
import { IAgoraRTCClient, ILocalVideoTrack } from 'agora-rtc-sdk-ng';

interface ChannelInfo {
    id: string;
    token: string;
    channelName: string;
    uid: number;
    screenUID: number;
    screenToken: string;
}

interface StreamState {
    viewer: number;
    broadcastStarted: boolean;
    screenSharing: boolean;
    mainClient?: IAgoraRTCClient | null;
    screenClient?: IAgoraRTCClient | null;
    videoContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    thumbnailRef: React.MutableRefObject<HTMLDivElement | null>;
    cameraTrackRef: React.MutableRefObject<ILocalVideoTrack | null>;
    screenTrackRef: React.MutableRefObject<ILocalVideoTrack | null>;
    increaseViewer: () => void;
    descreaseViewer: () => void;
    startBroadcast: () => void;
    stopBroadcast: () => void;
    toggleScreenSharing: (isSharing: boolean) => void;
    setMainClient: (client?: IAgoraRTCClient | null) => void;
    setScreenClient: (client?: IAgoraRTCClient | null) => void;
    // Broadcast details
    title: string;
    description: string;
    category: string;
    duration: string;
    setTitle: (title: string) => void;
    setDescription: (description: string) => void;
    setCategory: (category: string) => void;
    setDuration: (duration: string) => void;
    channelInfo?: ChannelInfo | null;
    setChannelInfo: (info: ChannelInfo) => void;
    reset: () => void;
    isEndModalOpen: boolean;
    setIsEndModalOpen: (isOpen: boolean) => void;
}

export const useStreamStore = create<StreamState>((set) => ({
    viewer: 1,
    broadcastStarted: false,
    screenSharing: false,
    mainClient: null,
    screenClient: null,
    videoContainerRef: React.createRef(),
    thumbnailRef: React.createRef(),
    cameraTrackRef: React.createRef(),
    screenTrackRef: React.createRef(),
    startBroadcast: () => set({ broadcastStarted: true }),
    stopBroadcast: () => set({ broadcastStarted: false }),
    toggleScreenSharing: (isSharing) => set({ screenSharing: isSharing }),
    setMainClient: (client) => set({ mainClient: client }),
    setScreenClient: (client) => set({ screenClient: client }),
    increaseViewer: () => set((state) => ({ viewer: state.viewer + 1 })),
    descreaseViewer: () => set((state) => ({ viewer: state.viewer - 1 })),
    title: '',
    description: '',
    category: '',
    duration: '',
    setTitle: (title) => set({ title }),
    setDescription: (description) => set({ description }),
    setCategory: (category) => set({ category }),
    setDuration: (duration) => set({ duration }),
    channelInfo: null,
    setChannelInfo: (info) => set({ channelInfo: info }),
    reset: () => set({ 
        broadcastStarted: false,
        screenSharing: false,
        mainClient: null,
        screenClient: null,
        videoContainerRef: React.createRef(),
        thumbnailRef: React.createRef(),
        cameraTrackRef: React.createRef(),
        screenTrackRef: React.createRef(),
        title: '',
        description: '',
        category: '',
        duration: '',
        channelInfo: null 
    }),
    isEndModalOpen: false,
    setIsEndModalOpen: (isOpen) => set({ isEndModalOpen: isOpen }),
}));

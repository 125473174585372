import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services/api';
import { useUserStore } from '../../store/userStore';
import { parseErrors } from '../../utils/error';
import { useToast } from '../../components/ToastComp';
import useOTP from './useOTP';
import { useRegisterStore } from '../../store/useRegisterStore';
import { MeType } from '../../types/api';

const useRegister = () => {
  const {
    loading,
    setLoading,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    step,
    setStep,
    reset,
    setAgreeToTerms,
    agreeToTerms,
  } = useRegisterStore();

  const navigate = useNavigate();
  const { setUser } = useUserStore();
  const toast = useToast();


  const register = async () => {
    // validate inputs
    if (!email || !password || !passwordConfirm) {
      return toast({
        type: 'error',
        message: 'All fields are required',
      });
    }
    // agrees to terms.
    if (!agreeToTerms) {
      return toast({
        type: 'error',
        message: 'Please agree to terms and conditions',
      });
    }
    try {
      setLoading(true);
      await api.auth.register.signup({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        repeat: passwordConfirm,
      });
      setStep(2);
    } catch (error: any) {
      let err = parseErrors(error.response.data)
      console.log ("Error occured! ", err)
      err.general && toast({
        type:'error',
        message: err.general,
      });
    } finally {
      setLoading(false);
    }
  };

  const resendOTP = async () => {
    try {
      setLoading(true);
      const response = await api.auth.register.resendOTP({ email });
      toast({type: "info", message: "OTP resent successfully" });
    } catch (error: any) {
        let err = parseErrors(error.response.data)
        console.log ("Error occured! ", err)
        err.general && toast({
          type:'error',
          message: err.general,
        });
    } finally {
      setLoading(false);
    }
  }

  const validateOTP = async (otp: string) => {
    try {
      setLoading(true);
      const response: MeType = await api.auth.register.validateOTP({ otp, email });
      setUser(response.user);
      toast({type: "success", message: "OTP verified successfully, redirected to account setup" });
      navigate('/account-setup');
    } catch (error: any) {
      let err = parseErrors(error.response.data)
        console.log ("Error occured! ", err)
        err.general && toast({
          type:'error',
          message: err.general,
        });
    } finally {
      setLoading(false)
    }
  }

  const { code, setCode, resendCountdown, handleSendOTP, handleValidateOTP, handleResendCode } = useOTP(
    resendOTP,
    validateOTP,
    loading,
    setLoading
  );

  const next = async (): Promise<void> => {
    if (step === 1) {
      await register();
    }
  }

  const prev = (): void => {}

  return {
    register,
    resendOTP,
    validateOTP,
    loading,
    setLoading,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    step,
    setStep,
    next,
    prev,
    code,
    setCode,
    handleResendCode,
    resendCountdown
  };
};

export default useRegister;

import React from 'react';

interface DiscoverItemProps {
    category: string;
    title: string;
    description: string;
    date: string;
    readTime: string;
    categoryColor?: string;
}

const DiscoverComponent: React.FC<DiscoverItemProps> = ({
    category,
    title,
    description,
    date,
    readTime,
    categoryColor = 'text-blue-100'
}) => {
    return (
        <div className="flex flex-col gap-3 w-full max-w-[1780px]">
            <span className={`text-xs font-semibold ${categoryColor}`}>
                {category.toUpperCase()}
            </span>
            <div className="flex flex-col gap-1">
                <h2 className="text-xl font-semibold text-white">
                    {title}
                </h2>
                <p className="text-sm text-grey-40 line-clamp-2">
                    {description}
                </p>
            </div>
            <span className="text-xs text-grey-40">
                {date} • {readTime} read
            </span>
        </div>
    );
};

export default DiscoverComponent;
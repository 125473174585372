import React from 'react';

interface VideoDetailsProps {
    title: string;
    category: string;
    views: number;
    duration: string;
    imageSrc: string;
}

const VideoDetails: React.FC<VideoDetailsProps> = ({
    title,
    category,
    views,
    duration,
    imageSrc,
}) => {
    return (
        <div className="flex flex-row items-start gap-6">
            {/* Thumbnail */}
            <div className="relative w-[120px] h-20 bg-[#DBDBDB] flex-none">
                <img
                    src={imageSrc}
                    alt={title}
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-[#020318] opacity-30" />
            </div>

            {/* Details */}
            <div className="flex flex-col gap-1 h-[76px] flex-grow">
                <h3 className="font-outfit font-semibold text-[16px] leading-[20px] text-white w-full">
                    {title}
                </h3>

                <span className="font-outfit font-normal text-[12px] leading-[16px] text-[#C7F572] w-full">
                    {category}
                </span>

                <span className="font-outfit font-semibold text-[24px] leading-[32px] text-[#9FA0AD] w-full">
                    {views} Views • {duration}
                </span>
            </div>
        </div>
    );
};

export default VideoDetails;

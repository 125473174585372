const types = [
    { value: 'All', label: 'All Types' },
    { value: 'Streams', label: 'Streams' },
    { value: 'Channels', label: 'Channels' },
    // { value: 'Categories', label: 'Categories' },
    { value: 'Transcripts', label: 'Transcripts' }
]

const times = [
    { value: 'Today', label: 'Today' },
    { value: 'LastWeek', label: 'Last Week' },
    { value: 'LastMonth', label: 'Last Month' },
    { value: 'LastYear', label: 'Last Year' },
    { value: 'All', label: 'All Time' },
]

const finances = [
    { value: 'All', label: 'Finance' },
    { value: 'Aws', label: 'Aws' },
    { value: 'Salesforce', label: 'Salesforce' },
    { value: 'Manufacturing', label: 'Manufacturing' },
]
const lives = [
    { value: 'All', label: 'All Types' },
    { value: 'Live', label: 'Live' },
    { value: 'On Demand', label: 'On Demand' },
]

const sortBy = [
    { value: 'All', label: 'Sort By' },
    { value: 'Most Viewed', label: 'Most Viewed' },
    { value: 'Most Recent', label: 'Most Recent' },
]

export {
    sortBy,
    lives,
    finances,
    times,
    types
}

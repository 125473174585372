import { useState, useRef, useEffect } from 'react';
import { useToast } from '../components/ToastComp';
import { useParams, useNavigate } from 'react-router-dom';
import api from "../services/api";
import useAllChannels from './channel/useAllChannels';
import { getData } from 'ajv/dist/compile/validate';

const useExplore = () => {
    const {
        getChannels,
        channels,
        handleSelect,
    } = useAllChannels()
    const [broadcasts, setBroadcasts] = useState<any[]>([]);
    const [liveBroadcasts, setliveBroadcasts] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToast();
    const navigate = useNavigate(); 

    const fetchData = async () => {
        setLoading(true);
        try {
            const [liveResponse, recentResponse] = await Promise.all([
                api.broadcast.recent(true),
                api.broadcast.recent(false)
            ]);
            console.log ("liveResponse", liveResponse);
            console.log ("recentResponse", recentResponse, recentResponse.length);
            setliveBroadcasts(liveResponse?.broadcasts);
            setBroadcasts(recentResponse?.broadcasts);
            console.log("response from fetchData", { liveResponse, recentResponse });
        } catch (error) {
            console.error("error from fetchData", error);
            toast({
                type: 'error',
                message: 'Error fetching data'
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const loadData = () => {
            getChannels(3);
            fetchData();
        };
        loadData();
    }, []);

    return {
        handleSelect,
        channels,
        getChannels,
        loading,
        broadcasts,
        liveBroadcasts
    };
}

export default useExplore;

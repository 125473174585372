import { useState, useRef, useEffect } from 'react';
import { useToast } from '../../components/ToastComp';
import { useParams, useNavigate } from 'react-router-dom';
import api from "../../services/api";

const useAllChannels = () => {
    const { id } = useParams();
    const [channels, setChannels] = useState([]);
    const [isLoading, setIsLoaded] = useState(false);

    const toast = useToast();
    const navigate = useNavigate(); 

    const handleSelect = (id: string) => {
        navigate(`/channel/${id}/view`);
    };

    const getChannels = async (limit?: number) => {
        setIsLoaded(true);
        try {
            const response = await api.broadcast.getListOfChannels(limit);
            setChannels(response.broadcasterList);
            console.log("response from getChannels", response);
        } catch (error) {
            console.error("error from getChannels", error);
            toast({
                type: 'error',
                message: 'Error fetching channels'
            });
        }
        finally {
            setIsLoaded(false);
        }
    }

    return {
        isLoading,
        handleSelect,
        channels,
        getChannels
    };
}

export default useAllChannels;

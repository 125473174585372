import React from 'react';
import ButtonCompSpecific from '../../components/ButtonCompSpecific';
import InputComp from '../../components/InputComp';
import { Link } from 'react-router-dom';
import AuthLayout from '../../components/Layouts/AuthLayout';
import { ReactComponent as ShortRightIcon } from '../../assets/icons/short_right.svg';
import ButtonComp from '../../components/ButtonComp'
import ForgotPassword1 from '../../assets/images/ForgotPassword1.png';
import ForgotPassword2 from '../../assets/images/ForgotPassword2.png';
import ForgotPassword3 from '../../assets/images/ForgotPassword3.png';
import VerificationInput from '../../components/VerificationInputComp';
import useReset from '../../hooks/auth/useReset';

const PasswordReset = () => {
  const { loading, handleEmailSubmit, handleCodeSubmit, handleResendCode, handlePasswordSubmit, email, setEmail, code, setCode, newPassword, setNewPassword, confirmPassword, setConfirmPassword, resendCountdown, currentStep } = useReset();

  const getIllustration = () => {
    switch (currentStep) {
      case 1:
        return <img src={ForgotPassword1} alt="Forgot Password Step 1" className="w-[400px]" />;
      case 2:
        return <img src={ForgotPassword2} alt="Forgot Password Step 2" className="w-[400px]" />;
      case 3:
        return <img src={ForgotPassword3} alt="Forgot Password Step 3" className="w-[400px]" />;
      default:
        return null;
    }
  };

  return (
    <AuthLayout
      title="FORGOTTEN PASSWORD"
      subtitle={
        currentStep === 1 ? "Let's recover your account" :
          currentStep === 2 ? "Enter verification code" :
            "Set New Password"
      }
      topRightContent={
        <div className='flex flex-row items-center justify-center gap-4'>
          <label className='text-sm text-grey-40'>Remember your password?</label>
          <Link
            to='/login'
            className='text-sm text-brand-100'
          >
            <ButtonCompSpecific
              type='borderBrand'
              rightIcon={<ShortRightIcon className="fill-brand-100" />}
            >
              Back to login
            </ButtonCompSpecific>
          </Link>
        </div>
      }
      rightImage={true}
      illustration={getIllustration()}
    >
      <div className='w-full flex flex-col items-start justify-start gap-2'>
        {currentStep === 1 && (
          <>
            <label className="self-start text-sm md:text-base text-grey-40">
              Email Address
            </label>
            <div className="w-full rounded-lg overflow-hidden">
              <InputComp
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="w-full mt-12">
              <ButtonComp
                onClick={handleEmailSubmit}
                loading={loading}
                backgroundColor='bg-brand-100'
                textColor='text-grey-90'
                rightIcon={<ShortRightIcon className="fill-grey-90" />}
              >
                Send Reset Code
              </ButtonComp>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <label className="self-start text-sm md:text-base text-grey-40">
              Verification Code
            </label>
            <div className="w-full">
              <VerificationInput
                value={code}
                onChange={setCode}
                onComplete={handleCodeSubmit}
              />
            </div>
            <div className="mt-2 text-center">
              <button
                onClick={handleResendCode}
                disabled={resendCountdown > 0 && loading}
                className={`text-sm ${resendCountdown > 0 ? 'text-grey-40' : 'text-brand-100'}`}
              >
                {resendCountdown > 0
                  ? `Resend Code in ${resendCountdown}s`
                  : 'Resend Code'}
              </button>
            </div>
          </>
        )}

        {currentStep === 3 && (
          <>
            <label className="self-start text-sm md:text-base text-grey-40">
              New Password
            </label>
            <div className="w-full rounded-lg overflow-hidden">
              <InputComp
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                required
              />
            </div>
            <label className="self-start text-sm md:text-base text-grey-40 mt-4">
              Confirm Password
            </label>
            <div className="w-full rounded-lg overflow-hidden">
              <InputComp
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
                required
              />
            </div>
            <div className="w-full mt-12">
              <ButtonComp
                onClick={handlePasswordSubmit}
                loading={loading}
                backgroundColor='bg-brand-100'
                textColor='text-grey-90'
                rightIcon={<ShortRightIcon className="fill-grey-90" />}
              >
                Reset Password
              </ButtonComp>
            </div>
          </>
        )}

      </div>
    </AuthLayout>
  );
};

export default PasswordReset;

import React from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import Checklist from "../../components/List/CheckList";

interface CompleteBroadcast2Props {
    onNext: () => void;
}

const CompleteBroadcast2 = ({ onNext }: CompleteBroadcast2Props) => {
    return (
        <BecomeBroadcasterLayout>
            {/* Title Section */}
            <div className="flex flex-col gap-4 w-full">
                <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                    BROADCASTER
                </span>
                <div className="flex flex-col gap-2">
                    <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                        Set Up Your Payment Details
                    </h1>
                    <p className="text-base font-normal leading-5 text-grey-40 font-outfit">
                        To receive payments for your streams, kindly provide the following information:
                    </p>
                </div>
            </div>

            {/* Checklist Section */}
            <div className="flex flex-col gap-8 w-full">
                {/* Divider */}
                <div className="h-[1px] w-full bg-grey-80" />

                {/* Steps */}
                <div className="flex flex-col gap-8">
                    <Checklist
                        number={1}
                        title="Full Legal Name"
                        description="As registered with your bank"
                    />

                    <Checklist
                        number={2}
                        title="Bank Account Information"
                        description="Bank Name, Bank Account Number/IBAN, Routing Number/SWIFT Code, Bank Address (Street, City, State/Province, ZIP/Postal Code, Country)"
                    />

                    <Checklist
                        number={3}
                        title="Billing Address"
                        description="Your personal address associated with your bank account"
                    />

                    <Checklist
                        number={4}
                        title="Contact Information"
                        description="Email Address (For payment notifications)"
                    />

                    <Checklist
                        number={5}
                        title="Tax Information"
                        description="Tax Identification Number (E.g., SSN, EIN, or equivalent in your country), Tax Residency Country"
                    />
                </div>
            </div>

            {/* Button */}
            <ButtonCompSpecific
                type="brand"
                rightIcon={<ShortRight className="w-5 h-5" />}
                onClick={onNext}
            >
                Next
            </ButtonCompSpecific>
            <div className="flex mt-20 gap-5">
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
            </div>
        </BecomeBroadcasterLayout>
    );
};

export default CompleteBroadcast2;

import React from 'react';
import ButtonComp from '../ButtonComp';

interface PlanCompProps {
    id: string;
    title: string;
    price: string;
    period: string;
    isAlreadySub: boolean;
    bgColor?: string;
    borderColor?: string;
    textColor?: string;
    buttonColor?: string;
    dividerColor?: string;
    btnBorder?: string;
    onClick?: () => void;
}

const PlanComp: React.FC<PlanCompProps> = ({
    id,
    title,
    price,
    period,
    isAlreadySub,
    bgColor = 'bg-yellow-light',
    borderColor = 'border-transparent',
    textColor = 'text-[#020318]',
    buttonColor = 'text-[#020318]',
    dividerColor = 'bg-black',
    btnBorder = 'border-black border-2',
    onClick,
}) => {
    return (
        <div
            className={`flex flex-col justify-between p-5 w-[48%] h-[30%] rounded-lg ${bgColor} ${borderColor} border`}
        >
            {/* Title and Price Section */}
            <h2 className={`text-xl font-semibold leading-7 ${textColor}`}>
                {title}
            </h2>
            {/* Divider */}
            <div className={`w-full h-[0.5px] my-2 ${dividerColor}`} /> {/* Step 3: Use dividerColor */}
            <div className="flex flex-row justify-between items-center">
                <span className={`text-xl font-semibold leading-7 ${textColor}`}>
                    {price}
                    <span className={`text-sm font-normal ml-1 ${textColor}`}>
                        {period}
                    </span>
                </span>
                <div>
                    {isAlreadySub && id === 'starter' && (
                        <ButtonComp
                            backgroundColor="bg-transparent"
                            textColor={buttonColor}
                            borderColor={btnBorder}
                        >
                            {isAlreadySub ? 'Current' : 'Upgrade'}
                        </ButtonComp>
                    )}

                    {id === 'starter' && !isAlreadySub && (
                        <div className="h-10" />
                    )}

                    {id !== 'starter' && (
                        <ButtonComp
                            backgroundColor="bg-transparent"
                            textColor={buttonColor}
                            borderColor={btnBorder}
                            onClick={isAlreadySub ? undefined : onClick}
                        >
                            {isAlreadySub ? 'Current' : 'Upgrade'}
                        </ButtonComp>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PlanComp;
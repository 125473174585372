import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ChannelsComponent from '../../components/Feeds/ChannelsComp'
import ExploreLayout from '../../components/Layouts/ExploreLayout'
import useMediaQuery from '../../hooks/useMediaQuery'
import TagComponent from '../../components/TagComp'
import SectionHeader from '../../components/Section/SectionHeader'
import { useUserStore } from '../../store/userStore'

const AllCategoriesPage = () => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const isTablet = useMediaQuery('(max-width: 1024px)')
  const user = useUserStore(state => state.user)

  const categories = [...(user?.departments || []), ...(user?.tools || [])]

  console.log('Categories: ', categories)
  return (
    <ExploreLayout showCenterDotIcon={true}>
      <section className='w-full md:w-[700px] me:w-[800px] ml:w-[900px] lg:w-[1000px] xl:w-[1180px]  mx-auto'>
        <SectionHeader title='All Categories' />
        <div className='w-full flex flex-wrap gap-8 mx-auto'>
          {categories.map((tool, index) => (
            <Link
              className='cursor-pointer z-[100]'
              to={`/search/1?category=${tool}`}
            >
              <TagComponent
                key={index}
                label={tool}
                selected={false}
                onClick={() => console.log('Clicked')}
                size='medium'
              />
            </Link>
          ))}
        </div>
      </section>
    </ExploreLayout>
  )
}

export default AllCategoriesPage

import React from 'react';
import ButtonCompSpecific from '../../../components/ButtonCompSpecific';
import { ReactComponent as ShortRight } from '../../../assets/icons/short_right.svg';
import { ReactComponent as ShortLeft } from '../../../assets/icons/short_left.svg';
import TagComponent from '../../../components/TagComp';
import StepProgressBar from '../../../components/StepProgressBar';
import { useSetupAccount } from '../../../hooks/user/useSetupAccount';

interface SetupAccount3Props {
  onComplete: () => void;
}

const SetupAccount3: React.FC<SetupAccount3Props> = ({ onComplete }) => {
    const { options, toast, prevStep, beAsStreamer, setBeAsStreamer } = useSetupAccount();

  const selectOption = (option: 'yes' | 'no' | 'maybe') => {
    setBeAsStreamer(option);
  };

  const handleComplete = () => {
    if (!beAsStreamer) {
      toast({
        type: 'error',
        message: 'Please select an option',
        duration: 5000,
      });
      return;
    }
    onComplete();
  };

  const handleBack = () => {
    prevStep();
  };

  return (
    <div className="max-w-[480px] flex flex-col items-start justify-start gap-[60px]">
      {/* Title Section */}
      <div className="flex flex-col gap-4">
        <div> 
          <p className="text-base text-grey-40">Setup Account</p>
        <h1 className="text-2xl font-semibold text-white leading-8">
          Would you like to share your expertise by streaming as a professional on our platform?
          </h1>
        </div>
      </div>

      {/* Options Tags */}
      <div className="flex flex-wrap gap-4">
        {options.map((option) => (
          <TagComponent
            key={option.id}
            label={option.name.charAt(0).toUpperCase() + option.name.slice(1)}
            selected={beAsStreamer === option.name}
            borderColor={option.borderColor}
            onClick={() => selectOption(option.name)}
          />
        ))}
      </div>

      {/* Button Controls */}
      <div className="flex gap-4 w-full">
        <ButtonCompSpecific
          type="grey"
          leftIcon={<ShortLeft className="fill-grey-90" />}
          onClick={handleBack}
        >
          Back
        </ButtonCompSpecific>
        <ButtonCompSpecific
          type="brand"
          rightIcon={<ShortRight className="fill-grey-90" />}
          onClick={handleComplete}
        >
          Complete
        </ButtonCompSpecific>
      </div>

      {/* Progress Bars */}
      <div className='w-full mt-24'>
      <StepProgressBar totalSteps={3} currentStep={3} />
      </div>
    </div>
  );
};

export default SetupAccount3;

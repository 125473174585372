import React from 'react';
import { motion } from 'framer-motion';
import './Button.css';

interface ButtonProps {
    onClick: () => void;
    disabled?: boolean;
    children: React.ReactNode;
    icon?: React.ReactNode;
    variant?: 'default' | 'withIcon';
    type?: 'button' | 'submit' | 'reset';
    ariaLabel?: string;
}

const Button: React.FC<ButtonProps> = ({
    onClick,
    disabled = false,
    children,
    icon,
    variant = 'default',
    type = 'button',
    ariaLabel
}) => {
    return (
        <motion.button
            className={`Button Button-${variant}`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
            disabled={disabled}
            type={type}
            aria-label={ariaLabel}
        >
            {icon && (
                <motion.span
                    className="Button__icon"
                    animate={variant === 'withIcon' ? {
                        rotate: [0, 10, -10, 10, 0],
                    } : {}}
                    transition={{
                        duration: 1,
                        repeat: 3,
                        repeatType: "loop",
                        ease: "easeInOut",
                    }}
                >
                    {icon}
                </motion.span>
            )}
            <span className="Button__text">{children}</span>
        </motion.button>
    );
};

export default Button;

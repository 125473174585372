import React from 'react';

interface StepProgressBarProps {
  totalSteps: number;
  currentStep: number;
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({ totalSteps, currentStep }) => {
  return (
    <div className="w-full flex mt-3 gap-2">
      {Array.from({ length: totalSteps }).map((_, index) => {
        let bgColor = 'bg-grey-70';

        if (index < currentStep - 1) {
          bgColor = 'bg-brand-100';
        } else if (index === currentStep - 1) {
          bgColor = 'bg-brand-100';
        }

        return (
          <div
            key={index}
            className={`flex-1 h-1 rounded-[10px] ${bgColor}`}
          ></div>
        );
      })}
    </div>
  );
};

export default StepProgressBar;

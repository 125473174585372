import React from 'react';
import { useNavigate } from 'react-router-dom';
import FilterFMLogo from '../../assets/Logo.png';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ReactComponent as FilterFMFavicon } from '../../assets/logos/filterfm_favicon_brand.svg';
import ButtonComp from "../ButtonComp"
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';


interface ExploreLayoutProps {
    goBack?: boolean;
    children: React.ReactNode;
}

const BroadcastLayout: React.FC<ExploreLayoutProps> = ({ goBack = true, children }) => {
    const isMobile = useMediaQuery('(max-width: 780px)');
    const isTablet = useMediaQuery('(max-width: 1360px)');
    const navigate = useNavigate();

    return (
        <div className="flex min-h-screen bg-bg-dark-blue text-text-light relative mb-16 lg:mb-0">
            <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
                {isMobile ? (
                    // Mobile Header
                    <header className="bg-bg-dark-blue flex items-center justify-center p-4">
                        <div className="flex items-center justify-between w-full gap-4">
                            <div className="w-8 h-8 flex-shrink-0">
                                <FilterFMFavicon className="w-full h-full" />
                            </div>
                            <div className="flex-shrink-0 flex items-center">
                                <ButtonComp
                                    backgroundColor="bg-transparent"
                                    textColor='text-brand-100'
                                    borderColor="border-brand-100"
                                    rightIcon={<ShortRight className="w-4 h-4 text-brand-100" />}
                                    hoverColor="hover:bg-transparent hover:text-brand-100"
                                    pressedColor='active:bg-transparent'
                                >Go Back</ButtonComp>
                            </div>
                        </div>
                    </header>
                ) : isTablet ? (
                    // Tablet Header
                    <header className="bg-bg-dark-blue flex items-center justify-center p-4">
                        {/* Centered container with max width */}
                        <div className="flex items-center justify-between max-w-screen-lg w-full">
                            <div className="w-[120.47px]">
                                <img src={FilterFMLogo} className="h-7" alt="FilterFM Logo" />
                            </div>

                            <div className="flex items-center gap-4">
                            </div>
                            {goBack && (
                                <div className="flex-shrink-0 flex items-center">
                                    <ButtonComp
                                        onClick={() => { navigate('/explore') }}
                                        backgroundColor="bg-transparent"
                                        textColor='text-brand-100'
                                        borderColor="border-brand-100"
                                        rightIcon={<ShortRight className="w-4 h-4 text-brand-100" />}
                                        hoverColor="hover:bg-transparent hover:text-brand-100"
                                        pressedColor='active:bg-transparent'
                                    >Go Back</ButtonComp>
                                </div>
                            )}
                        </div>
                    </header>
                ) : (
                    // Desktop Header
                    <header className="bg-bg-dark-blue flex items-center justify-between p-4">
                        {/* Left section: Logo */}
                        <div className="flex-shrink-0">
                            <img src={FilterFMLogo} className="w-full max-w-[128px]" alt="FilterFM Logo" />
                        </div>
                        {/* Middle section */}
                        <div className="flex-grow flex justify-center mr-8">
                            {/* Centered container with max width */}
                            <div className="flex items-center justify-between gap-4 max-w-screen-lg w-full">
                                {/* Left side of middle section: Navigation Items */}
                                <div className="flex items-center gap-4">

                                </div>

                                {/* Right side of middle section: Search bar and Start Broadcasting Button */}
                                <div className="flex items-center gap-4">


                                </div>
                            </div>
                        </div>

                        {/* Right section: Notifications and User Profile */}
                        <div className="flex-shrink-0 flex items-center">
                            {goBack && (
                                <div className="flex-shrink-0 flex items-center">
                                    <ButtonComp
                                        onClick={() => { navigate('/explore') }}
                                        backgroundColor="bg-transparent"
                                        textColor='text-brand-100'
                                        borderColor="border-brand-100"
                                        rightIcon={<ShortRight className="w-4 h-4 text-brand-100" />}
                                        hoverColor="hover:bg-transparent hover:text-brand-100"
                                        pressedColor='active:bg-transparent'
                                    >Go Back</ButtonComp>
                                </div>
                            )}
                        </div>
                    </header>

                )}


                {/* Main Content */}
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-bg-dark-blue lg:p-6 pb-24  relative">
                    <div className="flex justify-between w-full">
                        <div className="w-[2%] lg:block lg:w-[10%]"></div>
                        <div className="max-w-screen-lg mx-auto relative mb-24">
                            {children}
                        </div>
                        <div className="lg:block w-[2%] lg:w-[10%]"></div>
                    </div>
                </main>
            </div>

        </div>
    );
};

export default BroadcastLayout;
import React from "react"

interface MenuItemProps {
    label: string;
    Icon?: React.ElementType;
    customIcon?: any,
    isActive?: boolean;
    onChange: () => void;
    redOutline?: boolean;
}

const MenuItem = ({ label, Icon, customIcon, isActive, onChange, redOutline }: MenuItemProps) => {
    return (
        <div
            className={`flex flex-row items-center p-[10px] px-[16px] gap-2 w-[280px] h-[40px] rounded-lg cursor-pointer ${isActive ? 'border border-grey-90' : 'bg-[#000]'
                }`}
            onClick={onChange}
        >
            {
                customIcon ? customIcon : null
            }
            {
                Icon ? <Icon className={`w-5 h-5 ${redOutline ? 'text-red-500' : isActive ? 'text-brand-100' : 'text-grey-40'}`} /> : null
            }

            <p className={`text-[14px] leading-[20px] ${isActive ? 'font-semibold text-white' : 'font-regular text-grey-40'} ${redOutline ? 'text-red-500' : ''}`}>
                {label}
            </p>
        </div>
    );
};

export default MenuItem;

import React, { useState } from 'react';
import Modal from '../../components/ModalComp';
import { ReactComponent as Broadcast } from '../../assets/icons/Broadcast.svg';
import InputComp from '../../components/InputComp';
import SelectComp from '../../components/SelectComp';
import { Upload, X } from 'lucide-react';
import TextAreaComp from '../../components/TextAreaComp';

interface StartBroadcastModalProps {
    isModalOpen: boolean;
    onClose: () => void;
}

const StartBroadcastModal: React.FC<StartBroadcastModalProps> = ({ isModalOpen, onClose }) => {
    const [title, setTitle] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const [Description, setDescription] = useState<string>('');

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };
    const categories = [
        { value: 'Salesforce', label: 'Salesforce' },
        { value: 'Category 2', label: 'Category 2' },
        { value: 'Category 3', label: 'Category 3' },
    ];
    const lengths = [
        { value: '30 Min', label: '30 Min' },
        { value: '1 h', label: '1 h' },
        { value: '2 Hours', label: '2 Hours' },
    ];
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFiles([...files, ...Array.from(event.target.files)]);
        }
    };

    const handleSubmit = () => {
        console.log('Start Broadcasting');
    };

    const onRemoveFile = (fileName: string) => {
        setFiles(files.filter((file) => file.name !== fileName));
    };
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    }

    return (
        <div className='bg-grey-100'>
            <Modal
                isOpen={isModalOpen}
                onClose={onClose}
                title="Start Broadcast"
                subtitle="Give us a few details"
                showNavigation={false}
                onNavigateLeft={() => console.log('Navigate left')}
                onNavigateRight={() => console.log('Navigate right')}
                footer={
                    <button
                        onClick={handleSubmit}
                        className="w-full bg-brand-100 text-grey-90 font-semibold py-2.5 rounded-lg flex items-center justify-center"
                    >
                        <Broadcast className="mr-2" />
                        Broadcast Now
                    </button>
                }
            >
                {/* Title Input */}
                <div className="flex flex-col w-full">
                    <label className="text-sm text-grey-40 mb-2">Title</label>
                    <InputComp
                        value={title}
                        onChange={handleTitleChange}
                        placeholder="Start building your grid"
                        type="text"
                        bgColor='bg-grey-90'
                    />
                </div>
                {/* Description Input */}
                <div className="flex flex-col w-full">
                    <label className="text-sm text-grey-40 mb-2">Description</label>
                    <TextAreaComp
                        value={Description}
                        onChange={handleDescriptionChange}
                        placeholder="Start building your grid"
                        bgColor='bg-grey-90'
                    />
                </div>

                {/* Category Input */}
                <div className="flex flex-col w-full">
                    <SelectComp
                        options={categories}
                        label="Category"
                        onChange={(value) => console.log(value)}
                        value={categories[0].value}
                    />
                </div>

                {/* Length Input */}
                <div className="flex flex-col w-full">
                    <SelectComp
                        options={lengths}
                        label="Length"
                        onChange={(value) => console.log(value)}
                        value={lengths[0].value}
                    />
                </div>
                <div className="relative flex flex-col items-center justify-center w-full h-[200px] border-2 border-dashed border-grey-80 rounded-lg">
                    <div className="absolute flex flex-col items-center justify-center">
                        <Upload className="text-brand-100 w-6 h-6" />
                        <p className="text-white mt-2">Drag & drop your files</p>
                    </div>
                    <input
                        type="file"
                        className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                        multiple
                        onChange={handleFileChange}
                    />
                        </div>

                {/* Uploaded Files Section */}
                <div className="flex flex-wrap gap-2 mt-2 p-4">
                    {files.map((file) => (

                        <div
                            className="flex items-center gap-2 px-3 h-10 border border-blue-100 rounded-lg"
                        >
                            <X
                                className="text-white cursor-pointer"
                                onClick={() => onRemoveFile(file.name)}
                            />
                            <span className="text-white text-xs">{file.name}</span>

                        </div>
                    ))}
                </div>

            </Modal>
        </div>
    );
};

export default StartBroadcastModal;
import React, { useState } from 'react';
import InputComp from "../InputComp";
import ButtonCompSpecific from "../ButtonCompSpecific";
import ButtonComp from "../ButtonComp";
import SelectComp from '../SelectComp';
import TabComp from '../Tabs/TabComp';
import { ChevronLeft, ChevronRight, Upload, X, Pause } from 'lucide-react';
import { ReactComponent as IconPause } from '../../assets/icons/pause.svg';
import { ReactComponent as IconStop } from '../../assets/icons/stop.svg';
import SectionHeader from '../../components/Section/SectionHeader';
import LeftRightButton from '../../components/Section/LeftRightButton';
import { ReactComponent as Move } from '../../assets/icons/move.svg';
import { ReactComponent as MoreVertical } from '../../assets/icons/more_vertical.svg';
import { ReactComponent as Broadcast } from '../../assets/icons/Broadcast.svg';
import IconButton from '../IconButton';
import TextAreaComp from '../../components/TextAreaComp';
import { useStreamStore } from '../../store/streamStore';
import useStream from '../../hooks/stream/useStream';

interface BroadCastDetailRightCompProps {
    onTitleChange: (title: string) => void;
    onStartBroadcast: () => void;
    categories?: { value: string; label: string }[];
    lengths?: { value: string; label: string }[];
    onFileUpload: (files: File[]) => void;
    onRemoveFile: (fileName: string) => void;
    uploadedFiles: string[];
    activeTab_: 'details' | 'resources';
}

const BroadCastDetailRightComp: React.FC<BroadCastDetailRightCompProps> = ({
    onTitleChange,
    onStartBroadcast,
    categories = [
        { value: 'Salesforce', label: 'Salesforce' },
        { value: 'Category 2', label: 'Category 2' },
        { value: 'Category 3', label: 'Category 3' },
    ],
    lengths = [
        { value: '30 Min', label: '30 Min' },
        { value: '1 h', label: '1 h' },
        { value: '2 Hours', label: '2 Hours' },
    ],
    onFileUpload,
    onRemoveFile,
    uploadedFiles,
    activeTab_,
}) => {
    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files) {
    //         onFileUpload(Array.from(event.target.files));
    //     }
    // };
    const {
        title,
        description,
        category,
        duration,
        setTitle,
        setDescription,
        setCategory,
        setDuration,
        setIsEndModalOpen
    } = useStreamStore();

    const [activeTab, setActiveTab] = useState<'details' | 'resources'>(activeTab_);
    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
        onTitleChange(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };

    return (
        <div className='rounded-lg w-full h-[460px]'>
            {/* Header Section */}
            <SectionHeader title="Broadcast Details">
                {/* <IconButton
                    onClick={() => console.log('Left Click')}
                    icon={<Move className='text-grey-40' />}
                    className="!w-8 !border-transparent !bg-transparent"
                /> */}
            </SectionHeader>

            <div className="flex flex-col items-start gap-5 w-full h-[396px] bg-grey-100 rounded-lg">
                {/* <TabComp
                    tabs={[
                        { label: 'Details', value: 'details' },
                        // { label: 'Resources', value: 'resources' },
                    ]}
                    onTabChange={(tab) => setActiveTab(tab as 'details' | 'resources')}
                    width='w-full'
                /> */}

                {/* Add scrollable container with custom scrollbar */}
                {/* <div className="flex-1 w-full overflow-y-auto px-4 [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:bg-grey-100 [&::-webkit-scrollbar-thumb]:bg-grey-40 [&::-webkit-scrollbar-thumb]:rounded-full"> */}
                <div className="flex-1 w-full ">
                    {activeTab === 'details' && (
                        <div className="flex flex-col gap-6 w-full">
                            {/* Title Input */}
                            <div className="flex flex-col w-full">
                                <label className="text-sm text-grey-40 mb-2">Title</label>
                                <InputComp
                                    value={title}
                                    onChange={handleTitleChange}
                                    placeholder="Start building your grid"
                                    type="text"
                                    bgColor='bg-grey-90'
                                />
                            </div>
                            {/* Description Input */}
                            <div className="flex flex-col w-full">
                                <label className="text-sm text-grey-40 mb-2">Description</label>
                                <TextAreaComp
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder="Start building your grid"
                                    bgColor='bg-grey-90'
                                />
                            </div>
                            {/* Category Input */}
                            <div className="flex flex-col w-full">
                                <SelectComp
                                    options={categories}
                                    label="Category"
                                    onChange={(value) => setCategory(value)}
                                    value={category}
                                />
                            </div>

                            {/* Length Input */}
                            <div className="flex flex-col w-full">
                                <SelectComp
                                    options={lengths}
                                    label="Length"
                                    onChange={(value) => setDuration(value)}
                                    value={duration}
                                />
                            </div>
                            <div className='mt-6'>

                                <ButtonCompSpecific
                                    type='brand'
                                    onClick={onStartBroadcast}
                                    rightIcon={<Broadcast className="w-5 h-5" />}
                                >
                                    Start Broadcast

                                </ButtonCompSpecific>
                            </div>
                        </div>
                    )}

                    {activeTab === 'resources' && (
                        <div className="flex flex-col w-full">
                            {/* <div className="relative flex flex-col items-center justify-center w-full h-[200px] border-2 border-dashed border-grey-80 rounded-lg">
                                <div className="absolute flex flex-col items-center justify-center">
                                    <Upload className="text-brand-100 w-6 h-6" />
                                    <p className="text-white mt-2">Drag & drop your files</p>
                                </div>
                                <input
                                    type="file"
                                    className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </div> */}

                            {/* Uploaded Files Section */}
                            {/* <div className="flex flex-wrap gap-2 mt-2 p-4">
                                {uploadedFiles.map((fileName) => (

                                    <div
                                        key={fileName}
                                        className="flex items-center gap-2 px-3 h-10 border border-blue-100 rounded-lg"
                                    >
                                        <X
                                            className="text-white cursor-pointer"
                                            onClick={() => onRemoveFile(fileName)}
                                        />
                                        <span className="text-white text-xs">{fileName}</span>

                                    </div>
                                ))}
                            </div> */}

                            {/* temp fields */}
                            {/* Title Input */}
                            <div className="flex flex-col w-full">
                                <label className="text-sm text-grey-40 mb-2">Title</label>
                                <InputComp
                                    value={title}
                                    onChange={handleTitleChange}
                                    placeholder="Start building your grid"
                                    type="text"
                                    bgColor='bg-grey-90'
                                />
                            </div>
                            {/* Description Input */}
                            <div className="flex flex-col w-full my-4">
                                <label className="text-sm text-grey-40 mb-2">Description</label>
                                <TextAreaComp
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder="Start building your grid"
                                    bgColor='bg-grey-90'
                                />
                            </div>

                            {/* Control Buttons */}
                            <div className="flex gap-3 w-full mt-2">
                                {/* <ButtonComp
                                    backgroundColor='bg-grey-90'
                                    textColor='text-white'
                                    rightIcon={<IconPause className="w-5 h-5" />}
                                >
                                    <span>Pause</span>
                                </ButtonComp> */}
                                <ButtonComp
                                    onClick={() => {setIsEndModalOpen(true)}}
                                    backgroundColor='bg-red-90'
                                    textColor='text-white'
                                    rightIcon={<IconStop className="w-5 h-5" />}
                                >
                                    <span>Stop</span>
                                </ButtonComp>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BroadCastDetailRightComp;

import React from 'react';
import { Link } from 'react-router-dom';

interface SectionHeaderProps {
    title: string;
    viewAllLink?: string;
    children?: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, viewAllLink, children }) => {
    return (
        <div className="flex flex-row items-center justify-between mb-6">
            {/* Title */}
            <h3 className="text-xl font-semibold mb-4 sm:mb-0">
                {title}
            </h3>
            {/* View All Button */}
            {
                viewAllLink && (
                    <Link
                        to={viewAllLink}
                        className="px-4 py-2 bg-gray-600 bg-opacity-40 text-white rounded-lg hover:bg-opacity-60 transition-all"
                    >
                        View All
                    </Link>)
            }
            {children}
        </div>
    );
};

export default SectionHeader;

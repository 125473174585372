import React, { ReactNode } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    subtitle?: string;
    showNavigation?: boolean;
    onNavigateLeft?: () => void;
    onNavigateRight?: () => void;
    footer?: ReactNode;
    children: ReactNode;
    isBottom?: boolean;
}

const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    title,
    subtitle,
    showNavigation = false,
    onNavigateLeft,
    onNavigateRight,
    footer,
    children,
    isBottom = false,
}) => {
    if (!isOpen) return null;

    return (
        <div className={`fixed inset-0 bg-black bg-opacity-50 flex ${isBottom ? 'items-end' : 'items-center'} justify-center z-50`}>
            <div className={`bg-grey-100 border border-grey-80 ${isBottom ? 'rounded-t-lg w-full' : 'rounded-lg w-[502px]'} shadow-lg max-h-[704px] flex flex-col`}>
                <div className="p-6 space-y-5 flex-grow overflow-y-auto">
                    <div className="flex justify-between items-center">
                        {title && (
                            <h2 className="text-white text-xl font-semibold">{title}</h2>
                        )}
                        {showNavigation && (
                            <div className="flex space-x-2">
                                <button onClick={onNavigateLeft} className="text-white opacity-50">
                                    <ChevronLeft size={20} />
                                </button>
                                <button onClick={onNavigateRight} className="text-white">
                                    <ChevronRight size={20} />
                                </button>
                            </div>
                        )}
                        {!showNavigation && (
                            <button
                                onClick={onClose}
                                className="text-grey-40 hover:text-white transition-colors"
                            >
                                <X size={24} />
                            </button>
                        )}
                    </div>
                    {subtitle && (
                        <p className="text-grey-30 text-sm">{subtitle}</p>
                    )}
                    <div className="space-y-5">
                        {children}
                    </div>
                </div>
                {footer && (
                    <div className="mt-auto p-6">
                        {footer}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Modal;
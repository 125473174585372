import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search, X } from 'lucide-react';
import useSearch from '../../hooks/useSearch'
import { Transcript, Broadcast, Channel } from '../../types/search'

// icons
import Aws from '../../assets/icons/aws.png';
import Finance from '../../assets/icons/finance.png';
import Salesforce from '../../assets/icons/salesforce.png';
import Manufacturing from '../../assets/icons/manufacturing.png';

import User1 from '../../assets/user1.png';
import User2 from '../../assets/user2.png';
import User3 from '../../assets/user3.png';
import User4 from '../../assets/user4.png';

import Trending from '../../assets/icons/trending.png';
import Category from '../../assets/icons/category.png';
import Tools from '../../assets/icons/tools.png';

// Default icon placeholder
const defaultIcon = 'https://via.placeholder.com/30';

// Left-side menu data
const menuItems = [
    { id: 1, name: 'Trending', icon: Trending },
    { id: 2, name: 'Categories', icon: Category },
    { id: 3, name: 'Tools', icon: Tools },
];

// Channels and Categories data
const channelsData = [
    { id: 1, name: 'Natalia Oreiro', icon: User1, description: '30k Videos • 453 Followers' },
    { id: 2, name: 'Morgan Freeman', icon: User2, description: '30k Videos • 453 Followers' },
    { id: 3, name: 'Stuart Little', icon: User3, description: '30k Videos • 453 Followers' },
    { id: 4, name: 'Miranda Morales', icon: User4, description: '30k Videos • 453 Followers' },
];

const categoriesData = [
    { id: 1, name: 'AWS', icon: Aws, description: '30k Live • 6.7k On Demand' },
    { id: 2, name: 'Salesforce', icon: Salesforce, description: '30k Live • 6.7k On Demand' },
    { id: 3, name: 'Finance', icon: Finance, description: '30k Live • 6.7k On Demand' },
    { id: 4, name: 'Manufacturing', icon: Manufacturing, description: '30k Live • 6.7k On Demand' },
];

// Stream data for filtering
const streamsData = [
    { id: 1, name: 'Keyword1' },
    { id: 2, name: 'Keyword2' },
    { id: 3, name: 'Keyword3' },
];

const MainSearch: React.FC = () => {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { 
        fetchBroadcasts,
        fetchChannels,
        loading,
        broadcasts,
        channels,
    } = useSearch();

    const handleSearchClick = () => {
        setIsActive(true);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        setIsActive(false);
    };

    // // Filtered results based on search input
    // const filteredStreams = streamsData.filter((stream) =>
    //     stream.name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    // const filteredChannels = channelsData.filter((channel) =>
    //     channel.name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    useEffect(() => {
        if (searchTerm) {
            fetchBroadcasts(searchTerm, 1, 10);
            fetchChannels(searchTerm, 1, 10);
        }
    }, [searchTerm]);

    return (
        <div>
            {/* Page overlay and search dropdown */}
            {isActive && (
                <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-[500]" onClick={handleClearSearch}>
                    <div className="absolute inset-0 flex justify-center items-start mt-4">
                        <div className="bg-bg-dark text-text-light p-4 rounded-lg shadow-lg w-[70%] flex flex-col z-[400] relative" onClick={(e) => e.stopPropagation()}>

                            {/* Search box in the popover */}
                            <div className="relative mb-6 bg-black">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="w-full bg-[#23243D] text-white placeholder-[#8E8FAD] rounded-lg py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-primary-gold"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                    autoFocus
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            setIsActive(false);
                                            navigate('/search/1?q=' + searchTerm);
                                        }
                                    }}
                                />
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#8E8FAD]" size={18} />
                                {searchTerm && (
                                    <button className="absolute right-3 top-1/2 transform -translate-y-1/2" onClick={handleClearSearch}>
                                        <X className="text-[#8E8FAD]" size={18} />
                                    </button>
                                )}
                            </div>

                            {
                                loading.broadcasts || loading.channels ? (
                                    <div className="text-white">Loading...</div>
                                ) : null
                            }

                            {/* Show filtered results if search term is entered */}
                            {searchTerm ? (
                                                                
                                <div className="flex flex-grow">
                                    <div className="w-full">
                                        {/* Streams */}
                                        {broadcasts.length > 0 && (
                                            <div className="mb-4">
                                                <h3 className="text-primary-gold mb-2">Streams</h3>
                                                <ul className="space-y-4">
                                                    {broadcasts.map((stream) => (
                                                        <Link to={`/watch?id=${stream.id}`} key={stream.id} className="flex justify-between items-center">
                                                            <div className="flex items-center space-x-2">
                                                                <div className="w-8 h-8 bg-gray-500 rounded-lg"></div>
                                                                <span className="font-semibold text-white">{stream.title}</span>
                                                            </div>
                                                            <button className="text-white">
                                                                <X size={18} />
                                                            </button>
                                                        </Link>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}

                                        {/* Channels */}
                                        {channels.length > 0 && (
                                            <div>
                                                <h3 className="text-primary-gold mb-2">Channels</h3>
                                                <ul className="space-y-4">
                                                    {channels.map((channel) => (
                                                        <Link to={`/channel/${channel.id}/view`} key={channel.id} className="flex justify-between items-center">
                                                            <div className="flex items-center space-x-2">
                                                                <div className="w-8 h-8 bg-gray-500 rounded-lg"></div>
                                                                <span className="font-semibold text-white">{channel.channel_name}</span>
                                                            </div>
                                                            <button className="text-white">
                                                                <X size={18} />
                                                            </button>
                                                        </Link>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}

                                        {/* No results found */}
                                        {broadcasts.length === 0 && channels.length === 0 && searchTerm && (
                                            <div className="text-white">No results found.</div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                // Default view when no search term is entered
                                <div className="flex flex-grow">
                                    {/* Left side menu */}
                                        {/* <div className="w-[25%] border-r border-gray-700 pr-4">
                                        <ul className="space-y-4">
                                            {menuItems.map((item) => (
                                                <li key={item.id} className="flex items-center space-x-2 text-primary-gold font-semibold">
                                                    <img src={item.icon || defaultIcon} alt={item.name} className="rounded-lg" />
                                                    <span>{item.name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div> */}

                                    {/* Right side with Channels and Categories */}
                                        {/* <div className="w-[75%] pl-6"> */}
                                        {/* Channels section */}
                                        {/* <h3 className="text-primary-gold mb-2">Channels</h3>
                                        <div className="grid grid-cols-2 gap-4">
                                            {channelsData.map((channel) => (
                                                <div key={channel.id} className="flex items-center space-x-2">
                                                    <div className="w-8 h-8 bg-gray-500 rounded-lg">
                                                        <img src={channel.icon || defaultIcon} alt={channel.name} className="rounded-lg" />
                                                    </div>
                                                    <div>
                                                        <p className="font-semibold text-white">{channel.name}</p>
                                                        <p className="text-sm text-[#8E8FAD]">{channel.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div> */}

                                        {/* Categories section */}
                                        {/* <h3 className="text-primary-gold mt-6 mb-2">Categories</h3>
                                        <div className="grid grid-cols-2 gap-4">
                                            {categoriesData.map((category) => (
                                                <div key={category.id} className="flex items-center space-x-2">
                                                    <div className="w-8 h-8  rounded">
                                                        <img src={category.icon || defaultIcon} alt={category.name} className="rounded-lg" />
                                                    </div>
                                                    <div>
                                                        <p className="font-semibold text-white">{category.name}</p>
                                                        <p className="text-sm text-[#8E8FAD]">{category.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div> */}
                                        {/* </div> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* Default search bar in the top section */}
            <div className="z-[300] relative flex-grow">
                <div className="relative flex items-center h-[40px]">
                    <Search className="absolute left-6 text-[#8E8FAD] z-[301]" size={18} />
                    <input
                        type="text"
                        placeholder="Search"
                        className="w-full h-full bg-[#23243D] text-white placeholder-[#8E8FAD] rounded-lg px-6 pl-12 focus:outline-none focus:ring-2 focus:ring-primary-gold"
                        onClick={handleSearchClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default MainSearch;

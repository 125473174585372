import React, { useState } from 'react';
import InputComp from '../../../components/InputComp';
import CategoriesComp from '../../../components/CategoriesComp';
import ButtonCompSpecific from '../../../components/ButtonCompSpecific';
import TextAreaComp from '../../../components/TextAreaComp';
import { useProfile } from '../../../hooks/profile/useProfile';
import { useUserStore } from '../../../store/userStore';
import Spinner from '../../../components/SpinnerComp';


const PersonalInfoComp = () => {
    const { about,
        tools,
        onAddTool,
        onRemoveTool,
        submit,
        firstName,
        setFirstName,
        lastName,
        setAbout,
        setLastName, email, tool, setTool, isloading, categories, category, setCategory,
        onAddCategory,
        onRemoveCategory } = useProfile();

    return (
        <div className="flex flex-col w-full min-h-[832px] bg-transparent rounded-lg relative pb-20">
            {/* Form Fields */}
            {!isloading && (
                <div className="flex flex-col items-start gap-5 w-full">
                {/* First Name */}
                <div className="flex flex-col gap-2 w-full">
                    <label className="font-outfit text-grey-40 text-[14px] leading-[20px]">First Name</label>
                    <InputComp
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Enter your first name"
                        type="text"
                        bgColor="bg-grey-90"
                            disabled={true}
                    />
                </div>

                {/* Last Name */}
                <div className="flex flex-col gap-2 w-full">
                    <label className="font-outfit text-grey-40 text-[14px] leading-[20px]">Last Name</label>
                    <InputComp
                            value={lastName || ''}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Enter your last name"
                        type="text"
                        bgColor="bg-grey-90"
                            disabled={true}
                    />
                </div>
                {/* email */}
                <div className="flex flex-col gap-2 w-full">
                    <label className="font-outfit text-grey-40 text-[14px] leading-[20px]">Email</label>
                    <InputComp
                        value={email}
                        onChange={(e) => { }}
                        disabled={true}
                        placeholder="Enter your last name"
                        type="text"
                        bgColor="bg-grey-90"
                    />
                </div>
                {/* About */}
                    {/* <div className="flex flex-col gap-2 w-full">
                    <label className="font-outfit text-grey-40 text-[14px] leading-[20px]">About</label>
                    <TextAreaComp
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                        placeholder="Tell us about yourself"
                        bgColor="bg-grey-90"
                        height="h-[120px]"
                    />
                </div> */}


                {/* Categories Component */}
                <CategoriesComp
                    value={tool}
                    categories={tools}
                    onAddCategory={() => onAddTool(tool)}
                    onChangeCategory={(e) => setTool(e.target.value)}
                        label='Tools'
                    />
                    <CategoriesComp
                        value={category}
                        categories={categories || []}
                        onAddCategory={() => onAddCategory()}
                        onChangeCategory={(e) => setCategory(e.target.value)}
                        label='Relevant Categories'
                />
                <div className="flex justify-end w-full">
                    {/* Buttons Container - Fixed at Bottom */}
                        {/* <ButtonCompSpecific
                        type='brand'
                        width="w-[160px]"
                        onClick={submit}
                        loading={isloading}
                    >
                        Save
                    </ButtonCompSpecific> */}
                </div>
                </div> 
            )}

            {/* Loading State */}
            {isloading && (
                <div className="flex justify-center items-center w-[560px]">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default PersonalInfoComp;
import { useState, useRef, useEffect } from 'react';
import { api } from '../../services/api';
import { useBecomeBroadcasterStore } from "../../store/becomeBroadcasterStore";
import { useToast } from '../../components/ToastComp';

const useRecording = () => {
    const [recording, setRecording] = useState(false);
    const [screenSharing, setScreenSharing] = useState(false);
    const [paused, setPaused] = useState(false);

    const recordedChunksRef = useRef<Blob[]>([]);
    const videoContainerRef = useRef<HTMLVideoElement | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const cameraStreamRef = useRef<MediaStream | null>(null);
    const screenStreamRef = useRef<MediaStream | null>(null);
    const audioStreamRef = useRef<MediaStream | null>(null);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const animationFrameIdRef = useRef<number | null>(null);
    const currentVideoRef = useRef<HTMLVideoElement | null>(null);
    const toast = useToast();

    const startRecording = async () => {
        try {
            // Get camera and microphone streams
            const cameraStream = await navigator.mediaDevices.getUserMedia({
                video: { width: 1280, height: 720 },
                audio: true
            });

            // Store stream references
            cameraStreamRef.current = cameraStream;

            // Set up video preview first
            if (videoContainerRef.current) {
                videoContainerRef.current.srcObject = cameraStream;
                try {
                    await videoContainerRef.current.play();
                } catch (err) {
                    console.error('Error playing preview:', err);
                }
            }

            // Set up recording 
            const canvas = document.createElement('canvas');
            canvas.width = 1280;
            canvas.height = 720;
            canvasRef.current = canvas;
            const ctx = canvas.getContext('2d');

            // Drawing function for recording
            const drawFrame = () => {
                if (ctx && videoContainerRef.current) {
                    try {
                        ctx.drawImage(videoContainerRef.current, 0, 0, canvas.width, canvas.height);
                    } catch (error) {
                        console.error('Error drawing frame:', error);
                    }
                }
                animationFrameIdRef.current = requestAnimationFrame(drawFrame);
            };
            drawFrame();

            // Set up MediaRecorder with canvas stream and camera audio
            const canvasStream = canvas.captureStream(30);
            const combinedStream = new MediaStream([
                ...canvasStream.getVideoTracks(),
                ...cameraStream.getAudioTracks(),
            ]);

            const mediaRecorder = new MediaRecorder(combinedStream, {
                mimeType: 'video/webm; codecs=vp9'
            });
            mediaRecorderRef.current = mediaRecorder;

            recordedChunksRef.current = [];
            mediaRecorder.ondataavailable = (event) => {
                if (event.data && event.data.size > 0) {
                    recordedChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.start(1000);
            setRecording(true);
            setPaused(false);
        } catch (error) {
            console.error('Error in startRecording:', error);
            toast({
                type: 'error',
                message: 'Error starting recording. Please try again and make sure you have granted camera and microphone permissions.'
            });
        }
    };

    const toggleScreenShare = async () => {
        if (!screenSharing) {
            try {
                const screenStream = await navigator.mediaDevices.getDisplayMedia({
                    video: { width: 1280, height: 720 }
                });
                screenStreamRef.current = screenStream;

                // Update video preview
                if (videoContainerRef.current) {
                    videoContainerRef.current.srcObject = screenStream;
                    try {
                        await videoContainerRef.current.play();
                    } catch (err) {
                        console.error('Error playing screen preview:', err);
                    }
                }

                screenStream.getVideoTracks()[0].onended = () => {
                    toggleScreenShare();
                };

                setScreenSharing(true);
            } catch (error) {
                console.error('Error in screen sharing:', error);
            }
        } else {
            // Switch back to camera
            if (screenStreamRef.current) {
                screenStreamRef.current.getTracks().forEach((track) => track.stop());
                screenStreamRef.current = null;
            }

            if (cameraStreamRef.current && videoContainerRef.current) {
                videoContainerRef.current.srcObject = cameraStreamRef.current;
                try {
                    await videoContainerRef.current.play();
                } catch (err) {
                    console.error('Error resuming camera preview:', err);
                }
            }

            setScreenSharing(false);
        }
    };

    const pauseRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.pause();

            if (videoContainerRef.current && !videoContainerRef.current.paused) {
                videoContainerRef.current
                    .pause()
                // .catch((error: any) => {
                //     console.error('Error pausing video:', error);
                // });
            }

            setPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'paused') {
            mediaRecorderRef.current.resume();

            if (videoContainerRef.current && videoContainerRef.current.paused) {
                videoContainerRef.current
                    .play()
                    .then(() => {
                        // Playback resumed successfully
                    })
                    .catch((error) => {
                        console.error('Error resuming video playback:', error);
                    });
            }

            setPaused(false);
        }
    };

    const stopRecording = async () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        // Stop all streams
        if (cameraStreamRef.current) {
            cameraStreamRef.current.getTracks().forEach((track) => track.stop());
            cameraStreamRef.current = null;
        }
        if (screenStreamRef.current) {
            screenStreamRef.current.getTracks().forEach((track) => track.stop());
            screenStreamRef.current = null;
        }
        if (audioStreamRef.current) {
            audioStreamRef.current.getTracks().forEach((track) => track.stop());
            audioStreamRef.current = null;
        }
        if (animationFrameIdRef.current) {
            cancelAnimationFrame(animationFrameIdRef.current);
            animationFrameIdRef.current = null;
        }

        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.onstop = async () => {
                const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
                // const url = URL.createObjectURL(blob);
                // const a = document.createElement('a');
                // a.href = url;
                // a.download = `recording-${Date.now()}.webm`;
                // document.body.appendChild(a);
                // a.click();
                // document.body.removeChild(a);
                // URL.revokeObjectURL(url);


                // Convert blob to File object
                const file = new File([blob], `recording-${Date.now()}.webm`, { type: 'video/webm' });

                try {
                    const response = await api.broadcast.saveVideoFile(file);
                    const videoUrl = response.url;
                    console.log('Video uploaded:', response);

                    // Set the video URL in the store
                    useBecomeBroadcasterStore.getState().setVideoS3Url(videoUrl);
                    // submit
                    useBecomeBroadcasterStore.getState().submitRequest();
                } catch (error) {
                    console.error('Error uploading video:', error);
                } finally {
                    recordedChunksRef.current = [];
                    setRecording(false);
                    setPaused(false);
                    setScreenSharing(false);
                }
            };
        }
    };

    useEffect(() => {
        // Cleanup on unmount
        return () => {
            stopRecording();
        };
    }, []);

    return { 
        recording,
        paused,
        screenSharing,
        videoContainerRef,
        startRecording,
        stopRecording,
        toggleScreenShare,
        pauseRecording,
        resumeRecording,
    };
};

export default useRecording;

import React, { useState, useRef, useEffect } from 'react';
import BroadcastLayout from '../../components/Layouts/BroadcastLayout';
import Chat, { Message } from '../../components/BlockBroadcast/Chat';
import Notes, { Note } from '../../components/BlockBroadcast/Notes';
import BroadCastDetailRightComp from '../../components/BlockBroadcast/BroadcastDetail';
import { ReactComponent as StartBroadcast } from '../../assets/images/start_broadcast.svg';
import TabComp from '../../components/Tabs/TabComp';
import useStream from '../../hooks/stream/useStream';
import useMediaQuery from '../../hooks/useMediaQuery';
import Modal from '../../components/ModalComp';
import ButtonComp from '../../components/ButtonComp';
import ButtonCompSpecific from '../../components/ButtonCompSpecific';
import { useStreamStore } from '../../store/streamStore';

const BroadcastLive = () => {
    const isTablet = useMediaQuery('(max-width: 1024px)');
    const [activeTab, setActiveTab] = useState('broadcast');

    const {
        broadcastStarted,
        channelInfo,
        videoContainerRef,
        screenSharing,
        thumbnailRef,
        messages,
        handleStartBroadcast,
        sendMessage,
        toggleScreenShare,
        handleStopBroadcast,
        viewer,
    } = useStream();
    const {
        isEndModalOpen,
        setIsEndModalOpen
    } = useStreamStore();

    useEffect(() => {
        handleStartBroadcast()
    }, [])

    // Independent video container that sits above layouts
    const renderVideoContainer = () => (
        <div className="w-full flex justify-center">
            <div className="w-full max-w-[1180px] flex gap-6">
                {/* Left column - Video Player */}
                <div className="w-[760px] relative">
                    {/* Live Badge */}
                    <div className="absolute top-2 left-2 bg-red-500 text-white px-3 py-1 rounded-md animate-pulse z-[100]">Live</div>
                    {/* Viewer Badge */}
                    <div className="absolute top-2 right-2 bg-grey-70 text-white px-3 py-1 rounded-md animate-pulse z-[100]">{viewer} Watching</div>

                    <div className="bg-gray-800 rounded-lg w-full relative">
                        <button
                            onClick={toggleScreenShare}
                            className="absolute bottom-4 right-4 z-20 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                        >
                            {screenSharing ? "Stop Screen Share" : "Start Screen Share"}
                        </button>

                        {broadcastStarted ? (
                            <div className="relative w-full h-[500px]">
                                <div ref={videoContainerRef} className="w-full h-full rounded-lg" />
                                <div
                                    ref={thumbnailRef}
                                    className={`absolute bottom-4 right-4 w-36 h-36 rounded-full overflow-hidden border-2 border-white ${screenSharing ? 'block' : 'hidden'
                                        } z-10`}
                                />
                            </div>
                        ) : (
                            <div className="w-full h-[500px]">
                                <StartBroadcast
                                    className="w-full h-full rounded-lg object-cover cursor-pointer"
                                    onClick={handleStartBroadcast}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Right column - Broadcast Details */}
                <div className="w-[400px] hidden lg:block">
                    <BroadCastDetailRightComp
                        onTitleChange={(title) => console.log(title)}
                        onFileUpload={(files) => console.log(files)}
                        onRemoveFile={(fileName) => console.log(fileName)}
                        uploadedFiles={["file1", "file2"]}
                        activeTab_="resources"
                        onStartBroadcast={handleStartBroadcast}
                    />
                </div>
            </div>
        </div>
    );

    const renderDesktopLayout = () => (
        <div className="flex flex-col w-full mt-6">
            <div className="w-full flex justify-center items-center">
                <section className="w-full max-w-[1180px]">
                    <div className="flex gap-6 w-full">
                        {/* Notes Section */}
                        {/* <div className="bg-grey-100 rounded-lg h-[670px] w-[650] 2xl:w-[715px]">
                        </div> */}

                        {/* Chat Section */}
                        <div className="bg-grey-100 rounded-lg h-[670px] w-full">
                            <Chat
                                messages={messages}
                                onSendMessage={sendMessage}
                                title="Live Chat"
                                placeholder="Type your message..."
                                infoMessage="Chat is now active!"
                                onLeftClick={() => console.log('Left button clicked')}
                                onRightClick={() => console.log('Right button clicked')}
                                isBroadcaster={true}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );

    const renderMobileTabletLayout = () => {
        const tabs = [
            { label: 'Broadcast', value: 'broadcast' },
            { label: 'Chat', value: 'chat' },
            // { label: 'My Notes', value: 'notes' },
        ];

        return (
            <div className="flex flex-col max-w-[1180px] px-4 pb-4 mt-6">
                {/* Tab System */}
                <div className="mt-4">
                    <TabComp
                        tabs={tabs}
                        onTabChange={setActiveTab}
                        width="w-full"
                    />

                    {/* Tab Content */}
                    <div className="mt-4">
                        {activeTab === 'broadcast' && (
                            <BroadCastDetailRightComp
                                onTitleChange={(title) => console.log(title)}
                                onFileUpload={(files) => console.log(files)}
                                onRemoveFile={(fileName) => console.log(fileName)}
                                uploadedFiles={["file1", "file2"]}
                                activeTab_="resources"
                                onStartBroadcast={handleStartBroadcast}
                            />
                        )}
                        {activeTab === 'chat' && (
                            <Chat
                                messages={messages}
                                onSendMessage={sendMessage}
                                title="Live Chat"
                                placeholder="Type your message..."
                                infoMessage="Chat is now active!"
                                onLeftClick={() => console.log('Left button clicked')}
                                onRightClick={() => console.log('Right button clicked')}
                            />
                        )}
                        {activeTab === 'notes' && null}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <BroadcastLayout goBack={false}>
            {renderVideoContainer()}
            {isTablet ? renderMobileTabletLayout() : renderDesktopLayout()}

            <Modal
                isOpen={isEndModalOpen}
                onClose={() => setIsEndModalOpen(false)}
                title="End Broadcast"
                subtitle="Are you sure you want to end your stream?"
            >
                <div className="flex gap-4 justify-end">
                    <ButtonCompSpecific
                        type="borderBrand"
                        onClick={() => setIsEndModalOpen(false)}
                    >No</ButtonCompSpecific>
                    <ButtonCompSpecific
                        type="brand"
                        onClick={handleStopBroadcast}
                    >Yes</ButtonCompSpecific>
                </div>
            </Modal>
        </BroadcastLayout>
    );
};

export default BroadcastLive;
import React from 'react';
import { CheckCircle, Users, Eye, Mic, DollarSign } from 'lucide-react';
import ProfileStat from '../../pages/Profile/My profile/ProfileStat';
import avatarImage from '../../assets/images/avatar.png';
import { useUserStore } from '../../store/userStore';
import { parseDateString } from '../../utils/timeutils';

const ProfileTopComp = ({

    followers,
    viewers,
    streams,
    revenue,
    channel_name,
    user
}: {

    followers: number;
    viewers: number;
    streams: number;
    revenue: number;
        channel_name: string;
        user: any;
    }) => {
    return (
        <div className="flex flex-col justify-center items-center p-4 lg:p-8 gap-8 isolate w-full max-w-[1200px] bg-[#3F216C] rounded-lg relative">
            {/* Background Circle */}
            <div className="absolute w-[400px] h-[380px] left-1/2 -translate-x-1/2 -top-[142px] bg-[#000] opacity-10 rounded-[200px]" />

            {/* Profile Image and Info */}
            <div className="flex flex-col items-center gap-4">
                <img
                    src={avatarImage}
                    alt="Profile"
                    className="w-24 h-24 rounded-full"
                />
                <div className="flex flex-col items-center">
                    <div className="flex items-center gap-2">
                        <p className="text-white">@{user?.username != undefined ? user?.username : user.email.split('@')[0]
                        }</p>
                        <CheckCircle className="text-green-500" />
                    </div>
                    <p className="text-white font-semibold text-xl">{channel_name}</p>
                    {/* <p className="text-white font-semibold text-xl">{user?.full_name}</p> */}
                    <p className="text-white text-sm">Member since {parseDateString(user?.member_since)}</p>
                </div>
            </div>

            {/* Statistics Section */}
            {user?.isBroadcaster && (
                <div className="grid grid-cols-2 md:grid-cols-3 w-full gap-8">
                    {/* <ProfileStat label="Followers" value={followers} Icon={Users} /> */}
                    <ProfileStat label="Viewers" value={viewers} Icon={Eye} />
                    <ProfileStat label="Streams" value={streams} Icon={Mic} />
                    <ProfileStat label="Revenue" value={`$${revenue}`} Icon={DollarSign} />
                </div>
            )}
        </div>
    );
};

export default ProfileTopComp;
import React from 'react';
import { Check } from 'lucide-react';

interface ChecklistItemProps {
    number: number;
    title: string;
    description: string;
    isCompleted?: boolean;
    titleColor?: string;
    descriptionColor?: string;
    iconBgColor?: string;
    iconColor?: string;
}

const ChecklistComponent: React.FC<ChecklistItemProps> = ({
    number,
    title,
    description,
    isCompleted = false,
    titleColor = 'text-brand-100',
    descriptionColor = 'text-grey-40',
    iconBgColor = 'bg-brand-100',
    iconColor = 'text-grey-100'
}) => {
    return (
        <div className="flex items-start gap-5 w-full">
            <div className={`relative flex-shrink-0 flex items-center justify-center w-8 h-8 ${iconBgColor} rounded-full`}>
                {isCompleted ? (
                    <Check className={`w-6 h-6 ${iconColor}`} />
                ) : (
                    <span className={`text-base font-medium ${iconColor}`}>{number}</span>
                )}
            </div>
            <div className="flex flex-col gap-1 flex-grow">
                <h3 className={`text-base font-medium ${titleColor}`}>{title}</h3>
                <p className={`text-base ${descriptionColor}`}>{description}</p>
            </div>
        </div>
    );
};

export default ChecklistComponent;
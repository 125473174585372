import React from 'react';
import { Check, Flame } from 'lucide-react';

interface StreakCellProps {
    type: 'fire' | 'number' | 'check' | 'empty';
    value?: number;
}

const StreakCell: React.FC<StreakCellProps> = ({ type, value }) => {
    let content;
    let backgroundClass = 'bg-gray-900';
    let borderClass = '';

    switch (type) {
        case 'fire':
            content = <Flame className="w-4 h-4 text-brand-100" />;
            break;
        case 'number':
            content = <span className="text-xs text-white">{value}</span>;
            break;
        case 'check':
            content = <Check className="w-5 h-5 text-gray-900" />;
            backgroundClass = 'bg-[#C7F572]';
            break;
        case 'empty':
            borderClass = 'border-2 border-dashed border-grey-60';
            backgroundClass = 'bg-transparent';
            break;
    }

    return (
        <div className={`w-6 h-6 sm:w-6 sm:h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full flex items-center justify-center ${backgroundClass} ${borderClass}`}>
            {content}
        </div>
    );
};

const StreakRightBar: React.FC = () => {
    return (
        <div className="flex flex-row sm:flex-col gap-1 justify-between items-center">
            <StreakCell type="fire" />
            <StreakCell type="number" value={2} />
            <StreakCell type="check" />
            <StreakCell type="check" />
            <StreakCell type="check" />
            <StreakCell type="empty" />
            <StreakCell type="empty" />
        </div>
    );
};

export default StreakRightBar;
export const Profile = {
    username: 'morganfree',
    name: 'Morgan Freeman',
    memberSince: '2023',
    followers: 342,
    viewers: 3453,
    streams: 23,
    revenue: 3467,
    imageSrc: '/path-to-profile-image.png',
    email: 'morganfreeman@gmail.com',
    about: 'Praesent tristique viverra mattis. Fusce eu sapien efficitur, vestibulum metus dignissim, vehicula nulla. Mauris vestibulum pellentesque dui vel maximus. Praesent suscipit, felis vitae sollicitudin dignissim, nulla quam rutrum mi, vitae convallis enim elit ac metus. Vestibulum et eros nunc. Quisque consequat malesuada ex, in euismod sem tempus vitae.',
    categories: ['Salesforce', 'Finance', 'AWS']
};

// sub section
export interface SubscriptionPlan {
    id: string;
    title: string;
    price: string;
    period: string;
    isAlreadySub: boolean;
}
export interface PaymentMethod {
    type: string;
    cardNumber: string;
    cardIcon?: string;
    isSelected?: boolean;
}


export const PaymentMethods: PaymentMethod[] = [
    {
        type: 'Credit Card',
        cardIcon: 'visa',
        cardNumber: 'XXXX XXXX XXXX 4536',
        isSelected: true
    },
    {
        type: 'Debit Card',
        cardIcon: 'mastercard',
        cardNumber: 'XXXX XXXX XXXX 4536'
    },
];

import React, { useRef, KeyboardEvent, ChangeEvent, useEffect } from 'react';

interface VerificationInputProps {
    value: string;
    onChange: (value: string) => void;
    onComplete: (code: string) => void;
}

const VerificationInput: React.FC<VerificationInputProps> = ({
    value,
    onChange,
    onComplete,
}) => {
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [isCompletedDone, setIsCompletedDone] = React.useState(false);

    useEffect(() => {
        if (!isCompletedDone && (/^\d{6}$/.test(value))) {
            setIsCompletedDone(true);
            onComplete(inputRefs.current.map((input) => input?.value).join(''));
        }
    }, [value, onComplete]);

    const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const newDigit = inputValue.slice(-1);

        if (/^\d$/.test(newDigit)) {
            // Replace the character at the current index with the new digit
            const newValue =
                value.substring(0, index) +
                newDigit +
                value.substring(index + 1, 6);
            onChange(newValue);

            // Move focus to the next input if not the last one
            if (index < 5) {
                inputRefs.current[index + 1]?.focus();
            }
        } else if (inputValue === '') {
            // If the input is cleared, remove the digit
            const newValue =
                value.substring(0, index) +
                '' +
                value.substring(index + 1, 6);
            onChange(newValue);
        }
    };

    const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            if (value[index]) {
                const newValue =
                    value.substring(0, index) +
                    '' +
                    value.substring(index + 1, 6);
                onChange(newValue);
            } else if (index > 0) {
                inputRefs.current[index - 1]?.focus();
                const newValue =
                    value.substring(0, index - 1) +
                    '' +
                    value.substring(index, 6);
                onChange(newValue);
            }
        }

        if (e.key === 'Enter') {
            if (/^\d{6}$/.test(value)) {
                onComplete(inputRefs.current.map((input) => input?.value).join(''));
            }
        }

        // Navigate with arrow keys
        if (e.key === 'ArrowLeft' && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
        if (e.key === 'ArrowRight' && index < 5) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text');
        const numbers = pastedData.replace(/[^\d]/g, '').slice(0, 6);
        if (numbers) {
            const newValue = numbers.padEnd(6, '').slice(0, 6);
            onChange(newValue);

            // Focus the next empty input
            const firstEmpty = newValue.indexOf('');
            if (firstEmpty !== -1 && firstEmpty < 6) {
                inputRefs.current[firstEmpty]?.focus();
            } else if (/^\d{6}$/.test(newValue)) {
                onComplete(inputRefs.current.map((input) => input?.value).join(''));
            }
        }
    };

    return (
        <div className="flex w-full">
            {[0, 1, 2, 3, 4, 5].map((index) => (
                <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    inputMode="numeric"
                    pattern="\d{1}"
                    maxLength={1}
                    className="flex-1 min-w-0 h-16 mx-1 bg-grey-90 rounded-lg text-center text-2xl font-semibold text-white focus:bg-grey-80 focus:border-2 focus:border-brand-100 outline-none"
                    value={value[index] || ''}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                    autoFocus={index === 0}
                    aria-label={`Digit ${index + 1}`}
                />
            ))}
        </div>
    );
};

export default VerificationInput;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserStore } from '../../store/userStore';

const ProtectTheseRoutes = () => {
  const user = useUserStore(state => state.user);
  console.log ("USER", user)
  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectTheseRoutes;

import ButtonComp from "./ButtonComp";

interface ButtonCompSpecificProps {
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    children: React.ReactNode;
    type: 'brand' | 'borderBrand' | 'borderGrey' | 'grey';
    onClick?: () => void;
    fullWidthOnMobile?: boolean;
    disabled?: boolean;
    loading?: boolean;
    width?: string;
    height?: string;
}

const ButtonCompSpecific: React.FC<ButtonCompSpecificProps> = ({
    leftIcon,
    rightIcon,
    children,
    type,
    onClick,
    fullWidthOnMobile = true,
    disabled = false,
    loading = false,
    width = '!w-full',
    height = 'h-[40px]',
}) => {
    let backgroundColor = 'bg-brand-100';
    let textColor = 'text-black';
    let borderColor = 'border-transparent';
    let hoverColor = 'hover:bg-brand-80';
    let pressedColor = 'active:bg-brand-80';
    let focusColor = 'focus:ring-brand-100';
    if (type == 'borderBrand') {
        backgroundColor = 'bg-transparent';
        textColor = 'text-brand-100';
        borderColor = 'border-brand-100 hover:border-brand-80';
        hoverColor = 'hover:text-brand-100';
        pressedColor = 'active:text-brand-90 active:border-brand-90';
        focusColor = 'focus:ring-brand-100';
    }
    else if (type == 'borderGrey') {
        backgroundColor = 'bg-transparent';
        textColor = 'text-grey-70';
        borderColor = 'border-grey-70 hover:border-grey-80';
        hoverColor = 'hover:text-grey-90';
        pressedColor = 'active:text-grey-100 active:border-grey-80';
        focusColor = 'focus:ring-grey-90';
    }
    else if (type == 'grey') {
        backgroundColor = 'bg-grey-90';
        textColor = 'text-grey-10';
        borderColor = 'border-transparent';
        hoverColor = 'hover:bg-grey-80';
        pressedColor = 'active:bg-grey-80';
        focusColor = 'focus:ring-grey-90';
    }


    return (
        <ButtonComp
            backgroundColor={backgroundColor}
            textColor={textColor}
            borderColor={borderColor}
            hoverColor={hoverColor}
            pressedColor={pressedColor}
            focusColor={focusColor}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            onClick={onClick}
            fullWidthOnMobile={fullWidthOnMobile}
            disabled={disabled}
            loading={loading}
            width={width}
            height={height}
        >
            {children}
        </ButtonComp>
    );
}

export default ButtonCompSpecific;
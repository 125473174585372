import React from 'react'
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';

interface EmptyScreenCompProps {
    title: string;
    description: string;
}

const EmptyScreenComp: React.FC<EmptyScreenCompProps> = ({ title, description }) => {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <div className="flex flex-col items-center justify-center p-8 rounded-2xl">
                <SearchIcon className="w-12 h-12 text-gray-light" />
                <p className="mt-6 text-lg text-gray-light">{title}</p>
                <p className="mt-2 text-sm text-gray-light">{description}</p>
            </div>
        </div>
    );
};

export default EmptyScreenComp;

import React from 'react';
import { ReactComponent as Broadcast } from '../../assets/icons/Broadcast.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import ButtonComp from '../../components/ButtonComp';
import MenuItem from '../../components/Menu/MenuItem';

interface MenuItemType {
    icon: 'user' | 'broadcast';
    label: string;
}

interface LeftMenuProps {
    menuItems: MenuItemType[];
    onMenuItemClick: (index: number) => void;
    activeIndex: number;
}

const LeftMenu: React.FC<LeftMenuProps> = ({ menuItems, onMenuItemClick, activeIndex }) => {
    const getIcon = (iconName: string) => {
        switch (iconName) {
            case 'user':
                return <User className={activeIndex === 0 ? 'text-brand-100' : 'text-grey-40'} />;
            case 'broadcast':
                return <Broadcast className={activeIndex === 1 ? 'text-brand-100' : 'text-grey-40'} />;
            default:
                return null;
        }
    };

    return (
        <nav className="flex flex-col justify-center items-start p-0 gap-2 w-[280px]">
            {menuItems.map((item, index) => (
                <MenuItem
                    key={index}
                    label={item.label}
                    isActive={activeIndex === index}
                    customIcon={getIcon(item.icon)}
                    onChange={() => onMenuItemClick(index)}
                />
            ))}
        </nav>
    );
};

export default LeftMenu;

import React, { useState } from 'react';
import Modal from '../../components/ModalComp';
import SelectComp from '../../components/SelectComp';
import ButtonCompSpecific from '../../components/ButtonCompSpecific';

interface CategoryModalProps {
    isModalOpen: boolean;
    onClose: () => void;
}

const CategoryModal: React.FC<CategoryModalProps> = ({ isModalOpen, onClose }) => {
    const [searchValue, setSearchValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState(['', '', '', '']);

    // Mock options for the select components
    const categoryOptions = [
        { value: 'All Type', label: 'Option 1' },
        { value: 'Last Week', label: 'Option 2' },
        { value: 'Finance', label: 'Option 3' },
        { value: 'Live', label: 'Option 4' },
    ];

    const handleOptionChange = (value: string, index: number) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = value;
        setSelectedOptions(newSelectedOptions);
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            title="Filters"
            isBottom={true}
        >
            {/* Controls Section */}
            <div className="flex flex-col gap-3 w-full">
                {/* Select Fields */}
                <SelectComp
                    value={"All type"}
                    onChange={(value) => setSearchValue(value)}
                    options={categoryOptions}
                    clearSeletion={true}
                    className="bg-grey-90"

                />
                <SelectComp
                    value={"Last week"}
                    onChange={(value) => setSearchValue(value)}
                    options={categoryOptions}
                    clearSeletion={true}
                    className="bg-grey-90"

                />
                <SelectComp
                    value={"Finance"}
                    onChange={(value) => setSearchValue(value)}
                    options={categoryOptions}
                    clearSeletion={true}
                    className="bg-grey-90"

                />
                <SelectComp
                    value={"Live"}
                    onChange={(value) => setSearchValue(value)}
                    options={categoryOptions}
                    clearSeletion={true}
                    className="bg-grey-90"

                />
                <ButtonCompSpecific
                    type="brand"
                    onClick={() => console.log('Apply button clicked')}
                >
                    Apply Filters
                </ButtonCompSpecific>
            </div>

        </Modal>
    );
};

export default CategoryModal;
import React, { useState, useEffect } from "react";
import ProfileHeroComponent from "../../components/BlockHero/ProfileHeroComp";
import LiveStreamCard from "../../components/Feeds/LiveStreamCard";
import ExploreLayout from '../../components/Layouts/ExploreLayout';
import SectionHeader from '../../components/Section/SectionHeader';
import LeftRightButton from '../../components/Section/LeftRightButton';
import BroadcastOn from "../../components/BlockSidebar/BroadcastOn";
import SelectComp from "../../components/SelectComp";
import MainSearch from "../../components/Search/MainSearch";
import { liveStreams } from '../../constants/stream';
import avatar from "../../assets/images/avatar.png";
import { ReactComponent as Eye } from '../../assets/icons/show.svg';
import DividerComp from "../../components/DividerComp";
import useMediaQuery from "../../hooks/useMediaQuery";
import InputComp from "../../components/InputComp";
import { ReactComponent as UserAdd } from '../../assets/icons/user_plus.svg';
import BroadcastOffComponent from "../../components/BlockSidebar/BroadcastOff";
import { useParams } from 'react-router-dom';
import Spinner from "../../components/SpinnerComp";
import EmptyScreenComp from "../../components/EmptyScreenComp";
import useChannel from "../../hooks/channel/useChannel";
import { parseDateString } from '../../utils/timeutils';

const ViewChannels = () => {
    const isTablet = useMediaQuery('(max-width: 1024px)');
    const { channel, broadcasts, isLoading } = useChannel();


    return (
        <ExploreLayout>
            <div className="w-full md:w-[700px] me:w-[800px] ml:w-[900px] lg:w-[1000px] xl:w-[1180px]  mx-auto">
                {/* Hero Section */}
                {/* <div className="flex flex-col lg:flex-row gap-5 mb-8"> */}
                <div className="">
                    {!isLoading ?
                        (<ProfileHeroComponent
                            username={channel?.username ?? channel?.channel_name}
                            name={channel?.channel_name}
                            memberSince={parseDateString(channel?.member_since)}
                            tags={channel?.tools}
                            avatarUrl={avatar}
                            bio={channel?.bio}
                            followers={342}
                            viewers={3453}
                            streams={23}
                            revenue={5407}
                            isLive={channel?.isLive}
                            // bgColor="bg-blue-20"
                            rightBtnTxt="Following"
                            rightBtnIcon={<UserAdd fill="bg-brand-100"
                            />}
                        />
                        ) : (null)}
                </div>
                {/* <div className="w-full lg:w-[280px]">
                        {isBroadcastOn ? (

                        <BroadcastOn
                            imageUrl="/path-to-stream-image.png"
                            isLive={true}
                            title="Strong Reasons For Hiring A Financial Advisor Or Investment Manager"
                            time="23:57"
                            viewers={2213}
                            onButtonClick={() => console.log('Watch Now Clicked')}
                            isMobile={isTablet}
                        />
                        ) : (<BroadcastOffComponent
                            nextBroadcastTime="3d 4d 43m"
                            onNotificationClick={() => console.log('Notify me when live')}
                                isMobile={isTablet}
                        />)}
                    </div> */}
                {/* </div> */}


                {/* Streams Section */}

                {!isLoading ?
                    (
                        <>
                            <div className="my-6">
                                <SectionHeader title="Streams">
                                    {broadcasts.length > 0 && (
                                        <>
                                            <LeftRightButton
                                                onLeftClick={() => console.log('Left Clicked')}
                                                onRightClick={() => console.log('Right Clicked')}
                                            />
                                        </>
                                    )}
                                </SectionHeader>
                            </div>
                            <DividerComp className="mt-6 mb-8" />
                        </>
                    ) : null}

                {/* Filters and Search */}
                {/* <div className="flex justify-between items-center my-4 gap-5">
                    <div className="flex gap-4 w-[370px]">
                            <SelectComp
                                value="Tools"
                                options={[
                                    { value: "all", label: "All" },
                                    { value: "live", label: "Live" },
                                    { value: "upcoming", label: "Upcoming" },
                                    { value: "past", label: "Past" }
                                ]}
                                onChange={() => { }}
                                width='w-full lg:w-[200px]'
                        />
                    </div>
                    <div className="relative w-[400px]">
                        <InputComp
                            placeholder="Search"
                            value=""
                            onChange={() => { }}
                        />
                    </div>
                </div> */}
                {isLoading &&
                    <Spinner />
                }
                {/* Stream Cards Grid */}
                {(!isLoading && broadcasts.length === 0) ?
                    (<EmptyScreenComp
                        title="No Streams Found"
                        description="No streams found for this channel"
                    />
                    ) : (
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-5">
                            {broadcasts.map((broadcast, index) => (
                                <LiveStreamCard
                                    key={index}
                                    title={broadcast.title}
                                    channelName={broadcast.channel_name}
                                    viewers={broadcast.viewers || 0}
                                    isLive={broadcast.isLive || false}
                                    thumbnail={broadcast.thumbnail || ''}
                                    category={broadcast.category}
                                    time={broadcast.duration_in_seconds}
                                    watching={broadcast.viewers || 0}
                                />
                            ))}
                        </div>
                    )}
            </div>
        </ExploreLayout>
    );
};

export default ViewChannels;
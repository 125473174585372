import React from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import Checklist from "../../components/List/CheckList";
interface CompleteBroadcast1Props {
    onNext: () => void;
}

const CompleteBroadcast1 = ({ onNext }: CompleteBroadcast1Props) => {
    return (
        <BecomeBroadcasterLayout>
            {/* Title Section */}
            <div className="flex flex-col gap-4 w-full">
                <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                    BROADCASTER
                </span>
                <div className="flex flex-col gap-2">
                    <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                        You're All Set to Start Streaming!
                    </h1>
                    <p className="text-base font-normal leading-5 text-grey-40 font-outfit">
                        Congratulations on becoming an approved expert on our platform. Before you begin sharing your knowledge and earning, please set up your payment details so we can process your payouts smoothly.
                    </p>
                </div>
            </div>

            {/* Checklist Section */}
            <div className="flex flex-col gap-8 w-full">
                {/* Divider */}
                <div className="h-[1px] w-full bg-grey-80" />

                {/* Steps */}
                <div className="flex flex-col gap-8">
                    <Checklist
                        number={1}
                        title="Enter Your Details"
                        description='Click the "Set Up Payment Details" button below to fill out the secure payment form.'
                    />

                    <Checklist
                        number={2}
                        title="Review and Accept Agreements"
                        description="Carefully read each document and check the box to indicate your acceptance."
                    />

                    <Checklist
                        number={3}
                        title="Confirmation"
                        description="Once submitted, you'll receive a confirmation email. Our team will verify your information, which may take up to 1-2 business days."
                    />
                </div>
            </div>

            {/* Button */}
            <ButtonCompSpecific
                type="brand"
                rightIcon={<ShortRight className="w-5 h-5" />}
                onClick={onNext}
            >
                Next
            </ButtonCompSpecific>
            <div className="flex mt-20 gap-5">
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
            </div>
        </BecomeBroadcasterLayout>
    );
};

export default CompleteBroadcast1;

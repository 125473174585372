import { create } from 'zustand';

interface RegisterState {
  loading: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  otp: string;
  step: number;
  agreeToTerms: boolean;
  setLoading: (loading: boolean) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setPasswordConfirm: (passwordConfirm: string) => void;
  setOtp: (otp: string) => void;
  setStep: (step: number) => void;
  reset: () => void;
  setAgreeToTerms: (agreeToTerms: boolean) => void;
}

export const useRegisterStore = create<RegisterState>(set => ({
  loading: false,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  otp: '',
  step: 1,
  agreeToTerms: false,
  setLoading: (loading: boolean) => set({ loading }),
  setFirstName: (firstName: string) => set({ firstName }),
  setLastName: (lastName: string) => set({ lastName }),
  setEmail: (email: string) => set({ email }),
  setPassword: (password: string) => set({ password }),
  setPasswordConfirm: (passwordConfirm: string) => set({ passwordConfirm }),
  setOtp: (otp: string) => set({ otp }),
  setStep: (step: number) => set({ step }),
  reset: () =>
    set({
      loading: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      otp: '',
      step: 1,
    }),
  setAgreeToTerms: (agreeToTerms: boolean) => set({ agreeToTerms }),
}));

import React, { useState, useEffect } from 'react';
import { User, DollarSign, Flame, Eye, LogOut } from 'lucide-react';
import MenuItem from '../../../components/Menu/MenuItem';
import useAuth from '../../../hooks/auth/useAuth';
import SpinnerComp from '../../../components/SpinnerComp';

interface LeftMenuCompProps {
    onChange: (activeItem: string) => void;
    currentlyActive: string;
    user: any;
}

const LeftMenuComp: React.FC<LeftMenuCompProps> = ({ onChange, currentlyActive, user }) => {
    const { logout, loading } = useAuth();
    const [activeItem, setActiveItem] = useState<string>('Personal Info');
    useEffect(() => {
        setActiveItem(currentlyActive);
    }, []);

    const handleMenuItemChange = (item: string) => {
        setActiveItem(item);
        onChange(item);
    };

    return (
        <div className="flex flex-col items-start gap-2 w-[280px] h-[832px]">
            <MenuItem
                label="Personal Info"
                Icon={User}
                isActive={activeItem === 'personal'}
                onChange={() => handleMenuItemChange('personal')}
            />
            <MenuItem
                label="Subscription"
                Icon={DollarSign}
                isActive={activeItem === 'subscription'}
                onChange={() => handleMenuItemChange('subscription')}
            />
            {/* <MenuItem
                label="Learning Streaks"
                Icon={Flame}
                isActive={activeItem === 'streaks'}
                onChange={() => handleMenuItemChange('streaks')}
            />
            <MenuItem
                label="Watch History"
                Icon={Eye}
                isActive={activeItem === 'history'}
                onChange={() => handleMenuItemChange('history')}
            /> */}
            {user.isBroadcaster && (
                <MenuItem
                    label="My Streams"
                    Icon={Eye}
                    isActive={activeItem === 'myStreams'}
                    onChange={() => handleMenuItemChange('myStreams')}
                /> 
            )}   
            {
                !loading ? (
                    <MenuItem
                        label="Logout"
                        Icon={LogOut}
                        isActive={activeItem === 'logout'}
                        onChange={() => logout()}
                        redOutline={true}
                    />
                ) : <SpinnerComp />
            }
        </div>
    );
};

export default LeftMenuComp;

import { useState, useRef, useEffect } from 'react';
import { useToast } from '../../components/ToastComp';
import api from "../../services/api";
import { useUserStore } from '../../store/userStore';

const useUserStreams = () => {
    const [broadcasts, setBroadcasts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast();
    const user = useUserStore((state) => state.user);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        if (!user?.id) {
            console.error('User ID not found');
            return;
        }
        setIsLoading(true);
        try {
            const broadcastsResponse = await api.broadcast.getBroadcastsByUserId(user?.id);
            setBroadcasts(broadcastsResponse.streams);
            console.log("Streams Response", broadcastsResponse);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast({
                type: 'error',
                message: 'Error fetching data'
            });
        } finally {
            setIsLoading(false);
        }
    };

    return {
        broadcasts,
        isLoading,
    };
}

export default useUserStreams;
import React, { ReactNode } from 'react';

interface IconButtonProps {
    icon: ReactNode;
    label?: string;
    onClick?: () => void;
    className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, label, onClick, className }) => {
    return (
        <button
            onClick={onClick}
            className={`
        box-border
        flex flex-row justify-center items-center
        p-2.5
        w-[270px] h-[40px]
        border-[1.5px] border-grey-70
        rounded-lg
        ${className || ''}
      `}
        >
            <div className="relative w-5 h-5 flex-shrink-0">
                {icon}
                <div className="absolute inset-0 opacity-50"></div>
            </div>
            {label && (
                <span className="ml-2 text-sm font-semibold text-white">
                    {label}
                </span>
            )}
        </button>
    );
};

export default IconButton;
import React from 'react';
import { ChevronDown, Loader2 } from 'lucide-react';

interface ButtonCompProps {
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  hoverColor?: string;
  pressedColor?: string;
  focusColor?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  fullWidthOnMobile?: boolean;
  disabled?: boolean;
  loading?: boolean;
  width?: string;
  height?: string;
}

const ButtonComp: React.FC<ButtonCompProps> = ({
  backgroundColor = 'bg-brand-100',
  textColor = 'text-grey-90',
  borderColor = 'border-transparent',
  hoverColor = '',
  pressedColor = '',
  focusColor = '',
  leftIcon,
  rightIcon,
  children,
  onClick,
  fullWidthOnMobile = true,
  disabled = false,
  loading = false,
  width = '!w-full',
  height = 'h-10',
}) => {
  const isOutlined = backgroundColor === 'bg-transparent';

  const disabledClasses = isOutlined
    ? 'bg-transparent border border-grey-80 text-grey-80'
    : 'bg-grey-90 text-grey-70';

  return (
    <button
      className={`
        flex flex-row justify-center items-center px-6 py-2.5 rounded-lg
        transition-colors duration-200 ease-in-out
        focus:outline-none
        ${fullWidthOnMobile ? 'w-full sm:w-auto' : 'w-auto'}
        ${disabled
          ? `${disabledClasses} opacity-50`
          : `${backgroundColor} ${textColor} ${borderColor} ${hoverColor} ${pressedColor} ${focusColor} ${width} ${height} border-2`
        }
        ${(disabled || loading) ? 'cursor-not-allowed' : 'cursor-pointer'}
      `}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <Loader2 className="w-5 h-5 animate-spin mr-2" />
      ) : (
        leftIcon && <span className="mr-2">{leftIcon}</span>
      )}
      <span className="font-semibold text-sm">{children}</span>
      {!loading && rightIcon && <span className="ml-2">{rightIcon}</span>}
    </button>
  );
};

export default ButtonComp;
import React from 'react';

interface TextAreaCompProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder: string;
    bgColor?: string;
    textColor?: string;
    borderColor?: string;
    labelColor?: string;
    hoverColor?: string;
    pressedColor?: string;
    focusColor?: string;
    label?: string;
    disabled?: boolean;
    width?: string;
    height?: string;
}

const TextAreaComp: React.FC<TextAreaCompProps> = ({
    value,
    onChange,
    placeholder,
    bgColor = 'bg-[#232437]',
    textColor = 'text-white',
    borderColor = 'border-transparent',
    labelColor = 'text-[#9FA0AD]',
    hoverColor = 'hover:bg-grey-80',
    pressedColor = 'active:bg-grey-70',
    focusColor = 'focus:ring-4 focus:ring-brand-100 focus:ring-opacity-10',
    label,
    disabled = false,
    width = 'w-full',
    height = 'h-[120px]',
    ...props
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        if (!disabled) onChange(e);
    };

    const disabledClass = disabled ? 'opacity-50 cursor-not-allowed' : '';

    return (
        <div className={`flex flex-col gap-2 ${width}`}>
            {label && (
                <label className={`text-sm font-normal font-outfit leading-5 ${labelColor} ${disabledClass}`}>
                    {label}
                </label>
            )}
            <div className={`flex justify-center items-center p-2.5 ${bgColor} rounded-lg`}>
                <textarea
                    className={`
            w-full ${height} ${textColor} ${borderColor}
            ${hoverColor} ${pressedColor} ${focusColor}
            outline-none bg-transparent
            px-2 py-0 resize-none
            text-sm leading-5
            transition-all duration-200
            font-outfit
            ${disabledClass}
          `}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                    {...props}
                />
            </div>
        </div>
    );
};

export default TextAreaComp;
import React from 'react';
import { Loader2 } from 'lucide-react';

const Spinner = () => {
    return (
        <div className="flex items-center justify-center">
            <Loader2
                size={32}
                strokeWidth={2}
                className="animate-spin text-secondary-gold"
            />
        </div>
    );
};

export default Spinner;

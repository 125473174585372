import React, { forwardRef, useState, useEffect } from 'react';
import { Check, Minus } from 'lucide-react';

interface CheckboxCompProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    label?: string;
    checked?: boolean;
    indeterminate?: boolean;
    bgColor?: string;
    checkedBgColor?: string;
    borderColor?: string;
    checkColor?: string;
    labelColor?: string;
    hoverBorderColor?: string;
    focusRingColor?: string;
    disabledOpacity?: string;
    onChange?: (checked: boolean) => void;
    checkedIcon?: React.ReactNode;
    indeterminateIcon?: React.ReactNode;
    disabled?: boolean;
}

const CheckboxComp = forwardRef<HTMLInputElement, CheckboxCompProps>(
    (
        {
            label,
            checked: externalChecked,
            indeterminate = false,
            bgColor = 'bg-grey-90',
            checkedBgColor = 'bg-brand-100',
            borderColor = 'border-transparent',
            checkColor = 'text-grey-100',
            labelColor = 'text-white',
            hoverBorderColor = 'hover:border-white',
            focusRingColor = 'focus:ring-white focus:ring-opacity-10',
            disabledOpacity = 'opacity-50',
            disabled = false,
            onChange,
            checkedIcon,
            indeterminateIcon,
            ...props
        },
        ref
    ) => {
        const [internalChecked, setInternalChecked] = useState(externalChecked ?? false);

        useEffect(() => {
            if (externalChecked !== undefined) {
                setInternalChecked(externalChecked);
            }
        }, [externalChecked]);

        const isChecked = externalChecked !== undefined ? externalChecked : internalChecked;

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newChecked = event.target.checked;
            setInternalChecked(newChecked);
            onChange?.(newChecked);
        };

        const checkboxClasses = `
      appearance-none
      w-4 h-4
      rounded
      ${isChecked || indeterminate ? checkedBgColor : bgColor}
      ${borderColor}
      ${disabled ? disabledOpacity : hoverBorderColor}
      ${focusRingColor}
      focus:ring-4 focus:outline-none
      transition-colors duration-200
      cursor-pointer
    `;

        const renderIcon = () => {
            if (indeterminate) {
                return indeterminateIcon || <Minus className={`w-3 h-3 ${checkColor}`} />;
            }
            if (isChecked) {
                return checkedIcon || <Check className={`w-3 h-3 ${checkColor}`} />;
            }
            return null;
        };

        return (
            <label className={`inline-flex items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                <div className="relative">
                    <input
                        type="checkbox"
                        className={checkboxClasses}
                        checked={isChecked}
                        ref={ref}
                        disabled={disabled}
                        onChange={handleChange}
                        {...props}
                    />
                    {(isChecked || indeterminate) && (
                        <span className="absolute inset-0 flex items-center justify-center pointer-events-none">
                            {renderIcon()}
                        </span>
                    )}
                </div>
                {label && (
                    <span className={`ml-2 ${labelColor} ${disabled ? disabledOpacity : ''}`}>
                        {label}
                    </span>
                )}
            </label>
        );
    }
);

CheckboxComp.displayName = 'CheckboxComp';

export default CheckboxComp;    
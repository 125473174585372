const protectedRoutesConsts: string[] = [
    "/login",
    "/register",
    "/forgot-password",
    "/video",
    "/explore",
    "/my-channel",
    "/broadcast",
    "/broadcast/live",
    "/broadcast/setup",
    "/search/1",
    "/search/2",
    "/my-profile",
    "/all-channels",
    "/channel/:id/view",
    "/become-broadcaster",
    "/complete-broadcast",
    "/all-categories",
    "/:id/view",
    "/account-setup",
    "/watch"
];

const authRoutesConsts: string[] = [
    "/login",
    "/register",
    "/forgot-password",
]

export {
    protectedRoutesConsts,
    authRoutesConsts
}


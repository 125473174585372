import { useState, useEffect } from 'react';
import AgoraRTC, { IAgoraRTCClient, ILocalVideoTrack, ILocalAudioTrack } from 'agora-rtc-sdk-ng';
import { useToast } from '../../components/ToastComp';
import { useStreamStore } from '../../store/streamStore';
import { useUserStore } from '../../store/userStore';
import api from '../../services/api';
import useStreamChat from './useStreamChat';
import { BroadcastSetupType } from '../../types/api';
import { showErrors } from '../../utils/error';
import { useNavigate } from 'react-router-dom';

const APP_ID: string = process.env.REACT_APP_AGORA_APP_ID ?? "";
const useStream = () => {
    const {
        broadcastStarted,
        screenSharing,
        mainClient,
        screenClient,
        videoContainerRef,
        thumbnailRef,
        cameraTrackRef,
        screenTrackRef,
        startBroadcast,
        stopBroadcast,
        toggleScreenSharing,
        setMainClient,
        setScreenClient,
        title,
        description,
        category,
        duration,
        channelInfo,
        setChannelInfo,
        reset,
        viewer,
        increaseViewer,
        descreaseViewer
    } = useStreamStore();

    const user = useUserStore((state) => state.user);
    const toast = useToast();
    const navigate = useNavigate();

    const { messages, sendMessage, setupRTM } = useStreamChat();

    // Function to start the broadcast
    const handleStartBroadcast = async () => {
        try {
            const data: BroadcastSetupType = {
                title,
                description,
                category,
                duration
            };
            // Validate fields
            if (!title || !description || !category || !duration) {
                toast({
                    type: 'error',
                    message: 'All fields are required'
                });
                return;
            }

            const response = await api.broadcast.start(data);
            console.log('API Response:', response);

            const { token, channelName, uid, screenToken, screenUID, broadcast } = response || {};
            if (!token || !channelName || !uid) {
                throw new Error('Token, channelName, or uid is missing in the API response');
            }
            console.log("Channel Name", channelName, response.boradcast);
            setChannelInfo({ id: broadcast.id, token, channelName, uid: Number(uid), screenToken, screenUID });
            startBroadcast();
            await initializeMainClient(channelName, token, Number(uid));
            await setupRTM(channelName);
        } catch (error: any) {
            showErrors(error, toast);
        }
    };

    // Initialize the main Agora client for camera and audio
    const initializeMainClient = async (channelName: string, token: string, uid: number) => {
        try {
            // Initialize main Agora client
            const mainClientInstance: IAgoraRTCClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
            setMainClient(mainClientInstance);

            if (mainClientInstance) {
                await mainClientInstance.setClientRole('host');
                await mainClientInstance.enableDualStream();
                // set fallback settings.
                await mainClientInstance.setLowStreamParameter({
                    width: 160,
                    height: 120,
                    framerate: 15,
                    bitrate: 100,
                });

                await mainClientInstance.join(APP_ID, channelName, token, uid);

                // Create and publish camera and audio tracks
                const cameraTrack = await AgoraRTC.createCameraVideoTrack({
                    // Specify custom encodings.
                    encoderConfig: {
                        width: 1280,
                        height: 720,
                        frameRate: 25,
                        bitrateMin: 600,
                        bitrateMax: 4000,
                    },
                    optimizationMode: 'motion',
                });
                const audioTrack: ILocalAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

                cameraTrackRef.current = cameraTrack;

                if (videoContainerRef.current) {
                    // Play camera in main container or assign to thumbnail
                    cameraTrack.play(videoContainerRef.current);
                }

                // Publish the video and audio.
                await mainClientInstance.publish([cameraTrack, audioTrack]);
                console.log("Camera and audio tracks published successfully");

                // Listen network quality.
                mainClientInstance.on('network-quality', (obj: any) => {
                    console.log("net-", JSON.stringify(obj, null, 2), obj.uplinkNetworkQuality <= 3, obj.uplinkNetworkQuality);
                    if (obj.uplinkNetworkQuality >= 3) {
                        toast({
                            type: 'info',
                            message: 'Your network quality is poor. Video quality may be affected.',
                        });
                    }
                });
                // Listen for new users joining the channel
                mainClientInstance.on('user-published', async (user, mediaType) => {
                    // Increment viewer count when a new user joins
                    increaseViewer();
                });
                // user leave.
                mainClientInstance.on('user-unpublished', (user) => {
                    descreaseViewer();
                });
            }
        } catch (error: any) {
            if (error.name === 'NotAllowedError' || error.name === 'NotFoundError') {
                console.error('Permission denied or device not found:', error);
            } else {
                console.error('Error initializing main Agora client:', error);
            }
        }
    };

    const toggleScreenShareFunction = async () => {
        if (!screenSharing) {
            try {
                if (!channelInfo) {
                    console.error("Channel info is null. Cannot start screen sharing.");
                    return;
                }

                // Step 1: Move Camera Track to Thumbnail
                await assignCameraTrackToThumbnail();

                // Step 2: Initialize and Join Screen Client
                await initializeScreenClient();

                toggleScreenSharing(true);
                console.log("Screen sharing started.");
            } catch (error: any) {
                console.error("Error during screen sharing setup:", error);
                toast({
                    type: 'error',
                    message: 'Failed to start screen sharing. Check network and try again.',
                });
            }
        } else {
            await handleStopScreenShare();
        }
    };

    const assignCameraTrackToThumbnail = async () => {
        console.log("Assigning camera to thumbnail...")
        if (cameraTrackRef.current && thumbnailRef.current) {
            thumbnailRef.current.innerHTML = '';
            cameraTrackRef.current.play(thumbnailRef.current);
            console.log("Camera track assigned to thumbnail.");
        } else {
            console.log("Camera track reference is missing. Reinitializing camera track...");
        }
    };


    // Function to initialize and join the screen client
    const initializeScreenClient = async () => {
        const screenClientInstance: IAgoraRTCClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
        screenClientInstance.setClientRole('host');
        setScreenClient(screenClientInstance);
        screenClientInstance.on('connection-state-change', (curState, prevState) => {
            console.log(`Screen client state changed from ${prevState} to ${curState}`);
            if (curState === 'DISCONNECTED') {
                console.warn('Screen client disconnected unexpectedly. Attempting to reconnect...');
            }
        });

        try {
            // await screenClientInstance.enableDualStream();
            // await screenClientInstance.setLowStreamParameter({
            //     width: 160,
            //     height: 120,
            //     framerate: 15,
            //     bitrate: 5,
            // });
            await screenClientInstance.join(APP_ID, channelInfo!.channelName, channelInfo!.screenToken, channelInfo!.screenUID);
            console.log("Screen client joined the channel.");

            const screenTrack: any = await AgoraRTC.createScreenVideoTrack({
                encoderConfig: {
                    width: 640,
                    height: 360,
                    frameRate: 15,
                    bitrateMin: 260,
                    bitrateMax: 400,
                },
            
                optimizationMode: 'motion',
            });
            screenTrackRef.current = screenTrack;

            console.log("Screen track", screenTrack)
            // Assign screen share track to the main video container
            if (videoContainerRef.current) {
                videoContainerRef!.current!.innerHTML = '';
                screenTrack.play(videoContainerRef.current);
                console.log("Screen share track assigned to main video container.");
            } else {
                console.log("Video container is missing.")
            }
            console.log("Screen share client", screenClientInstance)
            if (screenClientInstance) {
                await screenClientInstance.publish(screenTrack);
                console.log("Screen share track published successfully.");
            }
            // Handle screen sharing stop event from browser
            screenTrack.on('track-ended', async () => {
                console.log('Screen sharing has been stopped from the browser.');
                await handleStopScreenShare();
            });
        } catch (error) {
            console.error("Error joining screen client:", error);
        }
    };

    // Function to stop screen share and reassign the camera track to the main video container
    const handleStopScreenShare = async () => {
        if (screenTrackRef.current && screenClient) {
            try {
                await screenClient.unpublish(screenTrackRef.current);
                await screenClient.leave();
                console.log("Screen share track unpublished and client left the channel.");

                screenTrackRef.current.stop();
                screenTrackRef.current.close();
                screenTrackRef.current = null;
            } catch (error) {
                console.error("Error stopping screen share:", error);
            }
        }

        // Clear main container and assign camera track back to it
        if (cameraTrackRef.current) {
            if (videoContainerRef.current) {
                videoContainerRef.current.innerHTML = ''; // Clear main container
                cameraTrackRef.current.play(videoContainerRef.current);
                console.log("Camera track reassigned to main video container.");
            }

            // Clear thumbnail if not needed
            if (thumbnailRef.current) {
                thumbnailRef.current.innerHTML = ''; // Clear thumbnail container
                console.log("Thumbnail cleared after stopping screen share.");
            }
        } else {
            // Reinitialize camera track if it's missing
            console.warn("Camera track reference is missing, reinitializing camera track.");
            const cameraTrack = await AgoraRTC.createCameraVideoTrack();
            cameraTrackRef.current = cameraTrack;

            if (videoContainerRef.current) {
                cameraTrack.play(videoContainerRef.current);
            }

            if (thumbnailRef.current) {
                thumbnailRef.current.innerHTML = ''; // Clear any previous elements
                cameraTrack.play(thumbnailRef.current);
            }

            await mainClient!.publish([cameraTrack]);
            console.log("Reinitialized and published camera track.");
        }

        toggleScreenSharing(false);
        console.log("Screen sharing stopped.");
    };

    const handleStopBroadcast = async () => {
        try {
            console.log("Stopping broadcast...");
    
            // 1. Unpublish and close camera track
            if (cameraTrackRef.current && mainClient) {
                try {
                    await mainClient!.unpublish(cameraTrackRef.current);
                cameraTrackRef.current.stop();
                cameraTrackRef.current.close();
                cameraTrackRef.current = null;
                } catch (error) {
                    console.error("Error", error)
                }
            }
    
            // 2. Unpublish and close screen track
            if (screenTrackRef.current && screenClient) {
                await screenClient.unpublish(screenTrackRef.current);
                screenTrackRef.current.stop();
                screenTrackRef.current.close();
                screenTrackRef.current = null;
            }
    
            // 3. Leave main client and remove listeners
            if (mainClient) {
                mainClient.removeAllListeners();
                await mainClient.leave();
                setMainClient(null);
            }
    
            // 4. Leave screen client and remove listeners
            if (screenClient) {
                screenClient.removeAllListeners();
                await screenClient.leave();
                setScreenClient(null);
            }
    
            // 5. Call API to update the server and end the session
            await api.broadcast.stop(channelInfo!.id);
            
            // Update UI and state
            stopBroadcast();
            reset();
            toast({
                type: 'success',
                message: 'Broadcast stopped successfully.',
            });
            navigate('/my-profile');
        } catch (error) {
            console.error("Error stopping broadcast:", error);
            toast({
                type: 'error',
                message: 'Failed to stop broadcast. Please try again.',
            });
        }
    };
    
    // Cleanup on component unmount
    // useEffect(() => {
    //     return () => {
    //         // Leave main client
    //         if (mainClient) {
    //             mainClient.leave().then(() => {
    //                 console.log("Main client left the channel");
    //             }).catch((err: any) => {
    //                 console.error("Error leaving main client:", err);
    //             });
    //         }

    //         // Leave screen share client
    //         if (screenClient) {
    //             screenClient.leave().then(() => {
    //                 console.log("Screen share client left the channel");
    //             }).catch((err: any) => {
    //                 console.error("Error leaving screen share client:", err);
    //             });
    //         }

    //         // Stop and close all tracks
    //         cameraTrackRef.current?.stop();
    //         cameraTrackRef.current?.close();
    //         screenTrackRef.current?.stop();
    //         screenTrackRef.current?.close();
    //     };
    // }, [mainClient, screenClient]);

    return {
        broadcastStarted,
        channelInfo,
        videoContainerRef,
        screenSharing,
        thumbnailRef,
        cameraTrackRef,
        screenTrackRef,
        messages,
        handleStartBroadcast,
        sendMessage,
        toggleScreenShare: toggleScreenShareFunction,
        handleStopBroadcast,
        viewer, // Ensure viewer is included
    };
};

export default useStream;

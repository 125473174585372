import React from 'react';
import { ReactComponent as Broadcast } from '../../assets/icons/Broadcast.svg';
import { ReactComponent as Fire } from '../../assets/icons/Fire.svg';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import ButtonComp from '../../components/ButtonComp';
import ButtonCompSpecific from '../../components/ButtonCompSpecific';

interface Stat {
    icon: string;
    label: string;
    value: string | number;
}

interface StreakStatListProps {
    stats: Stat[];
    onStartBroadcast: () => void;
}


const StreakStatList: React.FC<StreakStatListProps> = ({ stats, onStartBroadcast }) => {
    const getIcon = (iconName: string) => {
        switch (iconName) {
            case 'broadcast':
                return <Broadcast className="text-brand-100" />;
            case 'fire':
                return <Fire className="text-brand-100" />;
            case 'clock':
                return <Clock className="text-brand-100" />;
            default:
                return null;
        }
    };

    return (
        <div className="flex p-[5px] flex-row sm:flex-col items-start w-full md:w-[275px] lg:w-[338px] !rounded-lg" >
            <h2 className="hidden md:block text-white text-xl font-semibold leading-7">
                Streaks
            </h2>
            <div className="flex sm:flex-col sm:gap-4 sm:mt-10 justify-between w-full">
                {stats.map((stat, index) => (
                    <div key={index} className="flex flex-col sm:flex-row items-center gap-2">
                        <div className="flex flex-col items-start gap-3 sm:gap-4">
                            <div className="flex flex-col sm:flex-row gap-4 items-start">
                                <div className="w-4 h-4 sm:w-5 sm:h-5">
                                    {getIcon(stat.icon)}
                                </div>
                                <span className="text-grey-40 text-xs sm:text-sm leading-4 sm:leading-5">{stat.label}</span>
                            </div>
                            <span className="text-white text-lg sm:text-2xl font-semibold leading-7 sm:leading-8">{stat.value}</span>
                        </div>
                    </div>
                ))}
            </div>
            {/* <div className="flex-grow" /> */}

            {/* <ButtonCompSpecific
                type='brand'
                leftIcon={<Broadcast className="text-black" />}
            >
                <span className="text-grey-90 text-sm font-semibold" > Start Broadcasting </span>
            </ButtonCompSpecific> */}
        </div >
    );
};

export default StreakStatList;
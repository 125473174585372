import React from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import Checklist from "../../components/List/CheckList";

interface CompleteBroadcast3Props {
    onNext: () => void;
}

const CompleteBroadcast3 = ({ onNext }: CompleteBroadcast3Props) => {
    return (
        <BecomeBroadcasterLayout>
            {/* Title Section */}
            <div className="flex flex-col gap-4 w-full">
                <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                    BROADCASTER
                </span>
                <div className="flex flex-col gap-2">
                    <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                        Agreements to Accept
                    </h1>
                    <p className="text-base font-normal leading-5 text-grey-40 font-outfit">
                        Before we can process your payments, please review and accept the following terms:
                    </p>
                </div>
            </div>

            {/* Checklist Section */}
            <div className="flex flex-col gap-8 w-full">
                {/* Divider */}
                <div className="h-[1px] w-full bg-grey-80" />

                {/* Agreements */}
                <div className="flex flex-col gap-8">
                    <Checklist
                        number={1}
                        isCompleted={true}
                        title="Payment Agreement"
                        description="Outlines the payment schedule, methods, fees, and minimum payout thresholds."
                    />

                    <Checklist
                        number={2}
                        isCompleted={true}
                        title="Terms of Service"
                        description="Governs your use of our platform, including content guidelines and community standards."
                    />

                    <Checklist
                        number={3}
                        isCompleted={true}
                        title="Privacy Policy"
                        description="Details how we collect, use, and protect your personal information."
                    />

                    <Checklist
                        number={4}
                        isCompleted={true}
                        title="Tax Obligations Acknowledgment"
                        description="Confirms your responsibility to report and pay any taxes due on your earnings."
                    />
                </div>

                {/* Divider */}
                <div className="h-[1px] w-full bg-grey-80" />

                {/* Help Section */}
                <div className="flex flex-col gap-2">
                    <h3 className="text-base font-semibold text-white">
                        Need help?
                    </h3>
                    <p className="text-sm text-grey-40 leading-5">
                        If you have questions or need assistance, please contact our support team at{' '}
                        <a href="mailto:support@example.com" className="text-brand-100 hover:underline">
                            support@example.com
                        </a>
                        . We're thrilled to have you on board and can't wait to see you share your expertise!
                    </p>
                </div>
            </div>

            {/* Button */}
            <ButtonCompSpecific
                type="brand"
                rightIcon={<ShortRight className="w-5 h-5" />}
                onClick={onNext}
            >
                Let's Go
            </ButtonCompSpecific>
            <div className="flex mt-20 gap-5">
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
            </div>
        </BecomeBroadcasterLayout>
    );
};

export default CompleteBroadcast3;

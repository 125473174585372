import React, { useState, useRef } from 'react';
import BroadcastLayout from '../../../components/Layouts/BroadcastLayout';
import Chat from '../../../components/BlockBroadcast/Chat';
import BroadCastDetailRightComp from '../../../components/BlockBroadcast/BroadcastDetail';
import { ReactComponent as StartBroadcast } from '../../../assets/StartBroadcast.svg';
import TabComp from '../../../components/Tabs/TabComp';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useStreamStore } from '../../../store/streamStore';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../components/ToastComp';
import CountdownRedirect from '../../../components/CountdownRedirect';

const BroadcastSetup = () => {
    const isTablet = useMediaQuery('(max-width: 1024px)');
    const [activeTab, setActiveTab] = useState('broadcast');
    const toast = useToast();
    const { title, description, category, duration } = useStreamStore();
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();

    const onSubmit = () => {
        if (!title || !description || !category || !duration) {
            toast({
                "type": "error",
                "message": "Please fill all the fields"
            });
            return;
        }

        if (title.length < 6) {
            toast({
                "type": "error",
                "message": "Title must be at least 10 characters long"
            });
            return;
        }

        if (description.length < 20) {
            toast({
                "type": "error",
                "message": "Description must be at least 20 characters long"
            });
            return;
        }
        setSubmitted(true);
        //navigate('/broadcast/live')
    }

    // Independent video container that sits above layouts
    const renderVideoContainer = () => (
        <div className="w-full flex justify-center">
            <div className="w-full max-w-[1180px] flex gap-6">
                {/* Left column - Video Player */}
                <div className="w-[760px]">
                    <div className="bg-gray-800 rounded-lg w-full relative">
                        <StartBroadcast
                            className="w-full h-full  object-cover cursor-pointer"
                            onClick={() => console.log('Start Broadcast')}
                        />
                    </div>
                </div>

                {/* Right column - Broadcast Details */}
                {/* <div className="w-[400px] hidden lg:block">
                    <BroadCastDetailRightComp
                        onTitleChange={(title) => console.log(title)}
                        onFileUpload={(files) => console.log(files)}
                        onRemoveFile={(fileName) => console.log(fileName)}
                        uploadedFiles={["file1", "file2"]}
                        activeTab="details"
                        onStartBroadcast={handleStartBroadcast}
                    />
                </div> */}
            </div>
        </div>
    );

    const renderDesktopLayout = () => (
        <div className="flex flex-col w-full mt-6">
            <div className="w-full flex justify-center items-center">
                <section className="w-full max-w-[1180px]">
                    <div className="flex gap-6 w-full">
                        {/* Notes Section */}
                        <div className="bg-grey-100 rounded-lg h-[670px] w-[650] 2xl:w-[715px]">
                            {/* Notes component placeholder */}
                        </div>

                        {/* Chat Section */}
                        <div className="bg-grey-100 rounded-lg h-[670px] w-[400px]">

                        </div>
                    </div>
                </section>
            </div>
        </div>
    );

    //function for temporry layout 
    const renderLayout = () => {
        return (
            <div className="w-full max-w-[1180px] flex flex-col justify-center mx-auto">
                <div className=" w-full   ">
                    <StartBroadcast
                        className="w-full h-full object-cover cursor-pointer"
                    />
                </div>
                <div className="my-4">
                    <BroadCastDetailRightComp
                        onTitleChange={(title) => console.log(title)}
                        onFileUpload={(files) => console.log(files)}
                        onRemoveFile={(fileName) => console.log(fileName)}
                        uploadedFiles={["file1", "file2"]}
                        activeTab_="details"
                        onStartBroadcast={() => onSubmit()}
                    />
                </div>
            </div >
        );
    }
    const renderMobileTabletLayout = () => {
        const tabs = [
            { label: 'Broadcast', value: 'broadcast' },
            { label: 'Chat', value: 'chat' },
            // { label: 'My Notes', value: 'notes' },
        ];

        return (
            <div className="flex flex-col max-w-[1180px] px-4 pb-4 mt-6">
                {/* Tab System */}
                <div className="mt-4">
                    <TabComp
                        tabs={tabs}
                        onTabChange={setActiveTab}
                        width="w-full"
                    />

                    {/* Tab Content */}
                    <div className="mt-4">
                        {activeTab === 'broadcast' && (
                            <BroadCastDetailRightComp
                                onTitleChange={(title) => console.log(title)}
                                onFileUpload={(files) => console.log(files)}
                                onRemoveFile={(fileName) => console.log(fileName)}
                                uploadedFiles={["file1", "file2"]}
                                activeTab_="details"
                                onStartBroadcast={() => console.log('Start Broadcast')}
                            />
                        )}
                        {activeTab === 'chat' && (
                            <Chat
                                messages={[]}
                                onSendMessage={() => console.log('Send message')}
                                title="Live Chat"
                                placeholder="Type your message..."
                                infoMessage="Chat is now active!"
                                onLeftClick={() => console.log('Left button clicked')}
                                onRightClick={() => console.log('Right button clicked')}
                            />
                        )}
                        {activeTab === 'notes' && null}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <BroadcastLayout>
            {renderLayout()}
            {
                submitted && <CountdownRedirect
                    onComplete={() => navigate('/broadcast/live')}
                    redirectUrl="/broadcast/live"
                />
            }
            {/* {renderVideoContainer()}
            {isTablet ? renderMobileTabletLayout() : renderDesktopLayout()} */}
        </BroadcastLayout>
    );
};

export default BroadcastSetup;
import React from 'react';
import TranscribeType from '../../types/transcribe';

const TranscribeItem: React.FC<TranscribeType> = ({
    image,
    category,
    date,
    title,
    authorName,
    description,
}) => {
    return (
        <div className="flex flex-col w-full max-w-lg gap-1">
            {/* Top section with image and details */}
            <div className="flex gap-4 w-full h-16">
                {/* Image */}
                <div className="w-24 h-16 flex-none relative bg-white">
                    <img
                        src={image}
                        alt={title}
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                </div>

                {/* Details */}
                <div className="flex flex-col gap-1 flex-1 min-w-0">
                    {/* Category and Date */}
                    <div className="text-sm leading-4 text-yellow-light font-outfit truncate">
                        {category} • {date}
                    </div>

                    {/* Title */}
                    <h3 className="text-base leading-5 text-white font-medium font-outfit truncate">
                        {title}
                    </h3>

                    {/* Author */}
                    <p className="text-sm leading-4 text-grey-40 font-outfit truncate">
                        {authorName}
                    </p>
                </div>
            </div>

            {/* Description list */}
            <div className="flex flex-col justify-center pt-2 gap-3 w-full">
                <p className="text-sm leading-5 text-grey-40 font-outfit line-clamp-2">
                    {description}
                </p>
                <p className="text-sm leading-5 text-[#9FA0AD] font-outfit line-clamp-2">
                    ... sed keyword 1 convallis nunc molestie in. Donec hendrerit viverra mi, eget venenatis arcu blandit non.
                </p>
            </div>
        </div>
    );
};

export default TranscribeItem;
import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface LeftRightButtonProps {
    leftDisable?: boolean;
    rightDisable?: boolean;
    onLeftClick?: any;
    onRightClick?: any;
}

const LeftRightButton: React.FC<LeftRightButtonProps> = ({ leftDisable = false, rightDisable = false, onLeftClick, onRightClick }) => {
    return (
        <div className="flex flex-row items-center justify-between">
            <ChevronLeft
                className={`w-6 h-6 cursor-pointer ${
                    leftDisable ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                } mr-2`}
                onClick={!leftDisable ? onLeftClick : undefined}
            />
            <ChevronRight
                className={`w-6 h-6 cursor-pointer ${
                    rightDisable ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                }`}
                onClick={!rightDisable ? onRightClick : undefined}
            />
        </div>
    );
};

export default LeftRightButton;

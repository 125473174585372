import React from 'react';
import { MoreVertical, Clock, Eye } from 'lucide-react';

const StreamComp = ({
    title = "Discover Topics for streaming",
    category = "Manufacturing",
    time = "4",
    views = "25",
    description = "Praesent tristique viverra mattis. Fusce eu sapien efficitur, vestibulum metus dignissim, vehicula nulla. Mauris vestibulum pellentesque dui vel maximus.",
    date = "25 June, 2024",
    thumbnail = ""
}) => {
    return (
        <div className="flex flex-row items-start gap-4 w-full">
            {/* Media Image */}
            <div className="relative w-[200px] h-[120px] bg-white flex-shrink-0">
                <img
                    src={thumbnail}
                    alt="Stream thumbnail"
                    className="w-full h-full object-cover"
                />
            </div>

            {/* Details Section */}
            <div className="flex flex-col gap-3 flex-grow">
                {/* Copy Section */}
                <div className="flex flex-col gap-1 w-full">
                    {/* Title Row */}
                    <div className="flex flex-row items-center justify-between w-full">
                        <h2 className="text-xl font-semibold text-white flex-grow font-outfit">
                            {title}
                        </h2>
                        <MoreVertical className="w-5 h-5 text-gray-400" />
                    </div>

                    {/* About Section */}
                    <div className="flex flex-row items-center gap-1">
                        <span className="text-sm text-blue-400">{category}</span>
                        <div className="flex items-center gap-1">
                            <Clock className="w-4 h-4 text-gray-400" />
                            <span className="text-sm text-gray-400">{time}</span>
                        </div>
                        <div className="flex items-center gap-1">
                            <Eye className="w-4 h-4 text-gray-400" />
                            <span className="text-sm text-gray-400">{views}</span>
                        </div>
                    </div>

                    {/* Description */}
                    <p className="text-sm text-gray-400 mt-2 line-clamp-2">
                        {description}
                    </p>
                </div>

                {/* Date */}
                <div className="text-sm text-gray-400">
                    {date} • {time} min
                </div>
            </div>
        </div>
    );
};

export default StreamComp;

function parseDateString(dateString?: string): string {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit',
        // hour12: false
    };

    return date.toLocaleString('en-US', options);
}

export { parseDateString };


import React from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import Checklist from "../../components/List/CheckList";
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import Step1Illustration from '../../assets/images/BecomeBroadcaster1.png';

interface BecomeBroadcaster1Props {
    onNext: () => void;
}
const BecomeBroadcaster1 = ({ onNext }: BecomeBroadcaster1Props) => {
    const features = [
        {
            title: "Engage with a Global Audience",
            description: "Share your expertise and connect with viewers worldwide."
        },
        {
            title: "Monetize Your Skills",
            description: "Earn through subscriptions, tips, and more."
        },
        {
            title: "Effortless Streaming Experience",
            description: "Enjoy our user-friendly tools, making streaming as easy as doing your daily job well."
        }
    ];

    return (
        <BecomeBroadcasterLayout
            illustration={<img src={Step1Illustration} alt="Step 1 Illustration" />}
        >
            {/* Title Section */}
            <div className="flex flex-col gap-4 w-full">
                <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                    BECOME BROADCASTER
                </span>
                <div className="flex flex-col gap-2">
                    <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                        Welcome to Filter FM Broadcast!
                    </h1>
                    <p className="text-base font-normal leading-5 text-grey-40 font-outfit">
                        We're excited to have you join us as a broadcaster. Here's what you can look forward to:
                    </p>
                </div>
            </div>

            {/* Features List */}
            <div className="flex flex-col gap-8 w-full">
                {/* Divider */}
                <div className="h-[1px] w-full bg-grey-80" />

                {/* Feature Items */}
                {features.map((feature, index) => (
                    <Checklist
                        key={index}
                        number={index + 1}
                        title={feature.title}
                        description={feature.description}
                        isCompleted={false}
                        titleColor="text-brand-100"
                        descriptionColor="text-grey-40"
                        iconBgColor="bg-brand-100"
                        iconColor="text-[#020318]"
                    />
                ))}

                {/* Divider */}
                <div className="h-[1px] w-full bg-grey-80" />
            </div>

            {/* Bottom Section */}
            <div className="flex flex-col gap-6">
                <h2 className="text-xl font-semibold leading-7 text-white">
                    Let's get started on your journey to becoming a top broadcaster!
                </h2>

                {/* Button */}
                <ButtonCompSpecific
                    type="brand"
                    rightIcon={<ShortRight className="fill-white" />}
                    onClick={onNext}
                >
                    Let's Go
                </ButtonCompSpecific>
            </div>
        </BecomeBroadcasterLayout>
    );
};

export default BecomeBroadcaster1;
import React, { useState } from "react";
import CompleteBroadcast1 from "./CompleteBroadcast1";
import CompleteBroadcast2 from "./CompleteBroadcast2";
import CompleteBroadcast3 from "./CompleteBroadcast3";


const BecomeBroadcaster = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNext = () => {
        setCurrentStep(prev => prev + 1);
    };

    const handleBack = () => {
        setCurrentStep(prev => prev - 1);
    };

    const renderStep = () => {
        const props = {
            onNext: handleNext,
            onBack: handleBack,
            currentStep
        };

        switch (currentStep) {
            case 1:
                return <CompleteBroadcast1 {...props} />;
            case 2:
                return <CompleteBroadcast2 {...props} />;
            case 3:
                return <CompleteBroadcast3 {...props} />;
            default:
                return <CompleteBroadcast1 {...props} />;
        }
    };

    return (
        <div className="min-h-screen bg-[#000]">
            {renderStep()}
        </div>
    );
};

export default BecomeBroadcaster;

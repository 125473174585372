import React from 'react';
import ButtonCompSpecific from '../../../components/ButtonCompSpecific';
import { ReactComponent as ShortRight } from '../../../assets/icons/short_right.svg';
import TagComponent from '../../../components/TagComp';
import InputComp from '../../../components/InputComp';
import StepProgressBar from '../../../components/StepProgressBar';
import { useSetupAccount } from '../../../hooks/user/useSetupAccount';

const SetupAccount1: React.FC = () => {
    const { toast, nextStep, allToolsToDisplay, handleToolOnAdd, tools, toggleTool, addTool, toolInput, setToolInput } = useSetupAccount();

    const handleNext = () => {
        if (tools.length === 0) {
            toast({
                type: 'error',
                message: 'Please select at least one tool',
                duration: 3000,
            });
            return;
        }
        nextStep();
    };

    return (
        <div className="max-w-[480px] flex flex-col items-start justify-start gap-5">
            {/* Title Section */}
            <div>
                <p className="text-base opacity-70 text-grey-40">Setup Account</p>
            <h1 className="text-2xl font-medium mb-8 text-white">
                Which tools do you use most frequently for your professional work?
            </h1>
            </div>

            {/* Tools Tags */}
            <div className="flex flex-wrap gap-4">
                {allToolsToDisplay.map((tool) => (
                    <TagComponent
                        key={tool.id}
                        label={tool.name}
                        selected={tools.includes(tool.name)}
                        borderColor={tool.borderColor}
                        onClick={() => toggleTool(tool.name)}
                    />
                ))}
            </div>

            {/* Add New Input Field */}
            <div className="w-full mt-5">
                <div className="w-full flex flex-row justify-center items-center p-2.5 bg-grey-90 rounded-lg">
                    <InputComp
                        value={toolInput}
                        placeholder="Add New Tool"
                        onChange={(e) => setToolInput(e.target.value)}
                    />
                    <span className="text-grey-40 cursor-pointer" onClick={handleToolOnAdd}>
                        +
                    </span>
                </div>
            </div>

            {/* Button Controls */}
            <div className="flex gap-4 w-full mt-6">
                <ButtonCompSpecific
                    type="brand"
                    rightIcon={<ShortRight className="fill-grey-90" />}
                    onClick={handleNext}
                >
                    Next
                </ButtonCompSpecific>
            </div>

            {/* Progress Bars */}
            <div className='w-full mt-24'>
            <StepProgressBar totalSteps={3} currentStep={1} />
            </div>
        </div>
    );
};

export default SetupAccount1;

import React from 'react';
import ButtonComp from '../../../components/ButtonComp';
import InputComp from '../../../components/InputComp';
import { ReactComponent as ShortRightIcon } from '../../../assets/icons/short_right.svg';
import IconButton from '../../../components/IconButton';
import { ReactComponent as Google } from '../../../assets/icons/google.svg';
import { ReactComponent as LinkedIn } from '../../../assets/icons/LinkedIn.svg';
import CheckboxComp from '../../../components/CheckBoxComp';
import { useRegisterStore } from '../../../store/useRegisterStore';
import useRegister from '../../../hooks/auth/useRegister'
import StepProgressBar from '../../../components/StepProgressBar';

const CreateAccount1 = () => {
    const {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        password,
        setPassword,
        passwordConfirm,
        setPasswordConfirm,
        setAgreeToTerms,
        agreeToTerms,
        loading,
    } = useRegisterStore();
    const { next } = useRegister();

    const handleSubmit = async (): Promise<void> => {
        await next();
    };

    return (
        <div className='max-w-[480px] flex flex-col items-start justify-start gap-5'>
            <div>
                <p className="text-base text-grey-40">Create Account</p>
                <h1 className="text-2xl font-medium mb-8 text-white">
                    Setup your login Details
                </h1>
            </div>

            <div className="max-w-full w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* First Name */}
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-grey-40" htmlFor="firstName">
                        First Name
                    </label>
                    <div className="w-full rounded-lg overflow-hidden">
                        <InputComp
                            id="firstName"
                            value={firstName}
                            type="text"
                            placeholder="Enter your first name"
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                </div>

                {/* Last Name */}
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-grey-40" htmlFor="lastName">
                        Last Name
                    </label>
                    <div className="w-full rounded-lg overflow-hidden">
                        <InputComp
                            id="lastName"
                            value={lastName}
                            type="text"
                            placeholder="Enter your last name"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>
            </div>


            {/* Email Input */}
            <div className="w-full flex flex-col gap-2">
                <label className='text-sm text-grey-40'>
                    Email Address
                </label>
                <div className='w-full rounded-lg overflow-hidden'>
                    <InputComp
                        value={email}
                        type='email'
                        placeholder='Enter your email address'
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            </div>

            {/* Password Input */}
            <div className="w-full flex flex-col gap-2">
                <label className='text-sm text-grey-40'>
                    Password
                </label>
                <div className='w-full rounded-lg overflow-hidden'>
                    <InputComp
                        value={password}
                        type='password'
                        placeholder='Enter your password'
                        onChange={(e) => setPassword(e.target.value)}

                    />
                </div>
            </div>

            {/* Confirm Password Input */}
            <div className="w-full flex flex-col gap-2">
                <label className='text-sm text-grey-40'>
                    Re-Type
                </label>
                <div className='w-full rounded-lg overflow-hidden'>
                    <InputComp
                        value={passwordConfirm}
                        type='password'
                        placeholder='Confirm your password'
                        onChange={(e) => setPasswordConfirm(e.target.value)}

                    />
                </div>
            </div>
            <CheckboxComp
                checked={agreeToTerms}
                onChange={() => setAgreeToTerms(!agreeToTerms)}
                label="I agree to the Terms and Conditions"
                labelColor="text-grey-30"
            />

            {/* Submit Button */}
            <ButtonComp
                onClick={handleSubmit}
                loading={loading}
                backgroundColor='bg-brand-100'
                textColor='text-grey-90'
                rightIcon={<ShortRightIcon className="fill-grey-90" />}
                width='!w-full'
            >
                Next
            </ButtonComp>

            {/* Social Buttons */}
            <div className='flex flex-row items-center justify-center gap-4 mt-8 w-full'>
                <IconButton
                    icon={<Google className="w-full h-full text-grey-30" />}
                    onClick={() => console.log('Google button clicked')}
                    className="flex-1 h-10 border border-grey-70"

                />
                <IconButton
                    icon={<LinkedIn className="w-full h-full text-grey-30" />}
                    onClick={() => console.log('LinkedIn button clicked')}
                    className="flex-1 h-10 border border-grey-70"
                />
            </div>
            <div className='w-full mt-24'>
                <StepProgressBar totalSteps={2} currentStep={1} />
            </div>

        </div>
    );
};

export default CreateAccount1;

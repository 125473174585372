import React, { useState, useEffect} from 'react';
import BroadcastLayout from '../../components/Layouts/BroadcastLayout';
import HLSPlayer from '../../components/Player/HLSPlayer';
import { api } from '../../services/api';

const Watch: React.FC = () => {
    const [url, setUrl] = useState<string | null>(null);

    const getStreamUrl = async () => {
        const response = await api.broadcast.watch();
        console.log ("Response", response)
        if (response && response.signedUrl) {
            setUrl(response.signedUrl);
        }
    }

    useEffect(() => {
        getStreamUrl();
    }, []);

    return (
        <BroadcastLayout>
            {
                url ? <HLSPlayer src={url} /> : <p>Loading...</p>
            }
        </BroadcastLayout>
    );
};

export default Watch;

import React, { useState } from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import TagComponent from "../../components/TagComp";
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import { ReactComponent as ShortLeft } from '../../assets/icons/short_left.svg';
import Step4Illustration from '../../assets/images/BecomeBroadcaster4.png';
import { useBecomeBroadcasterStore } from "../../store/becomeBroadcasterStore";
import { useToast } from '../../components/ToastComp';

interface Vertical {
    id: string;
    name: string;
    borderColor: string;
    selected?: boolean;
}
interface BecomeBroadcaster4Props {
    onNext: () => void;
    onBack: () => void;
}
const BecomeBroadcaster4 = ({ onNext, onBack }: BecomeBroadcaster4Props) => {
    const setDepartments = useBecomeBroadcasterStore(state => state.setDepartments);
    const toast = useToast();
    const [verticals, setVerticals] = useState<Vertical[]>([
        { id: '1', name: 'Operations', borderColor: 'border-[#DF974D]', selected: true },
        { id: '2', name: 'Marketing', borderColor: 'border-blue-100', selected: true },
        { id: '3', name: 'Sales', borderColor: 'border-[#A854FD]', selected: true },
        { id: '4', name: 'Customer Support', borderColor: 'border-grey-60', selected: false },
        { id: '5', name: 'Supply Chain', borderColor: 'border-grey-60', selected: false },
        { id: '6', name: 'IT', borderColor: 'border-green-80', selected: true },
        { id: '7', name: 'Frontend Development', borderColor: 'border-grey-20', selected: true },
        { id: '8', name: 'Backend Development', borderColor: 'border-grey-60', selected: false },
        { id: '9', name: 'DevOps', borderColor: 'border-grey-60', selected: false },
        { id: '10', name: 'HR', borderColor: 'border-red-80', selected: true },
        { id: '11', name: 'Fintech', borderColor: 'border-blue-70', selected: true },
        { id: '12', name: 'SaaS', borderColor: 'border-grey-60', selected: false },
        { id: '13', name: 'Education', borderColor: 'border-grey-60', selected: false },
        { id: '14', name: 'Manufacturing', borderColor: 'border-red-80', selected: true },
        { id: '15', name: 'Healthcare', borderColor: 'border-blue-70', selected: true },
        { id: '16', name: 'Other', borderColor: 'border-grey-60', selected: false }
    ]);

    const toggleVertical = (verticalId: string) => {
        setVerticals(verticals.map(vertical =>
            vertical.id === verticalId
                ? { ...vertical, selected: !vertical.selected }
                : vertical
        ));
    };

    const handleNext = () => {
        const selectedVerticals = verticals.filter(vertical => vertical.selected).map(vertical => vertical.name);
        if (selectedVerticals.length === 0) {
            toast({
                type: 'error',
                message: 'Please select at least one vertical'
            });
            return;
        }
        setDepartments(selectedVerticals);
        onNext();
    }

    return (
        <BecomeBroadcasterLayout
            illustration={<img src={Step4Illustration} alt="Step 4 Illustration" />}
        >
            {/* Title Section */}
            <div className="flex flex-col gap-4 w-full">
                <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                    BECOME BROADCASTER
                </span>
                <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                    Which of these verticals do you specialize in?
                </h1>
            </div>

            {/* Verticals Tags */}
            <div className="flex flex-wrap gap-4">
                {verticals.map((vertical) => (
                    <TagComponent
                        key={vertical.id}
                        label={vertical.name}
                        selected={vertical.selected}
                        borderColor={vertical.borderColor}
                        onClick={() => toggleVertical(vertical.id)}
                        size="medium"
                        textColor={vertical.selected ? "text-white" : "text-grey-60"}
                    />
                ))}
            </div>

            {/* Button Controls */}
            <div className="flex gap-4 w-full">
                <ButtonCompSpecific
                    type="grey"
                    leftIcon={<ShortLeft className="w-5 h-5" />}
                    onClick={onBack}
                >
                    Back
                </ButtonCompSpecific>
                <ButtonCompSpecific
                    type="brand"
                    rightIcon={<ShortRight className="w-5 h-5" />}
                    onClick={handleNext}
                >
                    Next
                </ButtonCompSpecific>
            </div>
            <div className="flex mt-20 gap-5">
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
            </div>
        </BecomeBroadcasterLayout>
    );
};

export default BecomeBroadcaster4;
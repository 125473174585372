import React, { ReactNode } from 'react';
import FilterFMLogo from '../../assets/Logo.png';
import useMediaQuery from '../../hooks/useMediaQuery';

interface AuthLayoutProps {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  bottomLink?: ReactNode;
  rightImage?: boolean;
  topRightContent?: ReactNode;
  illustration?: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  title,
  subtitle,
  bottomLink,
  topRightContent,
  rightImage,
  illustration,
}) => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  if (isMobile) {
    illustration = null;
  }
  // Main content
  const mainContent = (
    <>
      <p className="text-base text-grey-40 mb-4">{title}</p>
      <h1 className="text-2xl font-medium text-white mb-8">{subtitle}</h1>
      {children}
    </>
  );

  return (
    <section className="bg-grey-100 min-h-screen min-w-screen w-full flex flex-col">
      {/* Logo Section */}
      <div className="h-[88px] w-full px-4 lg:px-8">
        <div className="h-full flex items-center justify-between">
          <div className="flex items-center">
            <img src={FilterFMLogo} className="w-full max-w-[128px]" alt="FilterFM Logo" />
          </div>
          {/* Top-right content */}
          {topRightContent && (
            <div className="flex items-center">
              {topRightContent}
            </div>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col justify-center items-center px-4 py-8 relative">
        {/* Mobile view */}
        <div className="lg:hidden w-full flex">
          {/* Left spacing - 20% */}
          <div className="w-[2.5%] sm:w-[15%]"></div>

          {/* Center content - 60% */}
          <div className="w-[95%] sm-w-[70%]">
            {illustration && <div className="mb-8">{illustration}</div>}
            <div className="mb-8">{mainContent}</div>
          </div>

          {/* Right spacing - 20% */}
          <div className="w-[2.5%] sm:w-[15%]"></div>
        </div>


        {/* Desktop view */}
        <div className="hidden lg:flex w-full py-8">
          {/* Left dead space */}
          <div className="hidden lg:block w-[20%]"></div>

          {/* Main content area */}
          <div className="w-[60%] flex items-center justify-between">
            {/* Conditional rendering based on rightImage prop */}
            {rightImage ? (
              <>
                {/* Left column - Main Content */}
                <div className="w-1/2 flex flex-col items-start lg:pr-8">
                  {mainContent}
                </div>
                {/* Right column - Illustration */}
                <div className="w-1/2 flex flex-col items-center lg:pl-8">
                  {illustration}
                </div>
              </>
            ) : (
              <>
                {/* Left column - Illustration */}
                <div className="w-1/2 flex flex-col items-center lg:pr-8">
                  {illustration}
                </div>
                {/* Right column - Main Content */}
                <div className="w-1/2 flex flex-col items-start lg:pl-8">
                  {mainContent}
                </div>
              </>
            )}
          </div>

          {/* Right dead space */}
          <div className="hidden lg:block w-[20%]"></div>
        </div>
      </div>

      {/* Bottom Link */}
      {bottomLink && (
        <div className="w-full flex justify-center py-8">
          <div className="text-grey-40 lg:fixed lg:bottom-12">{bottomLink}</div>
        </div>
      )}
    </section>
  );
};

export default AuthLayout;

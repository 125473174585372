import React from 'react';
import SectionHeader from '../Section/SectionHeader';
import LeftRightButton from '../Section/LeftRightButton';
import MainSearch from '../Search/MainSearch';
import SelectComp from '../SelectComp';
import DividerComp from '../DividerComp';

interface RelatedVideo {
    id: string;
    title: string;
    category: string;
    thumbnailUrl: string;
    duration: string;
    views: number;
    isLive?: boolean;
}

interface RelatedVideosProps {
    videos: RelatedVideo[];
    onLeftClick?: () => void;
    onRightClick?: () => void;
    title?: string;
    isMobile?: boolean;
}

const RelatedVideos: React.FC<RelatedVideosProps> = ({
    videos,
    onLeftClick,
    onRightClick,
    title = 'Related Videos',
    isMobile = false,
}) => {
    return (
        <div className="flex flex-col w-full h-[407px] p-5">
            {/* Header Section */}

            {!isMobile ? (
                <>
                    <SectionHeader title={title}>
                <LeftRightButton
                    onLeftClick={onLeftClick}
                    onRightClick={onRightClick}
                />
                    </SectionHeader>
            {/* Divider */}
            <DividerComp />
                </>
            ) : null}


            {/* Filter Dropdown */}
            <div className="w-full mt-4">
                <SelectComp
                    value="Latest First"
                    options={[
                        { value: "latest", label: "Latest First" },
                        { value: "oldest", label: "Oldest First" },
                        { value: "mostViewed", label: "Most Viewed" },
                        { value: "leastViewed", label: "Least Viewed" }
                    ]}
                    onChange={() => { }}
                />
            </div>

            {/* Videos List */}
            <div className="flex flex-col gap-5 overflow-y-auto mt-4">
                {videos.map((video) => (
                    <div key={video.id} className="flex gap-3">
                        {/* Thumbnail */}
                        <div className="relative w-[120px] h-[80px]">
                            <img
                                src={video.thumbnailUrl}
                                alt={video.title}
                                className="w-full h-full object-cover rounded-lg bg-[#DBDBDB]"
                            />
                            {video.isLive && (
                                <div className="absolute top-2 left-2 bg-red-100 rounded px-1 py-0.5 flex items-center gap-1">
                                    <div className="w-3 h-3 rounded-full bg-white" />
                                    <span className="text-white text-[8px] font-bold">LIVE</span>
                                </div>
                            )}
                        </div>

                        {/* Details */}
                        <div className="flex flex-col gap-2 flex-1">
                            <h3 className="text-xs font-semibold text-white line-clamp-2">
                                {video.title}
                            </h3>
                            <span className="text-xs text-[#C7F572]">
                                {video.category}
                            </span>
                            <div className="flex items-center gap-1 text-xs text-[#9FA0AD]">
                                <span>{video.duration}</span>
                                <span>•</span>
                                <span>{video.views} views</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RelatedVideos;
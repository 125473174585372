import React, { useState } from 'react';
import ExploreLayout from '../../components/Layouts/ExploreLayout';
import Calendar from '../../components/Discover/Calendar';
import SectionHeader from '../../components/Section/SectionHeader';
import DiscoverComponent from '../../components/List/DiscoverComp';
import SidebarProfile from '../../components/BlockSidebar/SidebarProfile';
import StreakStatList from './StreakStatList';
import LeftMenu from './LeftMenu';
import StreakRightBar from './StreakRightbar';
import SelectComp from "../../components/SelectComp";
import MainSearch from "../../components/Search/MainSearch";
import { liveStreams } from '../../constants/stream';
import LiveStreamCard from '../../components/Feeds/LiveStreamCard';
import LeftRightButton from '../../components/Section/LeftRightButton';
import DividerComp from '../../components/DividerComp';
import TabComp from '../../components/Tabs/TabComp';
import useMediaQuery from '../../hooks/useMediaQuery';
import InputComp from '../../components/InputComp';
import StreamComp from '../../components/List/StreamComp';

const MyChannel = () => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    let isTablet = useMediaQuery('(max-width: 1024px)');

    const [activeMenuItem, setActiveMenuItem] = React.useState(0);
    const [mobileTab, setMobileTab] = React.useState('streaks'); 

    const streakStats = [
        { icon: 'broadcast', label: 'Total Streams', value: '4,378' },
        { icon: 'fire', label: 'Total Streak', value: '342' },
        { icon: 'clock', label: 'Time Streaming', value: '56h 32m 44s' },
    ];

    const mobileTabs = [
        { label: 'Streaks', value: 'streaks' },
        { label: 'Discover', value: 'discover' },
        { label: 'My Streams', value: 'my-streams' },
    ];

    const handleStartBroadcast = () => {
        console.log('Starting broadcast...');
    };

    const renderStatsCalendarSection = () => (
        <div className="flex flex-col sm:flex-row pb-4 gap-4 rounded-lg h-full items-stretch">
            <StreakStatList
                stats={streakStats}
                onStartBroadcast={handleStartBroadcast}
            />

            {isMobile ?
                (<DividerComp className="self-stretch" orientation='horizontal' />
                ) : (<DividerComp className="self-stretch" orientation='vertical' />)
            }

                <Calendar />
            {isMobile ?
                (<DividerComp className="self-stretch" orientation='horizontal' />
                ) : (<DividerComp className="self-stretch" orientation='vertical' />)
            }
            <StreakRightBar />
        </div>
    );



    const renderDiscoverContent = () => (
        <div className="grid grid-cols-1 gap-4 mt-4">
            {Array(4).fill(null).map((_, index) => (
                <DiscoverComponent
                    key={index}
                    title="Strong Reasons For Hiring A Financial Advisor"
                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                    category={index % 2 === 0 ? "AWS" : "Manufacturing"}
                    date="25 June, 2024"
                    readTime="4 min"
                    categoryColor={index % 2 === 0 ? undefined : "text-green-100"}
                />
            ))}
        </div>
    );

    const renderStreamsContent = () => (
        <div className="grid grid-cols-1 gap-4 mt-4">
            {liveStreams.slice(1).map((stream, index) => (
                <StreamComp
                key={index}
                    title={stream.title}
                category={stream.category}
                    time={stream.time}
                    thumbnail={stream.thumbnail}
            />
        ))}
        </div>
    );

    const renderMobileContent = () => {
        switch (mobileTab) {
            case 'streaks':
                return <div className="flex flex-col gap-4">
                    {renderStatsCalendarSection()}
                </div>;

            case 'discover':
                return (
                    <>
                        <div className="flex flex-row lg:flex-col gap-4">
                            <InputComp
                                placeholder="Search"
                                value=""
                                onChange={() => { }}
                            />
                            <SelectComp
                                value="Sort By"
                                options={[
                                    { value: "recent", label: "Recent" },
                                    { value: "oldest", label: "Oldest" },
                                    { value: "mostViewed", label: "Most Viewed" },
                                    { value: "leastViewed", label: "Least Viewed" }
                                ]}
                                onChange={() => { }}
                                width='w-full lg:w-[160px]'
                            />
                        </div>
                        {renderDiscoverContent()}
                    </>
                );
            case 'my-streams':
                return (
                    <>
                        <div className="flex flex-row lg:flex-col gap-4 mt-4">
                            <InputComp
                                placeholder="Search"
                                value=""
                                onChange={() => { }}
                            />
                            <SelectComp
                                value="Sort By"
                                options={[
                                    { value: "recent", label: "Recent" },
                                    { value: "oldest", label: "Oldest" },
                                    { value: "mostViewed", label: "Most Viewed" },
                                    { value: "leastViewed", label: "Least Viewed" }
                                ]}
                                onChange={() => { }}
                                width='w-full lg:w-[160px]'

                            />
                        </div>
                        {renderStreamsContent()}
                    </>
                );
            default:
                return renderStatsCalendarSection();
        }
    };

    return (
        <ExploreLayout>
            <div className="max-w-[1180px] w-full mx-auto">
                {(isMobile || isTablet) ? (
                    // Mobile Layout
                    <div className="flex flex-col px-4">
                        <SidebarProfile
                            username='john_doe'
                            name='John Doe'
                            memberSince='25 June, 2024'
                            avatarUrl='/images/user1.png'
                            bio='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                            followers={100}
                            viewers={100}
                            streams={100}
                            revenue={100}
                            tags={['AWS', 'Manufacturing']}
                            isVerified={true}
                        />
                        <div className="mt-4 mb-6">
                            <TabComp
                                tabs={mobileTabs}
                                onTabChange={(tab) => setMobileTab(tab)}
                            />
                        </div>
                        {renderMobileContent()}
                    </div>
                ) : (
                // Desktop Layout (Preserved exactly as before)
                        // Desktop Layout
                        <div className="flex flex-col">
                            {/* Top section */}
                        <div className="flex gap-5">
                                <aside className="w-[300px]">
                                <SidebarProfile
                                    username='john_doe'
                                    name='John Doe'
                                    memberSince='25 June, 2024'
                                    avatarUrl='/images/user1.png'
                                    bio='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                                    followers={100}
                                    viewers={100}
                                    streams={100}
                                    revenue={100}
                                    tags={['AWS', 'Manufacturing']}
                                    isVerified={true}
                                />
                                </aside>

                                <main className="flex-grow mt-8">
                                    {renderStatsCalendarSection()}
                                </main>
                            </div>

                            {/* Full-width divider */}
                            <DividerComp className="mt-6 mb-6" />

                            {/* Bottom section */}
                            <div className="flex gap-5">
                                {/* Left menu in bottom section */}
                                <aside className="w-[300px]">
                                    <LeftMenu
                                        menuItems={[
                                            { icon: 'user', label: 'Discover' },
                                            { icon: 'broadcast', label: 'My Streams' },
                                        ]}
                                        activeIndex={activeMenuItem}
                                        onMenuItemClick={(index) => setActiveMenuItem(index)}
                                    />
                            </aside>

                                {/* Main content in bottom section */}
                                <div className="flex-grow">
                                <div>
                                    <SectionHeader title={activeMenuItem === 1 ? 'My Stream' : 'Discover'}>
                                        <LeftRightButton />
                                    </SectionHeader>
                                    </div>
                                    <DividerComp className="mt-6 mb-6" />

                                    <div className="flex items-center justify-between w-full h-[40px] mt-4 mb-4 gap-4">
                                        <SelectComp
                                            value="Sort By"
                                            options={[
                                                { value: "recent", label: "Recent" },
                                                { value: "oldest", label: "Oldest" },
                                                { value: "mostViewed", label: "Most Viewed" },
                                                { value: "leastViewed", label: "Least Viewed" }
                                            ]}
                                            onChange={() => { }}
                                            width='w-[160px]'
                                        />
                                        <InputComp
                                            placeholder="Search"
                                            value=""
                                            onChange={() => { }}
                                            width='w-[400px]'
                                        />
                                </div>
                                    {activeMenuItem === 1 ? renderStreamsContent() : renderDiscoverContent()}
                                </div>
                            </div>
                        </div>
                )}
            </div>
        </ExploreLayout>
    );
};

export default MyChannel;
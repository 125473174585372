import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ButtonComp from '../../components/ButtonComp';
import { ReactComponent as ShortRightIcon } from '../../assets/icons/short_right.svg';
import SuccessIllustration from '../../assets/images/CreateAccount7.png';
import { ReactComponent as Logo } from '../../assets/logos/filterfm_favicon_brand.svg';

// Used for password reset success and account creation success

const SuccessScreen = () => {
    const { source } = useParams();

    let title = "Action Successful";
    let description = "Your action was completed successfully.";

    if (source === "forgot-password") {
        title = "Password Reset Successful";
        description = "You have successfully reset your password.";
    } else if (source === "register") {
        title = "Congratulations!";
        description = "Your account is now setup \n you can start learning on demand.";
    }
    return (
        <div className="flex flex-col min-h-screen bg-grey-100">
            {/* Top Navigation */}
            <div className="w-[88px] h-[88px] flex items-center mx-4">
                <div className="flex items-center gap-2">
                    <Logo width="40%" height="40%" />
                </div>
            </div>

            {/* Main Content Container */}
            <div className="flex-1 flex flex-col items-center">
                {/* Illustration */}
                <div className="w-[400px] h-[400px] mt-[189px]">
                    <img
                        src={SuccessIllustration}
                        alt="Success Illustration"
                        className="w-full h-full object-contain"
                    />
                </div>

                {/* Text and Button Content */}
                <div className="w-[560px] flex flex-col items-center gap-[60px] mt-[60px]">
                    <div className="flex flex-col items-center gap-4">
                        <h1 className="text-2xl font-semibold text-white">
                            {title}
                        </h1>
                        <h2 className="text-xl font-semibold text-grey-40 text-center whitespace-pre-line">
                            {description}
                        </h2>
                    </div>

                    <Link to={
                        source === 'register' ? '/explore' : '/login'
                    } className='w-[200px]'>
                        <ButtonComp
                            backgroundColor="bg-brand-100"
                            textColor="text-grey-90"
                            rightIcon={<ShortRightIcon className="fill-grey-90" />}
                        >
                            {
                                source !== 'register' ? 'Login' : 'Start Learning'
                            }
                        </ButtonComp>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SuccessScreen;
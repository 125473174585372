import LiveStream from '../types/stream_type';

const liveStreams: LiveStream[] = [
    {
        title: "Government Grift As A Lucrative Side Hustle To Build More Wealth",
        channelName: "Morgan Freeman",
        viewers: 1567,
        category: "Finance",
        time: "1 h",
        watching: 1567,
        backgroundGradient: "bg-gradient-to-br from-purple-600 to-blue-500",
        thumbnail: '/images/thumbnail.png',
        isLive: true,
    },
    {
        title: "AWS Tutorial: A Step-by-Step Tutorial for Beginners",
        channelName: "Tech Guru",
        viewers: 982,  
        category: "Technology",
        time: "1 h",  
        watching: 982,  
        backgroundGradient: "bg-gradient-to-br from-red-500 to-yellow-500",
        titleSize: "small",
        thumbnail: '/images/thumbnail.png',
        isLive: false,
    },
    {
        title: "Financial Planning 101: Secure Your Future",
        channelName: "Money Matters",
        viewers: 756,  
        category: "Finance",
        time: "45 mins",  
        watching: 756,  
        backgroundGradient: "bg-gradient-to-br from-green-400 to-blue-500",
        titleSize: "small",
        thumbnail: '/images/thumbnail.png',
        isLive: true,
    },
    {
        title: "Manufacturing Innovations: Industry 4.0",
        channelName: "Future Factory",
        viewers: 1203,  
        category: "Manufacturing",
        time: "30 mins",  
        watching: 1203,  
        backgroundGradient: "bg-gradient-to-br from-pink-500 to-orange-400",
        titleSize: "small",
        thumbnail: '/images/thumbnail.png',
        isLive: false,
    },
    {
        title: "The Future of AI: A Conversation with Elon Musk",
        channelName: "Tech Titans",
        viewers: 1024,  
        category: "Technology",
        time: "1 h",  
        watching: 1024,  
        backgroundGradient: "bg-gradient-to-br from-indigo-600 to-purple-500",
        titleSize: "small",
        thumbnail: '/images/thumbnail.png',
        isLive: false,
    }
];

export {
    liveStreams
};


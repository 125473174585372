import React, { useState, useEffect, ChangeEvent } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaBell } from "react-icons/fa"; // Importing the bell icon
import "./ComingSoon.css";
// import Footer from "../../components/Footer/Footer"; // Import the Footer component
import FilterFMLogo from '../../assets/logos/filterfm_favicon_brand.svg';
import Button from '../../components/Button/Button';

const images: string[] = [
    "https://source.unsplash.com/random/1920x1080?abstract",
    "https://source.unsplash.com/random/1920x1080?nature",
    "https://source.unsplash.com/random/1920x1080?technology"
];

const ComingSoon = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isNotifyClicked, setIsNotifyClicked] = useState(false);
    const [email, setEmail] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (isSubmitted) {
            const timer = setTimeout(() => {
                setIsSubmitted(false);
                setIsNotifyClicked(false);
                setEmail("");
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [isSubmitted]);

    const handleButtonClick = (): void => {
        if (!isNotifyClicked) {
            setIsNotifyClicked(true);
        } else if (isNotifyClicked && email) {
            // Handle form submission (e.g., send to API)
            // Removed the alert to prevent pop-up
            setIsSubmitted(true);
            // Optionally, you can reset the form fields here
            // setIsNotifyClicked(false);
            // setEmail("");
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setEmail(e.target.value);
    };

    return (
        <div className="ComingSoonPage">

            <motion.div
                className="BackgroundAnimation"
                style={{ backgroundImage: `url(${images[currentImage]})` }}
                initial={{ scale: 1.1 }}
                animate={{ scale: 1 }}
                transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
            ></motion.div>

            {/* Add the logo */}
            <motion.img
                src={FilterFMLogo}
                alt="Filter FM Logo"
                className="Logo"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
            />

            <motion.h1
                className="Title"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
            >
                A New <span style={{ color: '#D6C887' }}>Streaming Experience</span> is Coming Soon
            </motion.h1>
            <AnimatePresence>
                {!isSubmitted ? (
                    <div className="NotifyContainer">
                        {isNotifyClicked && (
                            <input
                                type="email"
                                className="EmailInput"
                                placeholder="Enter your email"
                                value={email}
                                onChange={handleInputChange}
                                required
                            />
                        )}
                        <Button
                            onClick={handleButtonClick}
                            disabled={isNotifyClicked && !email}
                            icon={<FaBell />}
                            variant="withIcon"
                            ariaLabel={isNotifyClicked ? "Submit your email" : "Notify me"}
                        >
                            {isNotifyClicked ? "Submit" : "Notify Me"}
                        </Button>
                    </div>
                ) : (
                    <motion.p
                        className="ThankYouMessage"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 3 }}
                        exit={{ opacity: 2 }}
                        transition={{ duration: 3.5 }}
                    >
                        We appreciate your interest in Filter FM. We'll get back to you soon.
                    </motion.p>
                )}
            </AnimatePresence>
            {/* <Footer /> Add the Footer component here */}
        </div>
    );
};

export default ComingSoon;

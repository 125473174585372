import React from 'react';

interface DividerCompProps {
    orientation?: 'horizontal' | 'vertical';
    className?: string;
}

const DividerComp: React.FC<DividerCompProps> = ({ orientation = 'horizontal', className = '' }) => {
    return (
        <div
            className={`
               bg-grey-90
                ${orientation === 'horizontal' ? 'w-full h-[1px]' : 'w-[1px] flex-grow'}
                ${className}
            `}
        />
    );
};

export default DividerComp;

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ExploreLayout from '../../components/Layouts/ExploreLayout'
import LiveStreamCard from '../../components/Feeds/LiveStreamCard'
import SectionHeader from '../../components/Section/SectionHeader'
import { liveStreams } from '../../constants/stream'
import ChannelsComponent from '../../components/Feeds/ChannelsComp'
import avatar from '../../assets/images/avatar.png'
import DividerComp from '../../components/DividerComp'
import useMediaQuery from '../../hooks/useMediaQuery'
import TagComponent from '../../components/TagComp'
import { Tag } from '../../components/LabelTagComp'
import { useNavigate } from 'react-router-dom'
import useExplore from '../../hooks/useExplore'
import SpinnerComp from '../../components/SpinnerComp'
import EmptyScreenComp from '../../components/EmptyScreenComp'

interface NewsItem {
  title: string
  date: string
}

const Dashboard = () => {
  const navigate = useNavigate()
  const isTablet = useMediaQuery('(max-width: 1024px)')
  const isMobile = useMediaQuery('(max-width: 640px)')
  const latestNews: NewsItem[] = [
    { title: 'New streaming features announced', date: '2023-05-15' },
    { title: 'Upcoming charity event stream', date: '2023-05-18' },
    { title: 'Platform reaches 1 million users', date: '2023-05-20' }
  ]
  const { channels, handleSelect, broadcasts, liveBroadcasts, loading } =
    useExplore()

  const tools = ['AWS', 'Salesforce', 'Finance', 'Manufacturing']

  return (
    <ExploreLayout showCenterDotIcon={true}>
      {!loading ? (
        <main className='w-full md:w-[700px] me:w-[800px] ml:w-[900px] lg:w-[1000px] xl:w-[1180px]  mx-auto'>
          {/* Hero Section */}
          <div className='flex flex-col lg:flex-row gap-4 mx-auto w-full max-w-[1180px]'>
            {/* <div className="bg-purple-30 rounded-lg">
                <HeroExploreComponent
                  category="Finance"
                  title="Government Grift As A Lucrative Side Hustle To Build More Wealth"
                  description="Aenean magna mi, dictum sit amet lacus in, feugiat tristique lorem. Nunc finibus libero sit amet fringilla ultrices. Ut nulla nisi, hendrerit at magna semper, viverra molestie quam. Morbi eget consequat ligula. "
                  time="1h 30m"
                  viewers={1200}
                  thumbnailUrl={thumbnail2}
                  userAvatarUrl={User1}
                  userName="John Doe"
              userFollowers="1.2k"
              userVideos="30"
              userLikes="1.2k"
                  isLive={true}
              isMobile={isMobile}
                />
              </div> */}

            {/* <div className="w-full lg:max-w-[280px] bg-grey-90 text-white rounded-lg">
                <BroadcastUpcoming
                  nextBroadcastTime="12:00 PM"
                  title="Strong Reasons For Hiring A Financial Advisor Or Investment Manager"
                  plays="23k"
                  startsIn="Starts in 20 Min"
                  userName="Natalia Oreiro"
                  userAvatar={User1}
                  onPrevSlide={() => console.log('Previous Slide')}
                  onNextSlide={() => console.log('Next Slide')}
              isMobile={isTablet}
                />
              </div> */}
          </div>

          {/* Content Sections */}
          <div className='w-full max-w-[1180px]'>
            {/* Top Categories */}
            {/* <section className="mt-6 mb-6 w-full">
            <SectionHeader title="Top Categories" viewAllLink="/categories" />
                <div className="w-full">
                  <ChannelsComponent
                    channels={channels}
                    onSelect={handleSelect}
                    onClose={handleClose}
                  />
                </div>
              </section>

              <DividerComp /> */}

            {/* Live Streams */}
            <section className=' mb-6 w-full'>
              <SectionHeader
                title='Live Streams'
                viewAllLink='/search/1?q=live'
              />
              <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 w-full'>
                {broadcasts?.length > 0 &&
                  broadcasts?.map((broadcast, index) => (
                    <LiveStreamCard
                      key={index}
                      title={broadcast.title}
                      channelName={broadcast.channel_name}
                      viewers={broadcast.viewers || 0}
                      isLive={broadcast.is_live || false}
                      thumbnail={broadcast.thumbnail || '/images/thumbnail.png'}
                      category={broadcast.category}
                      time={broadcast.duration}
                      watching={broadcast.views || 0}
                    />
                  ))}
                {broadcasts.length === 0 ? (
                  <p className='opacity-40'>No live stream found.</p>
                ) : null}
              </div>
            </section>
            <DividerComp />

            <section className='mt-6 mb-6 w-full mx-auto'>
              <SectionHeader
                title='Recent Streams'
                viewAllLink='/search/1?q=ondemand'
              />
              <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 w-full'>
                {liveBroadcasts?.length > 0 &&
                  liveBroadcasts?.map((broadcast, index) => (
                    <>
                      <LiveStreamCard
                        key={index}
                        title={broadcast.title}
                        channelName={broadcast.channel_name}
                        viewers={broadcast.viewers || 0}
                        isLive={broadcast.is_live || false}
                        thumbnail={
                          broadcast.thumbnail || '/images/thumbnail.png'
                        }
                        category={broadcast.category}
                        time={broadcast.duration}
                        watching={broadcast.views || 0}
                      />
                    </>
                  ))}
                {liveBroadcasts.length === 0 ? (
                  <p className='opacity-40'>No live stream found.</p>
                ) : null}
              </div>
            </section>

            <DividerComp />

            {/* Top Channels */}
            <section className='mt-6 mb-6 w-full'>
              <SectionHeader
                title='Relevant Channels'
                viewAllLink='/all-channels'
              />
              <div className='w-full'>
                <ChannelsComponent
                  channels={channels}
                  onSelect={handleSelect}
                  // onClose={handleClose}
                />
              </div>
            </section>
            <DividerComp />

            {/* Relevant Categories */}
            <section className='mt-6 mb-6 w-full'>
              <SectionHeader
                title='Relevant Categories'
                viewAllLink='/all-categories'
              />
              <div className='flex flex-wrap gap-4'>
                {/* Categories Component */}
                {tools.map((tool, index) => (
                  <>
                    <Link to={`/search/1?category=${tool}`}>
                      <TagComponent
                        key={index}
                        label={tool}
                        selected={false}
                        onClick={() => console.log('Clicked')}
                        size='medium'
                        clickable={true}
                      />
                    </Link>
                  </>
                ))}
              </div>
            </section>
          </div>
        </main>
      ) : (
        <SpinnerComp />
      )}
    </ExploreLayout>
  )
}

export default Dashboard

import React, { useState, useEffect } from 'react';
import { Search, ChevronDown } from 'lucide-react';
import SectionHeader from '../../components/Section/SectionHeader';
import LeftRightButton from '../../components/Section/LeftRightButton';
import SelectComp from "../../components/SelectComp";
import MainSearch from "../../components/Search/MainSearch";
import DividerComp from '../DividerComp';

export interface TranscriptEntry {
    id: string;
    timestamp: string;
    content: string;
    startTime: number;
}

interface TranscriptEntryProps {
    entry: TranscriptEntry;
    isActive: boolean;
    onEntryClick: (id: string) => void;
}

const TranscriptEntryComponent: React.FC<TranscriptEntryProps> = ({ entry, isActive, onEntryClick }) => {
    const textColor = isActive ? 'text-[#DEC65E]' : 'text-[#6B6B7B]';
    const contentColor = isActive ? 'text-[#9FA0AD]' : 'text-[#6B6B7B]';

    return (
        <div
            className="flex gap-2 w-full h-20 cursor-pointer transition-colors duration-200 hover:bg-[#232437]/50"
            onClick={() => onEntryClick(entry.id)}
        >
            <div className={`w-[100px] font-outfit text-sm ${textColor} flex items-center`}>
                {entry.timestamp}
            </div>
            <div className={`flex-1 font-outfit text-sm ${contentColor} flex items-center`}>
                {entry.content}
            </div>
        </div>
    );
};

interface TranscriptComponentProps {
    entries: TranscriptEntry[];
    currentTime?: number;
    onEntryClick?: (id: string) => void;
    isMobile?: boolean;
}

const TranscriptComponent: React.FC<TranscriptComponentProps> = ({
    entries,
    currentTime = 0,
    onEntryClick,
    isMobile = false,
}) => {
    const [activeEntryId, setActiveEntryId] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const activeEntry = entries.reduce((prev, current) =>
            (current.startTime <= currentTime && current.startTime > prev.startTime) ? current : prev
        );
        setActiveEntryId(activeEntry.id);
    }, [currentTime, entries]);

    const handleEntryClick = (id: string) => {
        setActiveEntryId(id);
        if (onEntryClick) {
            onEntryClick(id);
        }
    };

    const filteredEntries = entries.filter(entry =>
        entry.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.timestamp.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="flex flex-col w-full p-5">
            {!isMobile ? (
                <>
                    <SectionHeader title="Transcript">
                <LeftRightButton
                    onLeftClick={() => { }}
                    onRightClick={() => { }}
                />
                    </SectionHeader>
            <DividerComp />
                </>
            ) : null}

            <div className="flex items-center justify-between w-full h-[40px] mt-4">
                <SelectComp
                    value="Latest First"
                    options={[
                        { value: "recent", label: "Recent" },
                        { value: "oldest", label: "Oldest" },
                        { value: "mostViewed", label: "Most Viewed" },
                        { value: "leastViewed", label: "Least Viewed" }
                    ]}
                    onChange={() => { }}
                    width="w-[140px]"
                />
                <MainSearch />
            </div>
            {/* Transcript List */}
            <div className="flex flex-col gap-5">
                {filteredEntries.map((entry) => (
                    <TranscriptEntryComponent
                        key={entry.id}
                        entry={entry}
                        isActive={entry.id === activeEntryId}
                        onEntryClick={handleEntryClick}
                    />
                ))}
            </div>
        </div>
    );
};

export default TranscriptComponent;
import { useState } from 'react'
import AgoraUIKit from "agora-react-uikit";

const Video = () => {
    const [videoCall, setVideoCall] = useState(true);

    const rtcProps = {
        appId: "b840a43cdebd49c2815f47528e0ef604",
        channel: "test",
        token: "007eJxTYDDPVTz7P2AP+z7O/9YLVgoqW7b9NmsKWHjrc+m06HlswfsVGJIsTAwSTYyTU1KTUkwsk40sDE3TTMxNjSxSDVLTzAxMbFdwpDcEMjKct7vBwAiFID4LQ0lqcQkDAwAx6h8W",
    };

    const callbacks = {
        EndCall: () => setVideoCall(false),
    };


    return videoCall ? (
        <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
            <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
        </div>
    ) : (
        <h3 onClick={() => setVideoCall(true)}>Join</h3>
    );
}

export default Video;

import React from 'react';
import { Check } from 'lucide-react';

interface TagProps {
    label: string;
    selected?: boolean;
    borderColor?: string;
    onClick?: () => void;
    size?: 'small' | 'medium' | 'large';
    textColor?: string;
    disabled?: boolean;
    clickable?: boolean;
}


const borderColors = [
    'border-orange-90',
    'border-blue-100',
    'border-[#A854FD]',
    'border-green-80',
    'border-grey-20',
    'border-red-80',
    'border-blue-70',
];

const TagComponent: React.FC<TagProps> = ({
    label,
    selected = false,
    borderColor = borderColors[Math.floor(Math.random() * borderColors.length)],
    onClick,
    size = 'medium',
    disabled = false,
    textColor = 'text-white',
    clickable = false
}) => {
    const sizeClasses = {
        small: 'text-xs py-1.5 px-2',
        medium: 'text-sm py-2 px-3',
        large: 'text-base py-2.5 px-4'
    };

    const commonClasses = `
        flex items-center gap-2 
        ${sizeClasses[size]}
        rounded-lg border
        ${borderColor}
        transition-colors duration-200
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
        hover:opacity-90
        w-fit
        inline-flex
        cursor-pointer
        z-[100]
    `;

    if (clickable) {
        return (
            <button
                onClick={onClick}
                disabled={disabled}
                className={commonClasses}
            >
                <span className={`font-outfit font-normal whitespace-nowrap ${textColor}`}>
                    {label}
                </span>
            </button>
        );
    }

    return (
        <div
            className={commonClasses}
            onClick={!disabled ? onClick : undefined}
        >
            {selected && (
                <Check className={`w-5 h-5 ${textColor}`} />
            )}
            <span className={`font-outfit font-normal whitespace-nowrap ${textColor}`}>
                {label}
            </span>
        </div>
    );
};

export default TagComponent;
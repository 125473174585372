import React, { useState } from 'react';
import ExploreLayout from '../../../components/Layouts/ExploreLayout';
import PersonalInfoComp from './PersonalInfoComp';
import SubscriptionsSection from '../MySubscription/SubsciptionsSection';
import NavigationComp from './LeftMenuComp';
import ProfileViewComp from '../../../components/BlockHero/MyProfileComp';
import { SubscriptionPlan, PaymentMethods } from '../../../constants/profile';
import SectionHeader from '../../../components/Section/SectionHeader';
import LeftRightButton from '../../../components/Section/LeftRightButton';
import DividerComp from '../../../components/DividerComp';
import TabComp from '../../../components/Tabs/TabComp';
import useMediaQuery from '../../../hooks/useMediaQuery';
import WatchHistorySection from '../WatchHistory/WatchHistorySection';
import useStat from '../../../hooks/profile/useStat';
import { useUserStore } from '../../../store/userStore';

const MyProfile = () => {
    // State Hooks for Profile Information

    const user = useUserStore((state) => state.user);
    const { streamCount, viewers, channelName } = useStat();
    const [activeItem, setActiveItem] = useState<string>('personal');
    const isTablet = useMediaQuery('(max-width: 1024px)');
    const isMobile = useMediaQuery('(max-width: 640px)');

    console.log("User", user);

    const tabToHeading: Record<string, string> = {
        personal: 'Personal Info',
        subscription: 'Subscriptions',
        myStreams: 'My Streams',
    };

    const mobileTabs = [
        { label: 'Personal Info', value: 'personal' },
        { label: 'Subscriptions', value: 'subscription' },
        { label: 'My Streams', value: 'myStreams' },
    ];

    const SubscriptionPlans: SubscriptionPlan[] = [
        {
            id: 'starter',
            title: 'The Starter',
            price: 'FREE',
            period: '/forever',
            isAlreadySub: user?.subscription_object?.plan?.id === undefined
        },
        {
            id: 'price_1QHmTdDRz4r3eFYsHlmtAAXs',
            title: 'The Pro',
            price: '$40',
            period: '/month',
            isAlreadySub: user?.subscription_object?.plan?.id === 'price_1QHmTdDRz4r3eFYsHlmtAAXs'
        }
    ];

    const renderContent = () => {
        switch (activeItem) {
            case 'personal':
                return (
                    <PersonalInfoComp
                    />
                );
            case 'subscription':
                return (
                    <SubscriptionsSection
                        plans={SubscriptionPlans}
                        paymentMethods={PaymentMethods}
                    />
                );

            case 'myStreams':
                return (
                    <WatchHistorySection />
                );
            default:
                return null;
        }
    };

    return (
        <ExploreLayout>
            <div className="max-w-[1180px] w-full mx-auto">
                {(isTablet || isMobile) ? (
                    // Mobile/Tablet Layout
                    <div className="flex flex-col">
                        {/* Profile Hero Section */}
                        <div className="p-4">
                            <div className="rounded-lg bg-[#3F216C] p-6">
                                <ProfileViewComp
                                    followers={0}
                                    viewers={viewers}
                                    streams={streamCount}
                                    revenue={0}
                                    channel_name={channelName}
                                    user={user}
                                />
                            </div>
                        </div>

                        {/* Navigation Tabs */}
                        <div className="px-4">
                            <TabComp
                                tabs={mobileTabs}
                                onTabChange={(tab) => setActiveItem(tab)}
                            />
                        </div>

                        {/* Content Area */}
                        <div className="w-full px-4 pb-4">
                            <div className="flex flex-col gap-4">
                                {/* Header Section */}
                                <div className="flex items-center justify-between">
                                    <h2 className="text-white text-xl font-semibold">
                                        {tabToHeading[activeItem]}
                                    </h2>
                                </div>

                                {/* Content Section */}
                                <div className="bg-bg-dark-blue rounded-lg">
                                    {renderContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    // Desktop Layout
                    <div className="flex w-full flex-col">
                        {/* Profile Section */}
                            <ProfileViewComp
                                followers={0}
                                viewers={viewers}
                                streams={streamCount}
                                revenue={0}
                                channel_name={channelName}
                                user={user}
                        />

                        {/* Main Content */}
                        <div className="flex-grow w-full">
                            <div className="w-full mt-8">
                                <div className="flex gap-8">
                                    {/* Left Navigation */}
                                    <div className="w-[300px] flex-shrink-0">
                                        <NavigationComp
                                            onChange={(e) => setActiveItem(e)}
                                            currentlyActive={activeItem}
                                                user={user}
                                        />
                                    </div>

                                    {/* Main Content Area */}
                                        <div className="flex-grow w-[880px]">
                                        <SectionHeader title={tabToHeading[activeItem]}>
                                                {activeItem == 'myStreams' && (
                                                    <LeftRightButton />
                                                )}
                                        </SectionHeader>

                                        <DividerComp className="mb-4" />

                                        <div className="overflow-x-hidden">
                                            {renderContent()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ExploreLayout>
    );
};

export default MyProfile;
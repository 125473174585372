import React from 'react';
import { User, Eye, Radio, DollarSign } from 'lucide-react';
import {ReactComponent as CheckCircle} from '../../assets/icons/circle_check.svg';
import LabelTagComp from '../LabelTagComp';

interface SidebarProfileProps {
    username: string;
    name: string;
    memberSince: string;
    avatarUrl: string;
    bio: string;
    followers: number;
    viewers: number;
    streams: number;
    revenue: number;
    isVerified?: boolean;
    tags?: string[];
}

const SidebarProfile: React.FC<SidebarProfileProps> = ({
    username,
    name,
    memberSince,
    avatarUrl,
    bio,
    followers,
    viewers,
    streams,
    revenue,
    isVerified = false,
    tags = [],
}) => {
    return (
        <div className="max-w-[900px] lg:w-[280px] h-[318px] sm:h-[278px] lg:h-[460px] bg-purple-30 rounded-lg p-6 flex flex-col items-center relative overflow-hidden">
            {/* Background circle */}
            <div className="absolute w-[400px] h-[400px] left-1/2 -top-[136px] bg-grey-100 opacity-10 rounded-full transform -translate-x-1/2" />

            {/* Profile details */}
            <div className="z-10 flex flex-col items-center gap-4 mb-4">
                <img src={avatarUrl} alt={name} className="w-12 h-12 rounded-lg" />
                <div className="text-center">
                    <div className="flex items-center justify-center gap-1">
                        <span className="text-grey-40 text-xs">@{username}</span>
                        {isVerified && <CheckCircle className="text-green-100 w-4 h-4" />}
                    </div>
                    <h2 className="text-white text-base font-semibold">{name}</h2>
                    <p className="text-grey-40 text-xs">member since {memberSince}</p>
                </div>
            </div>

            {/* Bio */}
            <p className="!text-white font-semibold z-[100] text-xs text-center mb-4 ">{bio}</p>

            {/* Tags */}
            <div className="flex gap-2 mb-4 mt-2">
                <LabelTagComp tags={tags} />
            </div>

            {/* Stats */}
            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-1 w-full gap-2">
                <StatItem icon={<User size={16} />} label="Followers" value={followers} />
                <StatItem icon={<Eye size={16} />} label="Viewers" value={viewers} />
                <StatItem icon={<Radio size={16} />} label="Streams" value={streams} />
                <StatItem icon={<DollarSign size={16} />} label="Revenue" value={`$${revenue}`} />
            </div>
        </div>
    );
};

interface StatItemProps {
    icon: React.ReactNode;
    label: string;
    value: number | string;
}

const StatItem: React.FC<StatItemProps> = ({ icon, label, value }) => (
    <div className="flex items-center bg-black bg-opacity-20 rounded p-2">
        {icon}
        <span className="text-white text-xs ml-2">{label}</span>
        <span className="text-white text-xs font-semibold ml-auto">{value}</span>
    </div>
);

export default SidebarProfile;
import React from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import Checklist from "../../components/List/CheckList";
import Step5Illustration from '../../assets/images/BecomeBroadcaster5.png';
import { ReactComponent as PlayArrow } from "../../assets/icons/play_arrow.svg";
import { ReactComponent as Pause } from "../../assets/icons/pause.svg";
import { ReactComponent as Stop } from "../../assets/icons/stop.svg";
import useRecording from "../../hooks/broadcaster/useRecording";
import IconButton from "../../components/IconButton";
import { useBecomeBroadcasterStore } from "../../store/becomeBroadcasterStore";
import { useToast } from '../../components/ToastComp';

interface BecomeBroadcaster5Props {
    onNext: () => void;
    onBack: () => void;
}

const BecomeBroadcaster5 = ({ onNext, onBack }: BecomeBroadcaster5Props) => {
    const setVideoS3Url = useBecomeBroadcasterStore(state => state.setVideoS3Url);
        const {
            recording,
            paused,
            screenSharing,
            videoContainerRef,
            startRecording,
            stopRecording,
            toggleScreenShare,
            pauseRecording,
            resumeRecording,
        } = useRecording();

    const [isLoading, setIsLoading] = React.useState(false);

    const toast = useToast();

        const handlePauseResumeRecording = () => {
            if (paused) {
                resumeRecording();
            } else {
                pauseRecording();
            }
        };

    const handleStopRecording = async () => {
        setIsLoading(true);
        try {
            await stopRecording();
            toast({
                type: 'success',
                message: 'Video uploaded successfully!'
            });
            onNext();
        }
        catch (error) {
            console.error('Error uploading video:', error);
            toast({
                type: 'error',
                message: 'Error uploading video. Please try again.'
            });
        }
        finally {
            setIsLoading(false);
        }
    };

        const getLeftSection = () => {
            return (
                <div className="bg-gray-70 rounded-lg w-[760px] p-8 mr-8">
                    <div className="relative">
                        <video
                            ref={videoContainerRef}
                            style={{ width: '100%', height: 'auto', background: '#000' }}
                            autoPlay
                            muted
                            playsInline
                            className="rounded-lg cursor-pointer"
                            onClick={handlePauseResumeRecording}
                        />
                        {/* Overlay the illustration when not recording */}
                        {!recording && (
                            <img
                                src={Step5Illustration}
                                alt="Step 5 Illustration"
                                className="absolute inset-0 w-full h-full object-cover rounded-lg"
                            />
                        )}
                        {/* Play/Pause overlay */}
                        {paused && recording && (
                            <div className="absolute inset-0 flex items-center justify-center">
                                <IconButton
                                    icon={<PlayArrow className="w-12 h-12 text-brand-100" />}
                                    className="!border-0 !w-24 !h-24 !bg-transparent"
                                />
                            </div>
                        )}
                        {/* Controls bar */}
                        {recording && (
                            <div className="absolute bottom-0 left-0 right-0 bg-black/50 px-4 h-16 py-3 flex items-center gap-4 rounded-b-lg">
                                <div className="flex items-center">
                                    <IconButton
                                        icon={
                                            paused ? (
                                                <PlayArrow className="w-5 text-brand-100" />
                                            ) : (
                                                <Pause className="w-5 text-brand-100" />
                                            )
                                        }
                                        onClick={handlePauseResumeRecording}
                                        className="!border-0 !w-10"
                                    />
                                    <IconButton
                                        icon={<Stop className="w-5 text-brand-100" />}
                                        onClick={stopRecording}
                                        className="!border-0 !w-10"
                                    />
                                </div>
                                <div className="ml-auto">
                                    <button
                                        className="px-3 py-1.5 text-white text-sm rounded-md hover:bg-gray-600/50 transition-colors flex items-center gap-2"
                                        onClick={toggleScreenShare}
                                    >
                                        {screenSharing ? "Stop Sharing" : "Share Screen"}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        };

        return (
            <BecomeBroadcasterLayout
                illustration={getLeftSection()}
            >
                {/* Title Section */}
                <div className="flex flex-col gap-4 w-full">
                    <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                        BECOME BROADCASTER
                    </span>
                    <div className="flex flex-col gap-2">
                        <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                            Show Us Your Skills!
                        </h1>
                        <p className="text-base font-normal leading-5 text-grey-40 font-outfit">
                            To complete your onboarding, please record a short demo of yourself using the tool you plan to teach. This helps us understand your teaching style and expertise.
                        </p>
                    </div>
                </div>

                {/* Checklist Section */}
                <div className="flex flex-col gap-8 w-full">
                    {/* Divider */}
                    <div className="h-[1px] w-full bg-grey-80" />

                    {/* Steps */}
                    <div className="flex flex-col gap-8">
                        <Checklist
                            number={1}
                            title="Prepare Your Demo:"
                            description="Open the tool you will be teaching. Plan to showcase key features or a brief tutorial. Think about what you'd like to cover when teaching on our platform."
                        />
                        <Checklist
                            number={2}
                            title="Start Recording:"
                            description="Click the Record button below. Introduce yourself and the topic you'll be teaching. Navigate through the tool while explaining your process."
                        />
                        <Checklist
                            number={3}
                            title="Finish and Submit:"
                            description="Click Stop Recordingwhen you're done. Review your recording to ensure everything looks good. Click Submit to send us your demo."
                        />
                    </div>

                    <h2 className="text-xl font-semibold leading-7 text-white">
                        When you're ready, let's see you in action!
                    </h2>

                    {/* Recording Controls */}
                    <div className="flex flex-col gap-4">
                        <ButtonCompSpecific
                            type="borderBrand"
                            rightIcon={<ShortRight className="w-5 h-5" />}
                            onClick={startRecording}
                            disabled={recording}
                        >
                            <span className="text-sm font-semibold text-brand-100">
                                {recording ? "Recording..." : "Record for Testing"}
                            </span>
                        </ButtonCompSpecific>
                        <ButtonCompSpecific
                            type="brand"
                            rightIcon={<ShortRight className="w-5 h-5" />}
                            onClick={handleStopRecording}
                            disabled={!recording}
                        >
                            Submit Request
                        </ButtonCompSpecific>
                    </div>
                    <div className="flex mt-20 gap-5">
                        <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                        <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                        <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                        <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                    </div>
                </div>

            </BecomeBroadcasterLayout >
        );
    };

export default BecomeBroadcaster5;
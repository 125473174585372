import React from 'react';
import PlanComp from '../../../components/Card/HomePlanComp';
import PaymentMethodComp from '../../../components/Card/PaymentMethodComp';
import { SubscriptionPlan } from '../../../constants/profile';
import AddNewCard from '../../../components/Card/AddCard';
import { ReactComponent as Visa } from '../../../assets/icons/visa-card.svg';
import { ReactComponent as Mastercard } from '../../../assets/icons/mastercard.svg';
import DividerComp from '../../../components/DividerComp';
import { api } from '../../../services/api';
import { useUserStore } from '../../../store/userStore';

interface SubscriptionsSectionProps {
    plans: SubscriptionPlan[];
    paymentMethods: PaymentMethod[];
}
export interface PaymentMethod {
    type: string;
    cardNumber: string;
    cardIcon?: React.ReactNode;
    isSelected?: boolean;
}

const SubscriptionsSection = ({ plans, paymentMethods }: SubscriptionsSectionProps) => {
    const user = useUserStore((state: any) => state.user);
    // Handle subscription plan selection and redirect to Stripe Checkout
    const handleSubscribe = async (priceId: string) => {
        try {
            const session = await api.stripe.createCheckoutSession(priceId);
            if (session.url) {
                window.location.href = session.url;
            } else {
                console.error("Failed to obtain redirect URL for Stripe Checkout.");
            }
        } catch (error) {
            console.error('Error during subscription:', error);
        }
    };

    const handleManageBilling = async (customerId: string) => {
        try {
            const session = await api.stripe.createDashboardSession(customerId);
            if (session.url) {
                window.location.href = session.url;
            } else {
                console.error("Failed to obtain redirect URL for Stripe Billing Portal.");
            }
        } catch (error) {
            console.error('Error during billing management:', error);
        }
    }


    const PaymentMethods: PaymentMethod[] = [
        {
            type: 'Credit Card',
            cardIcon: <Visa className='text-brand-10' />,
            cardNumber: 'XXXX XXXX XXXX 4536',
            isSelected: true
        },
        {
            type: 'Debit Card',
            cardIcon: <Mastercard />,
            cardNumber: 'XXXX XXXX XXXX 4536'
        },
    ];

    return (
        <div className="flex flex-col items-end gap-6 w-full max-w-[880px] min-h-[832px] bg-transparent rounded-lg">

            {/* Plan Section */}
            <div className="flex flex-col gap-5 w-full">
                <h3 className="text-[16px] text-white font-semibold">Plan</h3>
                <div className="flex flex-row md:flex-cols gap-5">
                    {/* PlanComp called for each plan */}
                    {plans.map((plan, index) => (
                        <PlanComp
                            id={plan.id}
                            key={index}
                            title={plan.title}
                            price={plan.price}
                            period={plan.period}
                            isAlreadySub={plan.isAlreadySub}
                            bgColor={plan.isAlreadySub ? 'bg-[#C7F572]' : 'bg-black'}
                            borderColor={plan.isAlreadySub ? 'border-blue-100' : 'border-blue-100'}
                            textColor={plan.isAlreadySub ? 'text-black' : 'text-white'}
                            buttonColor={plan.isAlreadySub ? 'text-black' : 'text-brand-100'}
                            dividerColor={plan.isAlreadySub ? 'bg-black' : 'bg-white'}
                            btnBorder={plan.isAlreadySub ? 'border-black border-2' : 'border-brand-100 border-2'}
                            onClick={() => handleSubscribe(user?.stripeCustomerId)} 
                        />
                    ))}
                </div>
            </div>

            {/* Divider */}
            <DividerComp />

            {/* Payment Method Section */}
            {/* <div className="flex flex-col gap-5 w-full">
                <h3 className="text-[16px] text-white font-semibold">Payment Method</h3>
                <div className="flex gap-5"> */}
                    {/* PaymentMethodComp called for each payment method */}
            {/* {paymentMethods.map((paymentMethod, index) => (
                        <PaymentMethodComp
                            key={index}
                            type={paymentMethod.type}
                            cardNumber={paymentMethod.cardNumber}
                            isSelected={paymentMethod.isSelected}
                        />
                    ))} */}
                    {/* AddNewCard Component */}
            {/* <AddNewCard />
                </div>
            </div> */}

            {/* Divider */}
            {/* <DividerComp /> */}

            {/* Billing History Section */}
            <button className="text-[16px] text-white font-semibold cursor-pointer"
                onClick={() => handleManageBilling(user?.stripeCustomerId)}
            > Manage Billing</button>

            {/* Spacer */}
            <div className="w-full h-[214.5px]" />
        </div>
    );
};

export default SubscriptionsSection;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import ComingSoon from './pages/ComingSoon/ComingSoon';
import Home from './pages/Explore/Explore';
import './pages/ComingSoon/ComingSoon';
import Login from './pages/Auth/Login';
import Reset from './pages/Auth/Reset';
import BroadcastSetup from './pages/Broadcast/SetupBroadCast/SetupBroadCast';
import Video from './pages/Video';
import MyChannel from './pages/Channel/MyChannel';
import Result2 from './pages/Search/Result2';
import Result1 from './pages/Search/Result1'
import MyProfile from './pages/Profile/My profile/MyProfile';
import AllChannels from './pages/Channels/AllChannels';
import ViewChannel from './pages/Channels/ViewChannels';
import BecomeBroadcaster from './pages/BecomeBroadcaster/BecomeBroadcaster';
import CompleteBroadcast from './pages/CompleteBroadcast/CompleteBroadcast';
import SuccessScreen from './pages/Auth/SuccessScreen';
import Register from './pages/Auth/CreateAccount/CreateAccount';
import StreamView from './pages/User/StreamView'
import { ToastManager } from './components/ToastComp';
import SetupAccount from './pages/Auth/SetupAccount/SetupAccount';
import api from './services/api';
import { useUserStore } from './store/userStore';
import { MeType } from './types/api';
import { protectedRoutesConsts, authRoutesConsts } from './constants/protectedRoutes';
import ProtectTheseRoutes from './components/Route/ProtectTheseRoutes';
import SpinnerComp from './components/SpinnerComp';
import { isProtectedRoute } from './utils/routeUtils';
import AllCategoriesPage from './pages/Channels/AllCategories';
import BroadcastLive from './pages/Broadcast/LiveBroadcast';
import Watch from './pages/User/Watch';

const AppContent = () => {
  const setUser = useUserStore(state => state.setUser);
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      console.log("Checking user")
      try {

        const response: MeType = await api.profile.me();
        setUser(response!.user);
        if (!location.pathname.includes('account-setup') && !response!.user.isProfileComplete) {
          navigate('/account-setup');
          return;
        }
        // if page is accunt-setup but user is already complete, redirect to home
        if (location.pathname.includes('account-setup') && response!.user.isProfileComplete) {
          navigate('/explore');
          return;
        }

        // if page is auth route but user is already logged in, redirect to home
        if (authRoutesConsts.includes(location.pathname) && response!.user) {
          navigate('/explore');
          return;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isProtectedRoute(location.pathname)) {
      checkUser();
    } else {
      setLoading(false);
    }
  }, [setUser, location.pathname]);

  if (isLoading) {
    return <div className="bg-grey-100 flex items-center justify-center h-screen"><SpinnerComp /></div>;
  }

  return (
    <ToastManager>
      <div className="App">
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Reset />} />
          <Route path="/:source/success" element={<SuccessScreen />} />
          <Route element={<ProtectTheseRoutes />}>
            <Route path="/video" element={<Video />} />
            <Route path="/explore" element={<Home />} />
            <Route path="/my-channel" element={<MyChannel />} />
            <Route path="/broadcast" element={<BroadcastSetup />} />
            <Route path="/broadcast/setup" element={<BroadcastSetup />} />
            <Route path="/broadcast/live" element={<BroadcastLive />} />
            <Route path='/search/1' element={<Result1 />} />
            <Route path="/search/2" element={<Result2 />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/all-channels" element={<AllChannels />} />
            <Route path="/all-categories" element={<AllCategoriesPage />} />
            <Route path="/channel/:id/view" element={<ViewChannel />} />
            <Route path="/become-broadcaster" element={<BecomeBroadcaster />} />
            <Route path="/complete-broadcast" element={<CompleteBroadcast />} />
            <Route path="/:id/view" element={<StreamView />} />
            <Route path="/account-setup" element={<SetupAccount />} />
            <Route path="/watch" element={<Watch />} />
          </Route>
        </Routes>
      </div>
    </ToastManager>
  );
};

export default AppContent;

import React, { useState } from 'react'
import ButtonComp from '../../components/ButtonComp'
import InputComp from '../../components/InputComp'
import { Link, useNavigate } from 'react-router-dom'
import AuthLayout from '../../components/Layouts/AuthLayout'
import useAuth from '../../hooks/auth/useAuth'
import { ReactComponent as ShortRightIcon } from '../../assets/icons/short_right.svg';
import IconButton from '../../components/IconButton';
import { ReactComponent as Google } from '../../assets/icons/google.svg';
import { ReactComponent as LinkedIn } from '../../assets/icons/LinkedIn.svg';
import ButtonCompSpecific from '../../components/ButtonCompSpecific';
import Illustration from '../../assets/graphics/LoginGraphic.png';
import useMediaQuery from '../../hooks/useMediaQuery';

const Login = () => {
  const { login, loading, setLoading } = useAuth();
  const navigate = useNavigate();


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const isMobile = useMediaQuery('(max-width: 640px)')

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await login({ email, password });
      navigate('/explore');
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'An error occurred during login';

    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout
      title="LOGIN"
      subtitle="Welcome back 👋"
      topRightContent={
        <div className='flex flex-row items-center justify-center gap-4'>
          <label className='text-sm text-grey-40 mb-0'>
            {isMobile ? 'No Account?' : "Don't have an account yet?"}
          </label>
          <Link
            to='/register'
            className='text-sm text-brand-100'
          >
            <ButtonCompSpecific
              type='borderBrand'
              rightIcon={<ShortRightIcon className="fill-brand-100" />}
            >
              {isMobile ? 'Sign Up' : 'Create Account'}
            </ButtonCompSpecific>
          </Link>
        </div>
      }
      rightImage={true}
      illustration={<img src={Illustration} alt="Illustration" className="w-[400px]" />}
    >
      <div className='w-full flex flex-col items-start justify-start gap-2'>
        <label className='self-start text-sm md:text-base text-grey-40'>
          Email Address
        </label>
        <div className='w-full rounded-lg overflow-hidden'>
          <InputComp
            value={email}
            type='email'
            placeholder='Enter your email address'
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <label className='self-start text-sm md:text-base text-grey-40 mt-2'>Password</label>
        <div className='w-full rounded-lg overflow-hidden'>
          <InputComp
            value={password}
            placeholder='Enter your password'
            type='password'
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='w-full mb-12'>
          <label></label>
          <Link
            to='/forgot-password'
            className='self-start text-xs md:text-base text-grey-40'
          >
            Forgot your password?
          </Link>
        </div>
        <ButtonComp
          onClick={handleSubmit}
          loading={loading}
          backgroundColor='bg-brand-100'
          textColor='text-black'
          rightIcon={<ShortRightIcon className="fill-black" />}
        >Login</ButtonComp>
      </div>

      <div className='w-full flex flex-row items-center justify-center gap-4 mt-12'>
        <IconButton
          icon={<Google className="w-full h-full text-white" />}
          onClick={() => console.log('Google button clicked')}
          className='flex-1'

        />

        <IconButton
          icon={<LinkedIn className="w-full h-full text-white" />}
          onClick={() => console.log('Google button clicked')}
          className='flex-1'
        />
      </div>
    </AuthLayout>
  )
}

export default Login

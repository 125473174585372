import React from 'react';
import ButtonCompSpecific from '../ButtonCompSpecific';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { ReactComponent as Share } from '../../assets/icons/Share.svg';
import { ReactComponent as CheckCircle } from '../../assets/icons/circle_check.svg';

interface WatchBroadcastProps {
    category: string;
    viewers: number;
    title: string;
    streamerName: string;
    streamerAvatar: string;
    followers: number;
    description: string;
    isVerified?: boolean;
    isLive?: boolean;
    isMobile?: boolean;
    onShareClick?: () => void;
}

const WatchBroadcastComponent: React.FC<WatchBroadcastProps> = ({
    category,
    viewers,
    title,
    streamerName,
    streamerAvatar,
    followers,
    description,
    isVerified = false,
    isLive = false,
    isMobile = false,
    onShareClick,
}) => {
    return (
        <div className="max-w-[812px] w-full rounded-b-lg p-6 flex flex-col gap-4">
            {/* Top section */}
            <div className="flex flex-col gap-1">
                <div className="flex items-center text-xs">
                    <span className="text-blue-100">{category}</span>
                    <span className="text-grey-40 ml-1">• {viewers} Watching</span>
                </div>
                <h1 className="text-white text-2xl font-semibold">{title}</h1>
            </div>

            {/* User section */}
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <div className="relative">
                        <img
                            src={streamerAvatar}
                            alt={streamerName}
                            className="w-10 h-10 rounded-lg border-2 border-red-100"
                        />
                        {isLive && (
                            <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-red-100 rounded-full border-2 border-grey-90"></div>
                        )}
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <span className="text-white text-sm">{streamerName}</span>
                            {isVerified && <CheckCircle className="w-4 h-4 text-green-100" />}
                        </div>
                        {/* <span className="text-grey-40 text-xs">{followers} Followers</span> */}
                    </div>
                </div>
                <div className="flex gap-4">
                    <button className="p-2.5 bg-grey-100 rounded-lg">
                        <Share className="w-5 h-5 text-grey-40"
                            onClick={onShareClick}
                        />
                    </button>

                    {/* <ButtonCompSpecific
                        leftIcon={<Star className="w-5 h-5 text-brand-100" />}
                        type='borderBrand'
                    >Favorite</ButtonCompSpecific> */}

                </div>
            </div>

            {/* Description */}
            <p className="text-grey-40 text-sm leading-5 line-clamp-3">{description}</p>
        </div>
    );
};

export default WatchBroadcastComponent;
import { create } from 'zustand';

interface SetupAccountState {
    step: number;
    tools: string[];
    departments: string[];
    beAsStreamer: 'yes' | 'no' | 'maybe' | null;
    nextStep: () => void;
    prevStep: () => void;
    addTool: (tool: string) => void;
    toggleTool: (tool: string) => void;
    setTools: (tools: string[]) => void;
    addDepartment: (department: string) => void;
    toggleDepartment: (department: string) => void;
    setDepartments: (departments: string[]) => void;
    setBeAsStreamer: (value: 'yes' | 'no' | 'maybe') => void;
    reset: () => void;
}


export const useSetupAccountStore = create<SetupAccountState>(set => ({
    step: 1,
    tools: [],
    departments: [],
    beAsStreamer: null,
    nextStep: () =>
        set((state) => ({
            step: state.step < 3 ? state.step + 1 : state.step,
        })),
    prevStep: () =>
        set((state) => ({
            step: state.step > 1 ? state.step - 1 : state.step,
        })),

    addTool: (tool: string) =>
        set((state: SetupAccountState) => ({
            tools: [...state.tools, tool],
        })),
    toggleTool: (tool: string) =>
        set((state: SetupAccountState) => ({
            tools: state.tools.includes(tool)
                ? state.tools.filter((t) => t !== tool)
                : [...state.tools, tool],
        })),
    setTools: (tools: string[]) => set(() => ({ tools })),
    addDepartment: (department: string) =>
        set((state: SetupAccountState) => ({
            departments: [...state.departments, department],
        })),
    toggleDepartment: (department: string) =>
        set((state: SetupAccountState) => ({
            departments: state.departments.includes(department)
                ? state.departments.filter((d) => d !== department)
                : [...state.departments, department],
        })),
    setDepartments: (departments: string[]) => set(() => ({ departments })),
    setBeAsStreamer: (value) =>
        set({
            beAsStreamer: value,
        }),

    reset: () =>
        set(() => ({
            tools: [],
            departments: [],
            beAsStreamer: null,
        })),
}));
